import { BREAKPOINTS, media } from '@app/styles/themes/constants';
import { IconCheck } from '@tabler/icons-react';
import styled, { css, keyframes } from 'styled-components';
import { NormalText } from '../common/BaseTexts/BaseTexts';
import { OrderedListOutlined } from '@ant-design/icons';
import { Input, Image } from 'antd';

const pulse = keyframes`
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(3, 149, 188, 0.4);
    box-shadow: 0 0 0 0 rgba(3, 149, 188, 0.4);
  }
  70% {
    -moz-box-shadow: 0 0 0 10px rgba(3, 149, 188, 0);
    box-shadow: 0 0 0 10px rgba(3, 149, 188, 0);
  }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(3, 149, 188, 0);
    box-shadow: 0 0 0 0 rgba(3, 149, 188, 0);
  }
`;

const animationRule = css(['', ' 2s infinite;'] as any as TemplateStringsArray, pulse);

export const MessagesWrapper = styled.div`
  padding: 30px;
  margin-bottom: 60px;
`;

export const SidebarChatBox = styled.div`
  padding: 20px;
  min-width: 25%;
  height: 90vh;
  margin: 0em;
  background-color: var(--background-color);
  border-right: 2px solid #7cc5d9;
  overflow: auto;
`;

export const AppendedFile = styled.div`
  margin-top: 0.5rem;
`;

export const CancelledInfo = styled.div`
  margin-top: 0.75rem;
`;

export type ChatBubbleContainerProps = { isMyMessage: boolean };

export const ChatBubbleContainer = styled('div')<ChatBubbleContainerProps>`
  &:hover .ActionsContainer {
    display: block;
  }
`;

export type ChatBubbleProps = { isGenerating: boolean; isMyMessage: boolean; isSidebarMode?: boolean };

export const ChatBubble = styled('div')<ChatBubbleProps>`
  background-color: var(--message-buble-background-color);
  border-radius: ${(props) => (props.isMyMessage ? '8px 8px 0 0' : '0px 0px 8px 8px')};
  box-shadow: ${(props) =>
    !props.isMyMessage ? '0px 2px 3px rgba(0, 0, 0, 0.15)' : '0px 2px 3px rgba(0, 0, 0, 0.15)'};
  border-bottom: ${(props) => (props.isMyMessage ? '0.5px solid var(--lightgrey)' : '0')};
  padding: 30px;
  color: var(--text-main-color);
  display: flex;
  align-items: flex-start;
  animation: ${(props) => (props.isGenerating ? animationRule : 'none')};
  width: 100%;

  @media only screen and ${media.xl} {
    width: ${(props) => (props.isSidebarMode ? '100%' : `${BREAKPOINTS.lg}px`)};
    margin-left: auto;
    margin-right: auto;
  }
`;

export const UserName = styled.p`
  font-weight: bold;
  margin-bottom: 6px;
  font-size: 0.9rem;
  color: var(--text-main-color);
`;

export const MessageContent = styled.div`
  //white-space: pre-wrap;
  display: grid;
`;

export const ChatBubbleRight = styled.div`
  width: 100%;
`;

export const GreenPointWrapper = styled.div`
  text-align: 'left';
  margin-top: 1.5rem;
  align-items: center;
`;

export const StyledGrid = styled.div`
  display: grid;
`;

export const StyledSourcesGrid = styled.div`
  display: grid;
`;

export const ChatBubbleLeft = styled.div`
  width: 35px;
  height: 35px;
  border-radius: 50%;
  margin-right: 10px;
`;

export const ChatBubbleLeftImage = styled.img`
  width: 35px;
  height: 35px;
  border-radius: 50%;
  margin-right: 10px;
  object-fit: cover;
`;

export const ActionsContainer = styled.div`
  display: none;
  flex-direction: column;
  gap: 8px;
  align-items: center;
  justify-content: flex-end;

  @media (min-width: 768px) {
    margin-right: 8px;
    margin-left: 8px;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 1px;
  }
`;

export type EditInputProps = { isSidebarMode: boolean };

export const EditInput = styled(Input.TextArea)<EditInputProps>`
  background-color: var(--chat-edit-input-background-color);
  max-width: ${(props) => (props.isSidebarMode ? '30vw' : '50vw')} !important;
  font-size: 1rem;
  border: 1;
  margin-bottom: 1rem;

  @media only screen and ${media.xl} {
    width: ${BREAKPOINTS.lg * 0.8}px;
  }
`;

export const CopyButton = styled.div`
  visibility: visible;
  cursor: pointer;
  &:hover,
  &:focus {
    visibility: visible;
    color: #4a5568; // Grau
  }

  &:hover {
    color: #2d3748; // dunkleres Grau
  }

  @media (prefers-color-scheme: dark) {
    color: var(--text-light-color);

    &:hover {
      color: var(--text-color);
    }
  }
`;

export const StyledIconCheck = styled(IconCheck)`
  color: #48bb78; // Grün

  @media (prefers-color-scheme: dark) {
    color: #38a169; // dunkleres Grün
  }
`;

type StyledProps = { fontSize?: string };

export const StyledTable = styled.table<StyledProps>`
  border-collapse: collapse;
  border: 1px solid black;
  padding: 3px 1px;
  font-size: ${(props) => props.fontSize || '1rem'};

  .dark & {
    border-color: #414141;
  }
`;

export const StyledTableFixedWidth = styled(StyledTable)`
  th:first-of-type {
    width: 40%;
  }
`;

export const StyledTh = styled.th<StyledProps>`
  word-break: break-word;
  border: 1px solid black;
  background-color: var(--table-header-background-color);
  padding: 3px 8px;
  color: var(--text-main-color);
  text-align: left;
  font-size: ${(props) => props.fontSize || '1rem'};

  .dark & {
    border-color: #414141;
  }
`;

export const StyledTd = styled.td<StyledProps>`
  word-break: break-word;
  border: 1px solid black;
  padding: 3px 8px;
  font-size: ${(props) => props.fontSize || '1rem'};

  .dark & {
    border-color: #414141;
  }
`;

export const StyledUl = styled.ul<StyledProps>`
  margin-top: 0;
  margin-bottom: 0;
  padding: 0;
  padding-left: 20px;
  font-size: ${(props) => props.fontSize || '1rem'};
`;

export const StyledOl = styled.ol<StyledProps>`
  margin-top: 0;
  margin-bottom: 0;
  padding: 0;
  padding-left: 20px;
  font-size: ${(props) => props.fontSize || '1rem'};
`;

export const StyledLi = styled.li<StyledProps>`
  margin: 0;
  font-size: ${(props) => props.fontSize || '1rem'};
`;

export const StyledP = styled(NormalText)`
  margin: 0;
  padding: 0;
  white-space: pre-wrap;
`;

export const StyledInlineP = styled(NormalText)`
  margin: 0;
  padding: 0;
  display: inline;
  white-space: pre-wrap;
`;

export const StyledBlockquote = styled(NormalText)`
  margin: 4px 0px;
  padding: 0;
  white-space: pre-wrap;
  padding-left: 1rem;
  color: var(--text-light-color);
  border-left: 1px solid var(--disabled-color);
`;

export const StyledShowMore = styled(OrderedListOutlined)`
  width: 2rem;
  height: 2rem;
  background-color: rgba(245, 245, 245, 1);
  border-radius: 6px;
  border-width: 1px;
  border-color: rgba(219, 219, 219, 1);
  border-style: solid;
  align-items: 'center';
`;

export const GeneratedImage = styled(Image)`
  display: inline-block;
  max-width: 300px;
  height: auto;
  border-radius: 8px;
`;
