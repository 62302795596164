export type LawSource = 'fedlex';

export type LawScanState = 'INITIAL' | 'IN_PROGRESS' | 'SUCCESSFUL' | 'PARTLY_WITH_ERRORS' | 'ERROR';
export type LawChangeDetailState = 'INITIAL' | 'APPROVED' | 'IGNORE';

export interface ILawChangeDetails {
  parentChapter?: string;
  nameOfArticle: string;
  heading: string;
  changeText: string;
  state: LawChangeDetailState;
  editedBy?: string;
  editedAt?: number;
}

export interface ILawChange {
  _id?: string;
  changeTitle: string;
  reason: string;
  changeSummary?: string;
  diffChangeSummary?: string;
  changeDetails?: ILawChangeDetails[];
  resolutionDate: string;
  comingIntoForceDate: string;
  url: string;
  pdfUrl?: string;
  revisionContentUrl: string;
  revisionContentPDFUrl: string;
  scanningError?: string;
}

export interface ILawEntry {
  _id: string;
  lawId: string;
  title: string;
  shortTitle?: string;
  url: string;
  source: LawSource;
  historyUrl: string;
  lastScanedAt: number;
  currentVersion: string;
  changes: ILawChange[];
  companyId: string;
  createdAt: number;
  createdBy: string;
  scanningState: LawScanState;
  scanningError?: string;
}

export interface ILawRevisionContent {
  _id: string;
  lawId: string;
  causedByChange: string;
  version: string;
  content: string;
  scanedAt: number;
  companyId: string;
  isActive: boolean;
}

export type LawChangeState = 'INITIAL' | 'APPROVED' | 'IGNORED';

export interface ILawChangeSummary {
  changeId: string;
  changeTitle: string;
  originalText: string;
  summary: string;
  state: LawChangeState;
  editedBy: string;
  editedAt: number;
  companyId: string;
}

export type LawEntryAutoImport = {
  lawId: string;
  title: string;
  shortTitle: string;
  historyUrl: string;
  currentVersion: string;
  lawIdAlreadyExists?: boolean;
};

export const EmptyLawChangesSummary = 'no changes';
export const ToManyDiffChangesInLaw = 'Too many changes, probably the new document has been heavily revised.';

export type LawReportState = 'INITIAL' | 'APPROVED' | 'DECLINED' | 'MUST_BE_REVIEWED';

export interface ILawChangeReference {
  changeId: string;
  changeTitle: string;
  comingIntoForceDate: string;
}

export interface ILawChangeReport {
  _id?: string;
  lawId: string;
  startDate: number;
  endDate: number;
  createdBy: string;
  createdAt: number;
  state: LawReportState;
  changesConsidered: ILawChangeReference[];
  content?: string;
  editedBy?: string;
  editedAt?: number;
  companyId: string;
}

export interface ILawReportGenerateOptions {
  useAiForReport?: boolean;
  considerDiffSummary?: boolean;
  additionalPrompt?: string;
  examplesForOutputFormat?: string;
}
