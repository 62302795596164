import React, { useCallback, useMemo, useState } from 'react';
import { Table, Row, Col, Dropdown, Modal, Space, Tooltip, Input } from 'antd';
import { useHandleLawEntries } from '@app/hooks/useHandleLawEntries';
import { ILawEntry } from '@app/types/lawTypes';
import ModalCreateLawEntry from './ModalCreateLawEntry';
import { HeaderContainerWrapper, LawRadarContainerWrapper } from './LawRadar.styles';
import { NormalText } from '../common/BaseTexts/BaseTexts';
import { Button as CustomButton } from '../common/buttons/Button/Button';
import { IconDotsVertical, IconEdit, IconTrash } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';
import { useCurrentTheme } from '@app/hooks/useCurrentTheme';
import { ExclamationCircleOutlined, LoadingOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import moment from 'moment';
import debounce from 'lodash/debounce';
import { LawRadarDefaultDateFormat } from '@app/utils/constants';

const LawRadarOverview: React.FC = () => {
  const { lawEntries, lawEntryLoading, onDeleteLawEntry, onEntryCreated, onUpdateLawEntry } = useHandleLawEntries({
    autoRefreshIfSomeItemIsLoading: true,
  });

  const [isModalVisible, setIsModalVisible] = React.useState(false);
  const [editingEntry, setEditingEntry] = React.useState<ILawEntry | null>(null);
  const [searchTerm, setSearchTerm] = useState('');

  const { t } = useTranslation();
  const { currentTheme } = useCurrentTheme();

  const handleEdit = (entry: ILawEntry) => {
    setEditingEntry(entry);
    setIsModalVisible(true);
  };

  const handleAdd = () => {
    setEditingEntry(null);
    setIsModalVisible(true);
  };

  const onDeleteClicked = (createdEntry: ILawEntry) => {
    Modal.confirm({
      title: t('lawRadar.deleteLaw'),
      icon: <ExclamationCircleOutlined />,
      content: t('lawRadar.areYouSureToDeleteLaw'),
      okText: t('yes'),
      okType: 'danger',
      cancelText: t('no'),
      onOk() {
        onDeleteLawEntry(createdEntry._id ?? '');
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };

  const handleSearch = useCallback(
    debounce((value: string) => {
      setSearchTerm(value);
    }, 300),
    [],
  );

  const getProcessingText = (item: ILawEntry) => {
    if (item.scanningState === 'INITIAL') {
      return t('lawRadar.notScanned');
    } else if (item.scanningState === 'IN_PROGRESS') {
      return t('lawRadar.processing');
    } else if (item.scanningState === 'SUCCESSFUL') {
      return t('lawRadar.successful');
    } else if (item.scanningState === 'ERROR') {
      return t('lawRadar.error');
    } else if (item.scanningState === 'PARTLY_WITH_ERRORS') {
      return t('lawRadar.partlyWithErrors');
    } else {
      return 'Not scaned';
    }
  };

  const getProcessingColor = (item: ILawEntry) => {
    if (item.scanningState === 'INITIAL') {
      return 'var(--text-main-color)';
    } else if (item.scanningState === 'IN_PROGRESS') {
      return 'orange';
    } else if (item.scanningState === 'SUCCESSFUL') {
      return 'var(--success-color)';
    } else if (item.scanningState === 'ERROR') {
      return 'var(--error-color)';
    } else if (item.scanningState === 'PARTLY_WITH_ERRORS') {
      return 'rgb(190, 122, 36)';
    } else {
      return 'var(--text-main-color)';
    }
  };

  const processingInfo = (item: ILawEntry) => {
    const text = getProcessingText(item);
    const color = getProcessingColor(item);
    const showLoading = item.scanningState === 'IN_PROGRESS';
    const showToolTip = item.scanningState === 'ERROR' && !!item.scanningError;

    return (
      <Space>
        {showToolTip ? (
          <Tooltip title={item.scanningError}>
            <NormalText size="s" semiBold style={{ color: color }}>
              {text}
            </NormalText>
          </Tooltip>
        ) : (
          <NormalText size="s" semiBold style={{ color: color }}>
            {text}
          </NormalText>
        )}
        {showLoading && <LoadingOutlined style={{ color: color }} />}
      </Space>
    );
  };

  const menuItems = [
    { label: t('lawRadar.edit'), key: 'edit', icon: <IconEdit size={16} /> },
    { label: t('lawRadar.delete'), key: 'delete', icon: <IconTrash size={16} /> },
  ];

  const filteredLawEntries = useMemo(
    () =>
      searchTerm.length
        ? lawEntries.filter(
            (law) =>
              law.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
              law.lawId?.toLowerCase().includes(searchTerm.toLowerCase()),
          )
        : lawEntries,
    [searchTerm, lawEntries],
  );

  return (
    <LawRadarContainerWrapper>
      <HeaderContainerWrapper>
        <Row justify={'space-between'} align={'bottom'}>
          <Col>
            <NormalText bold size="xl">
              {t('lawRadar.overviewTitle')}
            </NormalText>
            <NormalText verticalPadding>{t('lawRadar.overviewDescription')}</NormalText>
          </Col>
          <Col>
            <CustomButton type="primary" style={{ float: 'right' }} onClick={handleAdd}>
              {t('lawRadar.addLawEntry')}
            </CustomButton>
          </Col>
        </Row>
      </HeaderContainerWrapper>
      <Input.Search
        placeholder={t('lawRadar.searchPlaceholder')}
        onChange={(e) => handleSearch(e.target.value)}
        style={{ width: 400, marginBottom: '1.5rem' }}
        allowClear
      />
      <Table
        columns={[
          {
            title: t('lawRadar.title') as string,
            key: 'title',
            render: (_, item) => (
              <Link to={'/zen/law-radar/' + item._id}>
                <NormalText size="s" semiBold>
                  {item.title}
                </NormalText>
              </Link>
            ),
            sorter: (a, b) => a.title.localeCompare(b.title),
          },
          {
            title: t('lawRadar.lawId') as string,
            dataIndex: 'lawId',
            key: 'lawId',
            sorter: (a, b) => a.lawId.localeCompare(b.lawId),
          },
          {
            title: t('lawRadar.currentVersion') as string,
            dataIndex: 'currentVersion',
            key: 'currentVersion',
            sorter: (a, b) => {
              const cvA = moment(a.currentVersion, LawRadarDefaultDateFormat);
              const cvB = moment(b.currentVersion, LawRadarDefaultDateFormat);
              return cvA && cvB ? cvB.unix() - cvA.unix() : 0;
            },
            render: (_, record) => <NormalText size="s">{record.currentVersion || '-'}</NormalText>,
          },
          {
            title: t('lawRadar.source') as string,
            dataIndex: 'source',
            key: 'source',
            sorter: (a, b) => a.source.localeCompare(b.source),
          },
          {
            title: t('Scaning State') as string,
            dataIndex: 'scanningState',
            key: 'scanningState',
            defaultSortOrder: null,
            sorter: (a, b) => {
              const aCompare = a.scanningState || 'INITIAL';
              const bCompare = b.scanningState || 'INITIAL';
              return aCompare.localeCompare(bCompare);
            },
            render: (_, record) => processingInfo(record),
          },
          {
            title: t('Scaning Date') as string,
            dataIndex: 'scanningDate',
            key: 'scanningDate',
            defaultSortOrder: null,
            sorter: (a, b) => (a.lastScanedAt && b.lastScanedAt ? a.lastScanedAt - b.lastScanedAt : 0),
            render: (_, record) => (
              <NormalText size="s">{record.lastScanedAt ? moment.unix(record.lastScanedAt).fromNow() : '-'}</NormalText>
            ),
          },
          {
            title: t('lawRadar.actions') as string,
            key: 'actions',
            width: '50px',
            render: (_, item) => (
              <Dropdown
                menu={{
                  items: menuItems,
                  onClick: ({ key }) => {
                    if (key === 'edit') handleEdit(item);
                    if (key === 'delete') onDeleteClicked(item);
                  },
                }}
                trigger={['click']}
              >
                <div style={{ padding: '0.5rem', cursor: 'pointer' }}>
                  <IconDotsVertical color={currentTheme.textLight} />
                </div>
              </Dropdown>
            ),
          },
        ]}
        dataSource={filteredLawEntries}
        loading={lawEntryLoading}
        rowKey="lawId"
        pagination={{ hideOnSinglePage: true, defaultPageSize: 100, size: 'small' }}
      />
      <ModalCreateLawEntry
        onClose={() => setIsModalVisible(false)}
        visible={isModalVisible}
        onEntryCreated={(createdEntry: ILawEntry) => onEntryCreated(createdEntry)}
        onUpdateLawEntry={(lawEntryId: string, updatedEntry: ILawEntry) => onUpdateLawEntry(lawEntryId, updatedEntry)}
        editLawEntry={editingEntry}
      />
    </LawRadarContainerWrapper>
  );
};

export default LawRadarOverview;
