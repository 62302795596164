import { useCurrentTheme } from '@app/hooks/useCurrentTheme';
import React from 'react';

import styled from 'styled-components';

type Props = { text: string; isSelected?: boolean };

const FilterTagItem: React.FC<Props> = (props) => {
  const { currentTheme } = useCurrentTheme();
  return (
    <Div
      style={{
        backgroundColor: props.isSelected ? currentTheme.primary : currentTheme.background,
        marginTop: '0.5rem',
      }}
    >
      <Text style={props.isSelected ? { color: 'rgba(255, 255, 255, 1)' } : { color: currentTheme.textLight }}>
        <span>{props.text}</span>
      </Text>
    </Div>
  );
};

export default FilterTagItem;

const Div = styled('div')({
  width: 'auto',
  height: 'auto',
  display: 'flex',
  'align-items': 'flex-start',
  padding: '4px 12px',
  'margin-right': '12px',
  'border-radius': '9999px',
  'border-width': '1px',
  'border-color': 'var(--border-base-color)',
  'border-style': 'solid',
  position: 'relative',
  cursor: 'pointer',
});

const Text = styled('span')({
  color: 'rgba(71, 85, 105, 1)',
  'text-align': 'left',
  'line-height': '20px',
  'margin-right': '0',
  'margin-bottom': '0',
  'align-self': 'auto',
  'font-size': 12,
  'font-stretch': 'normal',
  'font-style': 'Medium',
  'font-weight': 500,
  'text-decoration': 'none',
});
