import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '@app/hooks/reduxHooks';
import { useResponsive } from '@app/hooks/useResponsive';
import { Divider, Row, Switch, Space, Tooltip } from 'antd';
import {
  setLLMLanguage,
  setConversationMemoryKnowledgeActive,
  setLLMModelAnswerSize,
  setAdvancedKnowledgeRAGActive,
  setEnableQuestionOptimization,
} from '@app/store/slices/settingsSlice';
import { languageOptions } from '@app/components/profile/profileCard/profileFormNav/nav/PersonalInfo/LanguageItem/LanguageItem';
import { BaseButtonsForm } from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';
import { BaseSelect } from '@app/components/common/selects/BaseSelect/BaseSelect';
import { NormalText } from '@app/components/common/BaseTexts/BaseTexts';
import { ANSWER_SIZE_SELECTION, LLM_ANSWER_SIZE_TYPE, LLM_OUTPUT_LANUGAGE_TYPE } from '@app/utils/constants';
import { LlmModelSection } from './LlmModelSection';
import { DrawerStyled, ResponseLengthButton } from './LlmSetupDrawer.styles';
import { IconInfoCircle } from '@tabler/icons-react';
import SystemPromptController from './SystemPromptController';
import { useCurrentTheme } from '@app/hooks/useCurrentTheme';

interface LlmSetupDrawerProps {
  onCloseLlmSetupDrawer: () => void;
  showLlmSetupDrawer: boolean;
  knowledgeBaseMode: boolean;
}

const showPreferedOutputLength = true;

const LlmSetupDrawer: React.FC<LlmSetupDrawerProps> = ({
  showLlmSetupDrawer,
  knowledgeBaseMode,
  onCloseLlmSetupDrawer,
}) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const { mobileOnly } = useResponsive();
  const { currentTheme } = useCurrentTheme();
  const {
    modelLanguage,
    conversationMemoryKnowledgeActive,
    modelResponseLength,
    enableQuestionOptimization,
    advancedKnowledgeRAGActive,
  } = useAppSelector((state) => state.settings);

  const renderSettingsForKnowledgeBase = () => {
    if (knowledgeBaseMode) {
      return (
        <>
          <Row justify={'space-between'} align={'middle'}>
            <NormalText verticalPadding color={currentTheme.primary} size="m">
              {t('conversationMemoryKnowledgeActive')}
            </NormalText>
            <Switch
              checked={conversationMemoryKnowledgeActive}
              onChange={(value: boolean) => dispatch(setConversationMemoryKnowledgeActive({ value }))}
              size={'small'}
            />
          </Row>

          <NormalText verticalPadding size="s" color={currentTheme.textLight}>
            {t('llmSetup.dialogueMemoryDescription')}
          </NormalText>
          <Divider />
          <Row justify={'space-between'} align={'middle'}>
            <NormalText verticalPadding color={currentTheme.primary} size="m">
              {t('enableQuestionOptimization')}
            </NormalText>
            <Switch
              checked={enableQuestionOptimization}
              onChange={(value: boolean) => dispatch(setEnableQuestionOptimization({ value }))}
              size={'small'}
            />
          </Row>
          <NormalText verticalPadding size="s" color={currentTheme.textLight}>
            {t('enableQuestionOptimizationToolTip')}
          </NormalText>
          <Divider />
          <Row justify={'space-between'} align={'middle'}>
            <NormalText verticalPadding color={currentTheme.primary} size="m">
              {t('advancedKnowledgeRAGActive')}
            </NormalText>
            <Switch
              checked={advancedKnowledgeRAGActive}
              onChange={(value: boolean) => dispatch(setAdvancedKnowledgeRAGActive({ value }))}
              size={'small'}
            />
          </Row>
          <NormalText verticalPadding size="s" color={currentTheme.textLight}>
            {t('advancedKnowledgeRAGActiveToolTip')}
          </NormalText>
          <Divider />
        </>
      );
    }
  };

  return (
    <DrawerStyled
      width={mobileOnly ? '80%' : 460}
      title={t('llmSetup.title')}
      placement="right"
      onClose={onCloseLlmSetupDrawer}
      open={showLlmSetupDrawer}
      style={{ display: 'flex', flexDirection: 'column' }}
    >
      <Row justify={'space-between'}>
        <NormalText verticalPadding color={currentTheme.primary} size="m">
          {t('llmSetup.outputLanguage')}
        </NormalText>
        <Space>
          <NormalText
            verticalPadding
            color={!modelLanguage || modelLanguage === 'auto' ? currentTheme.primary : undefined}
            size="m"
          >
            {t('llmSetup.automatically')}
          </NormalText>
          <Switch
            checked={!modelLanguage || modelLanguage === 'auto'}
            onChange={(checked) => {
              const langType: LLM_OUTPUT_LANUGAGE_TYPE = checked ? 'auto' : 'de';
              dispatch(setLLMLanguage({ value: langType }));
            }}
            size={'small'}
          />
        </Space>
      </Row>

      <BaseButtonsForm.Item labelCol={{ span: 24 }} name="language" label={null}>
        <BaseSelect
          defaultValue={modelLanguage === 'auto' ? undefined : modelLanguage ?? 'de'}
          disabled={!modelLanguage || modelLanguage === 'auto'}
          onChange={(language) => {
            if (language === 'en' || language === 'de') {
              dispatch(setLLMLanguage({ value: language }));
            }
          }}
        >
          {languageOptions}
        </BaseSelect>
      </BaseButtonsForm.Item>
      {showPreferedOutputLength && <Divider />}
      {showPreferedOutputLength && (
        <Row justify={'space-between'}>
          <NormalText verticalPadding color={currentTheme.primary} size="m">
            {t('llmSetup.maxResponseLength')}
          </NormalText>
          <Space>
            <NormalText
              verticalPadding
              color={!modelResponseLength || modelResponseLength === 'auto' ? currentTheme.primary : undefined}
              size="m"
            >
              {t('llmSetup.automatically')}
            </NormalText>
            <Switch
              checked={!modelResponseLength || modelResponseLength === 'auto'}
              onChange={(checked) => {
                const responseLength: LLM_ANSWER_SIZE_TYPE = checked ? 'auto' : 'minimal';
                dispatch(setLLMModelAnswerSize({ value: responseLength }));
              }}
              size={'small'}
            />
          </Space>
        </Row>
      )}

      {showPreferedOutputLength && (
        <Row justify={'start'}>
          {ANSWER_SIZE_SELECTION.map((answerSize) => (
            <ResponseLengthButton
              key={answerSize}
              onClick={() => dispatch(setLLMModelAnswerSize({ value: answerSize }))}
              selected={modelResponseLength === answerSize}
            >
              {t(`llmSetup.${answerSize}`)}
            </ResponseLengthButton>
          ))}
        </Row>
      )}
      <Divider />

      <SystemPromptController parentDrawerVisible={showLlmSetupDrawer} />

      <Divider />

      {renderSettingsForKnowledgeBase()}
      <Space>
        <NormalText verticalPadding color={currentTheme.primary} size="m">
          {t('llmSetup.activeAiModel')}
        </NormalText>
        <Tooltip title={t('llmSetup.activeAiModelHint')}>
          <IconInfoCircle size={'1rem'} color="gray" />
        </Tooltip>
      </Space>
      <LlmModelSection />
    </DrawerStyled>
  );
};

export default LlmSetupDrawer;
