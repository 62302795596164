import React from 'react';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { Link } from 'react-router-dom';
import { Col, Dropdown, Row } from 'antd';
import {
  IconBrain,
  IconBrandGoogle,
  IconBrowser,
  IconCopy,
  IconDotsVertical,
  IconEdit,
  IconPhotoAi,
  IconTrash,
} from '@tabler/icons-react';
import { themeObject } from '@app/styles/themes/themeVariables';

import { Container, Div, Text, TextDescription, ImageContainer, DivContent, MenuWrapper } from './SuperDoItem.styles';
import { shortenString } from '@app/utils/stringHelpers';

type Props = {
  title: string;
  description: string;
  imagePath: string;
  linkRoute: string;
  superDoId: string;
  toolNames?: string[];
  onDeleteSuperDoTemplate: (id: string) => void;
  onEditSuperDoTemplate: (id: string) => void;
  onDuplicateSuperDoTemplate: (id: string) => void;
  onClick?: (id: string) => void;
  isShowActionButtons: boolean;
};

const menuItems = [
  { label: 'Edit', key: 'edit', icon: <IconEdit size={16} /> },
  { label: 'Duplicate', key: 'duplicate', icon: <IconCopy size={16} /> },
  { label: 'Delete', key: 'delete', icon: <IconTrash size={16} /> },
];

const SuperDoItem: React.FC<Props> = ({
  title,
  description,
  toolNames,
  linkRoute,
  superDoId,
  onDeleteSuperDoTemplate,
  onEditSuperDoTemplate,
  onDuplicateSuperDoTemplate,
  onClick,
  isShowActionButtons,
}) => {
  const theme = useAppSelector((state) => state.theme.theme);

  return (
    <Container onClick={onClick ? () => onClick(superDoId) : undefined}>
      <Div>
        <Link to={linkRoute}>
          <DivContent>
            <Text>
              <span>{title}</span>
            </Text>
            <TextDescription>
              <span>{shortenString(description, 100)}</span>
            </TextDescription>
          </DivContent>
        </Link>
        <MenuWrapper>
          <Row justify={'space-between'} align={'stretch'}>
            <Col>
              <ImageContainer>
                <Row align={'middle'} gutter={12} style={{ padding: 0 }}>
                  <Col>
                    <IconBrain size={14} />
                  </Col>
                  {toolNames?.includes('WEB_BROWSER') && (
                    <Col>
                      <IconBrowser size={14} />
                    </Col>
                  )}
                  {toolNames?.includes('GOOGLE_SEARCH') && (
                    <Col>
                      <IconBrandGoogle size={14} />
                    </Col>
                  )}
                  {toolNames?.includes('DALL_E') && (
                    <Col>
                      <IconPhotoAi size={14} />
                    </Col>
                  )}
                </Row>
              </ImageContainer>
            </Col>
            <Col>
              {isShowActionButtons ? (
                <Dropdown
                  menu={{
                    items: menuItems,
                    onClick: ({ key }) => {
                      if (key === 'edit') onEditSuperDoTemplate(superDoId);
                      if (key === 'delete') onDeleteSuperDoTemplate(superDoId);
                      if (key === 'duplicate') onDuplicateSuperDoTemplate(superDoId);
                    },
                  }}
                  trigger={['click']}
                >
                  <div style={{ padding: '0.5rem', cursor: 'pointer' }}>
                    <IconDotsVertical size={16} color={themeObject[theme].textLight} />
                  </div>
                </Dropdown>
              ) : (
                <span></span>
              )}
            </Col>
          </Row>
        </MenuWrapper>
      </Div>
    </Container>
  );
};

export default SuperDoItem;
