import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Modal, UploadFile, message } from 'antd';
import { IUploadedDocument, IUploadedDocumentChangeableFields } from '@app/domain/UploadedDocumentModel';
import {
  createSignedUrlByPath,
  createUploadedDocument,
  createUploadedDocumentAndIngestToVectorDb,
  deleteUploadedFileByPath,
  getAllIngestedUploadedDocuments,
  getAllNotIngestedUploadedDocuments,
  updateFileName,
  updateDocumentData,
  UploadFileResult,
} from '@app/api/uploadedDocument.api';
import { debounce } from 'lodash';
import moment from 'moment';
import { isEmpty } from 'lodash';
import { KnowledgeAreaModel } from '@app/domain/KnowledgeAreaModel';
import { notificationController } from '@app/controllers/notificationController';
import { useAppDispatch, useAppSelector } from './reduxHooks';
import { setAddedOrRemovedAnalysisFile } from '@app/store/slices/uploadedDocumentsSlice';
import { useTranslation } from 'react-i18next';
import { resetKnowledgeAreaFilter } from '@app/store/slices/knowledgeAreaFilterSlice';

type useHandleMultipleFileUploadsProps = {
  mode: 'KNOWLEDGE_FILES' | 'ANALYSIS_FILES';
  selectedKnowledgeArea?: KnowledgeAreaModel;
  onProcessQueueError?: (error: any) => void;
};

export const useHandleMultipleFileUploads = ({
  mode,
  selectedKnowledgeArea,
  onProcessQueueError,
}: useHandleMultipleFileUploadsProps) => {
  const { t } = useTranslation();

  const [uploadedFiles, setUploadedFiles] = useState<IUploadedDocument[]>([]);
  const [pendingFiles, setPendingFiles] = useState<IUploadedDocument[]>([]);

  const [someProcessingFileExists, setSomeProcessingFileExists] = useState(false);

  const alreadyUploadedFiles = useMemo(() => [...pendingFiles, ...uploadedFiles], [pendingFiles, uploadedFiles]);

  const uploadQueue = useRef<UploadFile[]>([]);
  const uploadingActive = useRef(false);
  const refreshIntervalId = useRef<NodeJS.Timer>();
  const processQueueTimeoutId = useRef<NodeJS.Timer>();

  const dispatch = useAppDispatch();

  const user = useAppSelector((state) => state.user.user);
  const companyState = useAppSelector((state) => state.company);

  const readAllFiles = useCallback(async () => {
    if (mode === 'KNOWLEDGE_FILES' && !selectedKnowledgeArea?._id) return;
    if (mode === 'ANALYSIS_FILES' && (!companyState._id || !user?._id)) return;
    try {
      let result =
        mode === 'KNOWLEDGE_FILES'
          ? await getAllIngestedUploadedDocuments(selectedKnowledgeArea?._id ?? '')
          : await getAllNotIngestedUploadedDocuments(companyState._id ?? '', user?._id ?? '');

      if (result) {
        result = result.sort((a, b) => {
          return b.uploadedAt - a.uploadedAt;
        });
        const processingFileExists = result.some((item) => documentIsProcessing(item.processingState));
        setSomeProcessingFileExists(processingFileExists);
        setUploadedFiles(result);
        setPendingFiles((prevPending) =>
          prevPending.filter((pf) => {
            const processingTime = moment().diff(moment.unix(pf.uploadedAt), 'minutes');
            const processingError = documentIsProcessing(pf.processingState) && processingTime > 5;
            return !result.some((uq) => uq.title === pf.title) && !processingError;
          }),
        );
      }
    } catch (error) {
      console.log('****** readAllFiles error: ', error);
      if (error instanceof Error) {
        notificationController.error({ message: error.message });
      } else if (typeof error === 'string') {
        notificationController.error({ message: error });
      } else {
        notificationController.error({ message: 'Fehler beim Zugriff auf den Server' });
      }
      refreshIntervalId.current && clearInterval(refreshIntervalId.current);
    }
  }, [mode, selectedKnowledgeArea, companyState, user]);

  const debouncedReadAllFiles = debounce(readAllFiles, 1000);

  useEffect(() => {
    refreshIntervalId.current && clearInterval(refreshIntervalId.current);
    if (someProcessingFileExists) {
      refreshIntervalId.current = setInterval(() => {
        debouncedReadAllFiles();
      }, 10 * 1000);
    }
    return () => {
      refreshIntervalId.current && clearInterval(refreshIntervalId.current);
    };
  }, [debouncedReadAllFiles, someProcessingFileExists]);

  const documentIsProcessing = (processingState?: string) => {
    return processingState ? ['WAITING_FOR_PROCESSING', 'PROCESSING'].includes(processingState) : false;
  };

  const processQueue = async () => {
    if (uploadQueue.current.length > 0) {
      if (!uploadingActive.current) {
        uploadingActive.current = true;
        const formData = new FormData();
        try {
          for (let index = 0; index < uploadQueue.current.length; index++) {
            const fileToUpload = uploadQueue.current[index];
            formData.append('files', fileToUpload.originFileObj as Blob, encodeURIComponent(fileToUpload.name));

            const uploadedDocument: IUploadedDocument = {
              title: fileToUpload.name,
              url: '',
              thematicAreaId: selectedKnowledgeArea?.thematicAreaId || undefined,
              knowledgeAreaId: selectedKnowledgeArea?._id || undefined,
              companyId: companyState._id ?? '',
              createdByUserId: user?._id,
              uploadedAt: moment().unix(),
              ingestedInVectorDb: false,
              userComment: '',
              validFrom: 0,
              validUntil: 0,
            };
            formData.append(`uploadedDocumentsData[${index}]`, JSON.stringify(uploadedDocument));
          }

          setSomeProcessingFileExists(true);
          uploadQueue.current = [];

          if (mode === 'KNOWLEDGE_FILES') {
            const uploadResults: UploadFileResult = await createUploadedDocumentAndIngestToVectorDb(formData);
            if (uploadResults?.processingErrors?.length) {
              uploadResults.processingErrors.forEach((error) => {
                onProcessQueueError?.(`Error during processing the file "${error.documentTitle}": ${error.errorText}`);
                setPendingFiles((prevPending) => prevPending.filter((pf) => pf.title !== error.documentTitle));
              });
            }
          } else {
            const uploadResults: UploadFileResult = await createUploadedDocument(formData);
            if (uploadResults?.processingErrors?.length) {
              uploadResults.processingErrors.forEach((error) => {
                onProcessQueueError?.(`Error during processing the file "${error.documentTitle}": ${error.errorText}`);
                setPendingFiles((prevPending) => prevPending.filter((pf) => pf.title !== error.documentTitle));
              });
            }
            debouncedReadAllFiles();
            dispatch(setAddedOrRemovedAnalysisFile({ value: true }));
          }
        } catch (error) {
          onProcessQueueError?.(error);
        } finally {
          uploadingActive.current = false;
        }
      } else {
        processQueueTimeoutId.current && clearTimeout(processQueueTimeoutId.current);
        processQueueTimeoutId.current = setTimeout(() => {
          processQueue();
        }, 5 * 1000);
      }
    }
  };

  // Use lodash's debounce to prevent spamming the server with requests
  const debouncedProcessQueue = debounce(processQueue, 1000);

  // Function to handle new files being added to the queue
  const onNewFileUploaded = async (file: UploadFile) => {
    if (file) {
      if (pendingFiles.some((uf) => uf.title === file.name) || uploadedFiles.some((uf) => uf.title === file.name)) {
        message.info(t(mode === 'KNOWLEDGE_FILES' ? 'knowledgeFileAlreadyExists' : 'fileAlreadyExists'));
        return;
      }

      if (!uploadQueue.current.some((uf) => uf.name === file.name)) {
        setPendingFiles((prevState) => [
          {
            title: file.name,
            url: '',
            thematicAreaId: selectedKnowledgeArea?.thematicAreaId || undefined,
            knowledgeAreaId: selectedKnowledgeArea?._id || undefined,
            companyId: companyState._id ?? '',
            createdByUserId: user?._id,
            uploadedAt: moment().unix(),
            ingestedInVectorDb: false,
            processingState: 'WAITING_FOR_PROCESSING',
            userComment: '',
            validFrom: 0,
            validUntil: 0,
          },
          ...prevState,
        ]);

        uploadQueue.current.push(file);
        debouncedProcessQueue();
      }
    }
  };

  const onDeleteFile = async (title: string, fileStoragePath: string) => {
    const onDeleteConfirmed = async () => {
      try {
        if (!fileStoragePath) {
          notificationController.error({ message: `Error during deletion: invalid configuration` });
          return;
        }
        await deleteUploadedFileByPath(fileStoragePath);
        notificationController.success({ message: `The file ${title} was deleted.` });
        readAllFiles();
        if (mode === 'ANALYSIS_FILES') {
          dispatch(setAddedOrRemovedAnalysisFile({ value: true }));
        }
        if (mode === 'KNOWLEDGE_FILES' && selectedKnowledgeArea?._id) {
          dispatch(resetKnowledgeAreaFilter({ value: selectedKnowledgeArea?._id }));
        }
      } catch (error) {
        notificationController.error({ message: `Error during deletion ${error}` });
      }
    };
    Modal.confirm({
      title: t('removeFile'),
      //icon: <ExclamationCircleOutlined />,
      content: t('areYouSureRemove'),
      okText: t('yes'),
      okType: 'danger',
      cancelText: t('no'),
      onOk() {
        onDeleteConfirmed();
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };

  const onEditFileName = async (id: string, newTitle: string) => {
    try {
      if (!newTitle) {
        notificationController.error({ message: `Please enter a valid file name` });
        return;
      }
      await updateFileName(id, newTitle);
      setUploadedFiles((prev) => prev.map((file) => (file._id === id ? { ...file, title: newTitle } : file)));
    } catch (error) {
      notificationController.error({ message: `Error during deletion ${error}` });
    }
  };

  const onEditDocumentData = async (id: string, fieldsToUpdate: IUploadedDocumentChangeableFields) => {
    try {
      if (isEmpty(fieldsToUpdate)) {
        notificationController.error({ message: `Please select data to update` });
        return;
      }
      await updateDocumentData(id, fieldsToUpdate);
      setUploadedFiles((prev) => prev.map((file) => (file._id === id ? { ...file, ...fieldsToUpdate } : file)));
    } catch (error) {
      notificationController.error({ message: `Error during deletion ${error}` });
    }
  };

  const onPreviewFile = async (fileStoragePath: string) => {
    if (!fileStoragePath) {
      message.error('Fehler: ungültige Konfiguration');
      return;
    }
    const signedUrl = await createSignedUrlByPath(fileStoragePath);
    signedUrl && window.open(signedUrl, '_blank');
  };

  const getSignedFileUrl = async (fileStoragePath: string) => {
    if (!fileStoragePath) {
      message.error('Fehler: ungültige Konfiguration');
      return;
    }
    const signedUrl = await createSignedUrlByPath(fileStoragePath);
    return signedUrl;
  };

  return {
    alreadyUploadedFiles,
    onNewFileUploaded,
    readAllFiles,
    getSignedFileUrl,
    debouncedReadAllFiles,
    documentIsProcessing,
    onDeleteFile,
    onPreviewFile,
    onEditFileName,
    onEditDocumentData,
  };
};
