import React from 'react';
import { Row, Dropdown, Menu } from 'antd';
import { useTranslation } from 'react-i18next';
import { IconDotsVertical, IconLink, IconTrash } from '@tabler/icons-react';
import moment from 'moment';
import { NormalText } from '@app/components/common/BaseTexts/BaseTexts';
import { themeObject } from '@app/styles/themes/themeVariables';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { ExternalAccess } from '@app/types/externalAccessTypes';
import { Table } from './ExternalAccessTable.styles';

type GuestsTableProps = {
  deleteAccess: (guestId: string) => void;
  copyLink: (access: ExternalAccess, linkTo: 'avatar' | 'knowledge') => void;
  externalAccess: ExternalAccess[];
};

const TABLE_PAGINATION_PAGE_SIZE = 25;

export const ExternalAccessTable: React.FC<GuestsTableProps> = ({ deleteAccess, copyLink, externalAccess }) => {
  const theme = useAppSelector((state) => state.theme.theme);
  const { t } = useTranslation();

  const columns = [
    {
      title: t('externalAccess.name'),
      key: 'name',
      defaultSortOrder: null,
      sorter: (a: any, b: any) => a.title.localeCompare(b.title),
      render: (item: ExternalAccess) => (
        <NormalText colorType="light" size="s">
          {item.title}
        </NormalText>
      ),
    },
    {
      title: t('externalAccess.lastAccessAt'),
      key: 'lastAccessAt',
      defaultSortOrder: null,
      render: (item: ExternalAccess) => (
        <NormalText colorType="light" size="s">
          {item.lastAccessAt ? moment.unix(item.lastAccessAt).format('DD/MM/YY HH:mm') : '-'}
        </NormalText>
      ),
    },
    {
      title: '',
      key: 'editGuest',
      render: (item: ExternalAccess) => {
        const menu = (
          <Menu>
            <Menu.Item icon={<IconLink size={14} />} onClick={() => copyLink(item, 'avatar')} key="copy-avatar-link">
              {t('externalAccess.copyAvatarLink')}
            </Menu.Item>
            <Menu.Item
              icon={<IconLink size={14} />}
              onClick={() => copyLink(item, 'knowledge')}
              key="copy-knowledge-link"
            >
              {t('externalAccess.copyKnowledgeLink')}
            </Menu.Item>
            <Menu.Item danger icon={<IconTrash size={14} />} onClick={() => deleteAccess(item._id)} key="delete">
              {t('externalAccess.deleteAccessLink')}
            </Menu.Item>
          </Menu>
        );

        return (
          <Dropdown overlay={menu} trigger={['click']}>
            <div style={{ padding: '0.5rem', cursor: 'pointer' }}>
              <IconDotsVertical color={themeObject[theme].textLight} />
            </div>
          </Dropdown>
        );
      },
    },
  ];

  return (
    <Table
      bordered={false}
      pagination={
        externalAccess.length > TABLE_PAGINATION_PAGE_SIZE
          ? { pageSize: TABLE_PAGINATION_PAGE_SIZE, size: 'small' }
          : false
      }
      columns={columns}
      dataSource={externalAccess.map((guest) => ({ ...guest }))}
    />
  );
};
