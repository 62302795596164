import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { themeObject } from '@app/styles/themes/themeVariables';
import { without } from 'lodash';
import { Space, DatePicker, Row, Divider } from 'antd';
import { NormalText } from '@app/components/common/BaseTexts/BaseTexts';
import { IconArrowNarrowRight } from '@tabler/icons-react';
import { ExcelIcon } from '@app/components/common/icons/ExcelIcon';
import { PdfIcon } from '@app/components/common/icons/PdfIcon';
import { WordIcon } from '@app/components/common/icons/WordIcon';
import { FileType } from '@app/domain/UploadedDocumentModel';
import moment, { Moment } from 'moment';
import { KnowledgeAreaFilter } from '@app/store/slices/knowledgeAreaFilterSlice';
import { SelectTypeButton, DocumentFilterWrapper } from './DocumentFilter.styles';
import { RangePickerProps } from 'antd/es/date-picker';
import { SearchList } from './SearchList';
import { DocumentFilterType } from '@app/types/documentFilterTypes';

type DocumentFilterProps = {
  filterDocumentTypes: FileType[];
  updateFilter: (valuesToSave: Partial<KnowledgeAreaFilter>) => void;
  documents: DocumentFilterType[];
  isLoadingDocuments: boolean;
  selectedDocuments: DocumentFilterType[];
  validFromDate: number | undefined;
  validUntilDate: number | undefined;
  showValidityPeriodFields: boolean;
};

export type RangeValue = [Moment | null, Moment | null] | null;

export const DocumentFilter: React.FC<DocumentFilterProps> = ({
  filterDocumentTypes,
  updateFilter,
  documents,
  isLoadingDocuments,
  selectedDocuments,
  validFromDate,
  validUntilDate,
  showValidityPeriodFields,
}) => {
  const { t } = useTranslation();
  const theme = useAppSelector((state) => state.theme.theme);

  const handleDocumentSelect = (documentId: string, checked: boolean) => {
    const foudnDocument = documents.find((document) => document.id === documentId);
    if (foudnDocument) {
      const selectedDocumentsToSave = checked
        ? [...selectedDocuments, foudnDocument]
        : selectedDocuments.filter((selectedDocument) => selectedDocument.id !== documentId);
      updateFilter({ selectedDocuments: selectedDocumentsToSave });
    }
  };

  const handleFileTypeSelect = (fileType: FileType) => {
    const updatedFilterDocuments = filterDocumentTypes.includes(fileType)
      ? without(filterDocumentTypes, fileType)
      : [...filterDocumentTypes, fileType];
    updateFilter({ filterDocumentTypes: updatedFilterDocuments });
  };

  const handleDateChange: RangePickerProps['onChange'] = (dates: RangeValue) => {
    const datesToSave = dates ? dates : [null, null];
    const [validFromDate, validUntilDate] = datesToSave;
    const datesToSaveInFilter = {
      validFrom: validFromDate ? validFromDate.unix() : undefined,
      validUntil: validUntilDate ? validUntilDate.unix() : undefined,
    };

    updateFilter(datesToSaveInFilter);
  };

  const getDateForDatePicker = (value: number | undefined) => {
    return value ? moment.unix(value) : null;
  };

  return (
    <DocumentFilterWrapper>
      <div>
        {showValidityPeriodFields && (
          <>
            <NormalText verticalPadding color={themeObject[theme].primary} size="m">
              {t('validityOfData')}
            </NormalText>
            <Space direction="vertical">
              <DatePicker.RangePicker
                placeholder={[t('validFrom'), t('validUntil')]}
                allowEmpty={[true, true]}
                onChange={handleDateChange}
                value={[getDateForDatePicker(validFromDate), getDateForDatePicker(validUntilDate)]}
                style={{ height: 'auto' }}
                size="small"
                separator={<IconArrowNarrowRight size={22} color={themeObject[theme].textLight} />}
              />
            </Space>
            <Divider />
          </>
        )}
        <NormalText verticalPadding color={themeObject[theme].primary} size="m">
          {t('individualDocuments')}
        </NormalText>
        <Row style={{ marginBottom: 16 }}>
          <SelectTypeButton
            key={'excel'}
            onClick={() => handleFileTypeSelect('excel')}
            selected={filterDocumentTypes.includes('excel')}
            icon={<ExcelIcon style={{ marginRight: '0.2rem' }} />}
            shape="round"
          >
            Excel
          </SelectTypeButton>
          <SelectTypeButton
            key={'word'}
            onClick={() => handleFileTypeSelect('word')}
            selected={filterDocumentTypes.includes('word')}
            icon={<WordIcon style={{ marginRight: '0.2rem' }} />}
            shape="round"
          >
            Word
          </SelectTypeButton>
          <SelectTypeButton
            key={'pdf'}
            onClick={() => handleFileTypeSelect('pdf')}
            selected={filterDocumentTypes.includes('pdf')}
            icon={<PdfIcon style={{ marginRight: '0.2rem' }} />}
            shape="round"
          >
            PDF
          </SelectTypeButton>
        </Row>
        <SearchList
          listData={documents}
          isLoadingData={isLoadingDocuments}
          selectedItems={selectedDocuments}
          handleItemSelect={handleDocumentSelect}
        />
      </div>
    </DocumentFilterWrapper>
  );
};
