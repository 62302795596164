import { Button, Col, Row, Tooltip, UploadProps } from 'antd';
import { message as antMessage, Upload, UploadFile } from 'antd';
import React, { useEffect, useState } from 'react';
import TextArea from 'antd/lib/input/TextArea';
import { PaperClipOutlined, SendOutlined } from '@ant-design/icons';
import { useResponsive } from '@app/hooks/useResponsive';
import { SendMessageWrapper } from './SendMessage.styles';
import { useTranslation } from 'react-i18next';
import { useSpeechRecognition } from '@app/hooks/useSpeechRecognition';
import { IconPhotoPlus, IconPlayerStop, IconMicrophone, IconMessageX, IconSettings } from '@tabler/icons-react';
import { StopRecognitionIcon } from '@app/components/common/icons/StopRecognitionIcon';
import AIModeSwitcher from './AIModeSwitcher';
import LLMInlineSwitcher from './LLMInlineSwitcher';
import { GPT_MODEL_SELECTION } from '@app/utils/constants';
import { setTriggerActionId } from '@app/store/slices/chatBoxSlice';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import { debounce } from 'lodash';

interface SendMessageProps {
  messageIsProcessing: boolean;
  loadingMessageExists: boolean;
  allowFileUpload?: boolean;
  showAIModeSwitcher?: boolean;
  showSettings?: boolean;
  insideSidebar?: boolean;
  presetQuestion?: string;
  allowOnlyImagesUpload?: boolean;
  scroll?: React.MutableRefObject<HTMLSpanElement | undefined>;
  onFileUploaded?: (file: UploadFile) => void;
  onSendMessage: (message: string, uploadedFiles?: UploadFile[]) => void;
  handleOnStop?: () => void;
}

const MaxFileSize = 250 * 1024 * 1024;

const SendMessage: React.FC<SendMessageProps> = ({
  messageIsProcessing,
  loadingMessageExists,
  showAIModeSwitcher,
  showSettings,
  insideSidebar,
  presetQuestion,
  allowFileUpload,
  allowOnlyImagesUpload,
  onSendMessage,
  handleOnStop,
  onFileUploaded,
}) => {
  const [message, setMessage] = useState(presetQuestion ?? '');
  const dispatch = useAppDispatch();

  const { mobileOnly } = useResponsive();
  const { t } = useTranslation();
  const {
    isSpeechRecognitionAvailable,
    isSpeechRecognition,
    text,
    startContinuousSpeechRecognition,
    stopContinuousSpeechRecognition,
    setSpeechRecognitionText,
  } = useSpeechRecognition();

  const sendMessage = async () => {
    if (messageIsProcessing) return;
    const trimmedMessage = message.trim();
    if (trimmedMessage === '') {
      return;
    }
    onSendMessage(trimmedMessage);
    setMessage('');
    setSpeechRecognitionText('');
    //setFileList([]);
  };

  const handleKeyPress = (e: any) => {
    if (e.keyCode === 13 && e.shiftKey) {
      setMessage(message);
    } else if (e.keyCode === 13 && !e.shiftKey) {
      e.preventDefault();
      sendMessage();
    }
  };

  const validateFileType = ({ type }: UploadFile) => {
    const allowedTypes = allowOnlyImagesUpload
      ? ['image/png', 'image/jpeg']
      : [
          'application/pdf',
          'pdf',
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
          'word',
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          'excel',
          'image/png',
          'image/jpeg',
          'text/plain',
          'txt',
        ];
    if (type) {
      return allowedTypes.some((allowed) => type.includes(allowed));
    }
  };

  useEffect(() => {
    if (isSpeechRecognition && text) {
      setMessage((prevText) => {
        const isLastSignSpace = prevText[prevText.length - 1] === ' ';
        const isFirstSignInResultSpace = text[0] === ' ';
        const separator = !isLastSignSpace && !isFirstSignInResultSpace ? ' ' : '';
        return prevText + separator + text;
      });
    }
  }, [isSpeechRecognition, text]);

  const debouncedNotAllowedTypeError = debounce(
    () => antMessage.error(t(allowOnlyImagesUpload ? 'invalidImageTypeChat' : 'invalidFileTypeChat')),
    500,
  );

  const customRequest = (options: any) => {
    const { onSuccess } = options;
    onSuccess('done');
  };

  const props: UploadProps = {
    name: 'file',
    multiple: true,
    maxCount: allowOnlyImagesUpload ? 1 : 5,
    // onRemove: (file) => {
    //   const index = fileList.indexOf(file);
    //   const newFileList = fileList.slice();
    //   newFileList.splice(index, 1);
    //   setFileList(newFileList);
    // },
    customRequest,
    beforeUpload: (file: UploadFile, files) => {
      // if (files.length > 3) {
      //   debouncedMaxFileLimitWarning();
      //   return false;
      // }
      const isAllowedType = validateFileType(file);
      if (!isAllowedType) {
        debouncedNotAllowedTypeError();
        return false;
      }
      if (file.size) {
        const isLessThanMaxSize = file.size < MaxFileSize;
        if (!isLessThanMaxSize) {
          antMessage.error(t('fileToBigChat', { maxFileSize: MaxFileSize / 1024 / 1024 }));
          return false;
        } else {
          return true;
        }
      }
      return true;
    },
    onChange(info) {
      const { status } = info.file;
      if (status === 'done') {
        onFileUploaded?.(info.file);
      } else if (status === 'error') {
        antMessage.error(t('fileUploadError', { fileName: info.file.name }));
      }
    },
    onDrop(e) {
      console.log('Dropped files', e.dataTransfer.files);
    },
  };

  return (
    <SendMessageWrapper insideSidebar={insideSidebar || false}>
      <div
        style={{
          backgroundColor: 'var(--background-color)',
          border: '2px solid var(--border-color)',
          borderRadius: 8,
        }}
      >
        <Row justify={'space-between'}>
          <Col style={{ width: '100%' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {isSpeechRecognitionAvailable && (
                <div
                  style={{ cursor: 'pointer', marginLeft: '1rem' }}
                  onClick={() => {
                    if (isSpeechRecognition) {
                      stopContinuousSpeechRecognition();
                      return;
                    }

                    startContinuousSpeechRecognition();
                  }}
                >
                  {isSpeechRecognition ? (
                    <StopRecognitionIcon width={20} height={20} />
                  ) : (
                    <IconMicrophone size={20} color="#CDCDCD" />
                  )}
                </div>
              )}
              <TextArea
                autoSize={{ minRows: 1, maxRows: 6 }}
                size={mobileOnly ? 'middle' : 'large'}
                placeholder={t('messageToKiAssistant')}
                allowClear
                value={message}
                bordered={false}
                onChange={(e) => {
                  setSpeechRecognitionText('');
                  setMessage(e.target.value);
                }}
                onKeyDown={(e) => handleKeyPress(e)}
              />
            </div>
          </Col>
        </Row>
        <Row justify={'space-between'} style={{ marginLeft: '0.5rem', marginRight: '0.5rem' }} align={'middle'}>
          <Col style={{ width: mobileOnly ? '80%' : '85%' }}>
            <Row gutter={2} align={'middle'}>
              <Col>
                <Tooltip title={t('restartAiChat')}>
                  <Button
                    onClick={() => dispatch(setTriggerActionId({ value: 'RESET_MESSAGES' }))}
                    type="text"
                    icon={<IconMessageX size={14} />}
                  ></Button>
                </Tooltip>
              </Col>
              {showAIModeSwitcher && (
                <Col>
                  <AIModeSwitcher />
                </Col>
              )}
              <Col>
                <LLMInlineSwitcher models={GPT_MODEL_SELECTION} />
              </Col>
              {showSettings && (
                <Col>
                  <Tooltip title={t('furtherSettings')}>
                    <Button
                      type="text"
                      onClick={() => dispatch(setTriggerActionId({ value: 'SHOW_LLM_SETTINGS_DRAWER' }))}
                      icon={<IconSettings size={16} style={{ marginTop: '4px' }} />}
                    ></Button>
                  </Tooltip>
                </Col>
              )}
            </Row>
          </Col>
          <Col style={{ width: mobileOnly ? '20%' : '15%' }}>
            <div style={{ margin: '0.5rem' }}>
              <Row justify={'end'}>
                {allowFileUpload && (
                  <Col>
                    <div style={{ marginRight: '1rem' }}>
                      <Upload {...props} showUploadList={false}>
                        <Button
                          type="text"
                          icon={allowOnlyImagesUpload ? <IconPhotoPlus size={18} /> : <PaperClipOutlined />}
                        ></Button>
                      </Upload>
                    </div>
                  </Col>
                )}
                <Col>
                  <Button
                    loading={handleOnStop ? loadingMessageExists : messageIsProcessing}
                    type="primary"
                    onClick={() => (messageIsProcessing && handleOnStop ? handleOnStop() : sendMessage())}
                    icon={messageIsProcessing ? <IconPlayerStop /> : <SendOutlined />}
                  />
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </div>
    </SendMessageWrapper>
  );
};

export default SendMessage;
