export type AccessRight = 'READ_ONLY' | 'WRITE_AND_READ';
export type AccessRightType = 'OPEN_FOR_WHOLE_COMPANY' | 'ONLY_SPECIFIC_USERS';
export type AccessRightEntityType = 'KNOWLEDGE_AREA' | 'APP';

export const AppEntityIds = {
  SecureChatGpt: 'SECURE_CHAT_GPT',
  BasicDocumentAnalysis: 'BASIC_DOCUMENT_ANALYSIS',
  WebsiteMonitoring: 'WEBSITE_MONITORING',
  LawRadar: 'LAW_RADAR',
  SuperDos: 'SUPER_DOS',
  DataRoom: 'DATA_ROOM',
};

export interface IUserDetailedAccessRight {
  entityType: AccessRightEntityType;
  entityId: string;
  accessRight: AccessRight;
  // _id of IAccessRights
  accessRightId?: string;
}

export interface IUserDetailedAccessRights {
  userId: string;
  accessRights: IUserDetailedAccessRight[];
}

export interface IUserOverviewAccessRight {
  entityType: AccessRightEntityType;
  allowedItems: number;
}

export interface IUserOverviewAccessRights {
  userId: string;
  overviewAccessRights: IUserOverviewAccessRight[];
}

export interface IUserAccessRight {
  userId: string;
  accessRight: AccessRight;
}

// deprecated
export interface IDepartmentAccessRight {
  departmentId: string;
  accessRight: AccessRight;
}
export interface IAccessRights {
  companyId: string;
  entityType: AccessRightEntityType;
  entityId: string;
  generalType: AccessRightType;
  generalRight: AccessRight;
  specificUserRights: IUserAccessRight[];
  // deprecated
  departmentRights: IDepartmentAccessRight[];
  timestampLastUpdatedAt: number;
  _id?: string;
}
