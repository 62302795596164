import { useEffect, useState } from 'react';
import { useAppSelector } from './reduxHooks';
import { useErrorHandling } from './useErrorHandling';
import { ILawChangeReport } from '@app/types/lawTypes';
import { readAllLawChangeReports, updateLawChangeReport, deleteLawChangeReport } from '@app/api/lawChangeRport.api';

const ItemsPerPage = 200;

export const useHandleLawChangeReports = (lawId: string) => {
  const [lawChangeReports, setLawChangeReports] = useState<ILawChangeReport[]>([]);
  const [lawChangeReportLoading, setLawChangeReportLoading] = useState<boolean>(true);
  const [moreLawChangeReportsAvailable, setMoreLawChangeReportsAvailable] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);

  const companyState = useAppSelector((state) => state.company);

  const { handleApiError } = useErrorHandling();

  const getLawChangeReports = async (page: number) => {
    try {
      return await readAllLawChangeReports(companyState._id ?? '', lawId);
    } catch (error) {
      console.log(error);
      handleApiError(error);
    } finally {
      setLawChangeReportLoading(false);
    }
  };

  const loadAndSetLawChangeReports = async () => {
    if (companyState && lawId) {
      const reports = await getLawChangeReports(page);
      setLawChangeReports(reports ?? []);
      if (reports) {
        setMoreLawChangeReportsAvailable(reports.length === ItemsPerPage);
      }
    }
  };

  const loadMoreReports = async () => {
    setLawChangeReportLoading(true);
    const newPage = page + 1;
    setPage(newPage);
    const moreReports = await getLawChangeReports(newPage);
    if (moreReports) {
      setMoreLawChangeReportsAvailable(moreReports.length === ItemsPerPage);
      setLawChangeReports((prevReports) => [...prevReports, ...moreReports]);
    }
    setLawChangeReportLoading(false);
  };

  useEffect(() => {
    loadAndSetLawChangeReports();
  }, [companyState, lawId]);

  const onDeleteLawChangeReport = async (reportId: string) => {
    try {
      await deleteLawChangeReport(reportId);
      const reportsToSave = lawChangeReports.filter((report) => report._id !== reportId);
      setLawChangeReports(reportsToSave);
    } catch (error) {
      console.log('****** onDeleteLawChangeReport error: ', error);
      handleApiError(error);
    }
  };

  const onUpdateLawChangeReport = async (reportId: string, updatedReport: Partial<ILawChangeReport>) => {
    try {
      await updateLawChangeReport(reportId, updatedReport);
      onReportChanged(reportId, updatedReport);
    } catch (error) {
      console.log('****** onUpdateLawChangeReport error: ', error);
      handleApiError(error);
    }
  };

  const onReportCreated = (createdReport: ILawChangeReport) => {
    setLawChangeReports((prev) => [createdReport, ...prev]);
  };

  const onReportChanged = (reportId: string, updatedReport: Partial<ILawChangeReport>) => {
    const updatedReports = lawChangeReports.map((report) => {
      if (report._id === reportId) {
        return { ...report, ...updatedReport };
      } else {
        return report;
      }
    });
    setLawChangeReports(updatedReports);
  };

  return {
    lawChangeReports,
    lawChangeReportLoading,
    onDeleteLawChangeReport,
    loadMoreReports,
    loadAndSetLawChangeReports,
    onReportCreated,
    onUpdateLawChangeReport,
    onReportChanged,
    moreLawChangeReportsAvailable,
  };
};
