import { media } from '@app/styles/themes/constants';
import styled from 'styled-components';

export const MainContainer = styled.div`
  height: calc(100vh - 155px);
  background-color: var(--secondary-background-color);
  @media only screen and ${media.xl} {
    height: calc(100vh - 135px);
  }
`;

export const ChangesContainer = styled.div`
  height: calc(100vh - 105px);
  background-color: var(--background-color);
  overflow-y: auto;
  border-left: 1px solid var(--border-sidebar-color);
`;

export const ChangesContentContainer = styled.div`
  padding: 1rem;
`;

export const ReportsContainer = styled.div`
  height: calc(100vh - 105px);
  background-color: var(--background-color);
  overflow-y: auto;
  border-right: 1px solid var(--border-sidebar-color);
`;

export const ReportsContentContainer = styled.div`
  height: calc(100vh - 120px);
  background-color: var(--secondary-background-color);
  overflow: auto;
`;

export const DiffViewContainer = styled.div`
  height: calc(100vh - 105px);
  background-color: var(--background-color);
  overflow-y: auto;
`;

export const LawContentWrapper = styled.div`
  height: calc(100vh - 135px);
`;

export const LawPdfContentWrapper = styled.div`
  height: calc(100vh - 140px);
`;

type LawTextContentWrapperProps = { showBorder?: boolean };

export const LawTextContentWrapper = styled.div<LawTextContentWrapperProps>`
  height: calc(100vh - 215px);
  margin: 1rem 2rem;
  padding: 1rem;
  background-color: var(--background-color);
  overflow-y: auto;
  border-radius: 8px;
  border: ${(props) => (props.showBorder ? '1px solid var(--border-base-color)' : '')};
`;

export const StepsContainer = styled.div`
  margin-top: 1rem;
  height: 70px;
  overflow-x: auto;
`;

export const GeneratedContentContainer = styled.div`
  padding: 40px;
  margin: 0 auto 0 0;
  max-width: 1200px;
  height: 100%;
  min-height: 80vh;
`;

export const TextEditorContainer = styled.div`
  background-color: var(--additional-background-color);
  border-radius: 8px;
`;

export const EditorActionsContainer = styled.div`
  position: sticky;
  top: 0;
`;
