import styled from 'styled-components';
import { Button } from 'antd';

export const ScrollableWrapper = styled.div`
  max-height: 95vh;
  overflow-y: scroll;
`;

export const ElementSelection = styled.div`
  margin: 16px;
`;

export const ButtonsContainer = styled.div`
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 1rem;
  padding: 1rem;
  background-color: var(--dragable-item-background-color);
  border-radius: 6px;
  border: 1.5px solid var(--border-color);
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;
`;

export const AddButton = styled(Button)`
  margin-bottom: 1rem;
  min-width: 140px;
`;

export const PillsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const Pill = styled.p<{ isAddedToPrompt: boolean }>`
  margin-right: 1rem;
  padding: 0.5rem 1rem;
  background-color: ${({ isAddedToPrompt }) => (isAddedToPrompt ? 'var(--success-color)' : 'var(--background-color)')};
  border-radius: 6px;
  cursor: pointer;
`;

export const HeaderWrapper = styled.div`
  position: relative;
`;

export const IconButtonWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  padding: 0px 4px;
  border-radius: 4px;
  background-color: var(--dragable-item-background-color);
  border: 1px solid var(--border-color);
`;
