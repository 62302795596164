import { SuperDoTemplateInputInfo } from '@app/components/gpttemplates/templateDataInputMapping';
import { DocumentParseStrategy } from '@app/domain/KnowledgeAreaModel';

export type SuperDoTemplate = {
  _id: string;
  companyId: string;
  title: string;
  areaId: string;
  promptTemplate: string;
  description?: string;
  elements: SuperDoTemplateInputInfo[];
  createdBy: string;
  aiToolsToUse?: SuperDoAiTool[];
  documentParseStrategy?: DocumentParseStrategy;
};

export type SuperDoTemplateStore = {
  title: string;
  promptTemplate: string;
  description?: string;
  elements: SuperDoTemplateInputInfo[];
  aiToolsToUse?: SuperDoAiTool[];
  documentParseStrategy?: DocumentParseStrategy;
  schemaVersion: number;
};

export type SuperDoAiToolName = 'DALL_E' | 'GOOGLE_SEARCH' | 'WEB_BROWSER' | 'DEEP_DOCUMENT_PARSER';

export type SuperDoAiTool = {
  name: SuperDoAiToolName;
  parameters: Record<string, any>[];
};

export type SuperDoAiToolOption = {
  name: SuperDoAiToolName;
  label: string;
};

export const AiTools: SuperDoAiToolOption[] = [
  { name: 'DALL_E', label: 'Dall-E' },
  { name: 'GOOGLE_SEARCH', label: 'Google Search' },
  { name: 'WEB_BROWSER', label: 'Webbrowser' },
  { name: 'DEEP_DOCUMENT_PARSER', label: 'Advanced Document Parser' },
];
