import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row, Select, Space, Switch, Tooltip } from 'antd';
import { Form, Input } from 'antd';
import * as S from './GeneralSettings.styles';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { notificationController } from '@app/controllers/notificationController';
import { NormalText } from '../common/BaseTexts/BaseTexts';
import { Button as GradientButton } from '@app/components/common/buttons/Button/Button';
import { ISourceUpdateMonitoring } from '@app/domain/SourceUpdateMonitoringModel';
import { updateSourceUpdateMonitoring } from '@app/api/sourceUpdateMonitoring.api';
import { ReactMultiEmail } from 'react-multi-email';
import 'react-multi-email/dist/style.css';
import { BaseSelect } from '../common/selects/BaseSelect/BaseSelect';
import { InfoCircleOutlined } from '@ant-design/icons';

type GeneralSettingsProps = {
  selectedSourceMonitoring: ISourceUpdateMonitoring | null;
  onUpdateItem: (selectedSourceMonitoring: ISourceUpdateMonitoring | null) => void;
};

export const GeneralSettings: React.FC<GeneralSettingsProps> = ({ selectedSourceMonitoring, onUpdateItem }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const [sourceMonitoring, setSourceMonitoring] = useState<ISourceUpdateMonitoring | null>(null);
  const [emails, setEmails] = React.useState<string[]>([]);
  const [isActive, setIsActive] = React.useState(false);

  const companyState = useAppSelector((state) => state.company);

  const { Option } = Select;

  useEffect(() => {
    form.setFieldsValue({
      ...selectedSourceMonitoring,
      intervalCheckForUpdates: selectedSourceMonitoring?.intervalCheckForUpdatesInHours === 24 ? 'daily' : 'weekly',
    });
    setSourceMonitoring(selectedSourceMonitoring);
    setEmails(selectedSourceMonitoring?.sendNotificationsToEmails || []);
    setIsActive(selectedSourceMonitoring?.isActive || false);
  }, [form, selectedSourceMonitoring]);

  const onSave = async () => {
    if (sourceMonitoring) {
      const title = form.getFieldValue('title') ?? '';
      const description = form.getFieldValue('description') ?? '';
      const contextDescription = form.getFieldValue('contextDescription') ?? '';
      const ignoreTopicsDescription = form.getFieldValue('ignoreTopicsDescription') ?? '';
      const intervalCheckForUpdates = form.getFieldValue('intervalCheckForUpdates') ?? '';
      const sendNotificationsIfNoChangesFound = form.getFieldValue('sendNotificationsIfNoChangesFound') ?? false;
      const intervalCheckForUpdatesInHours = intervalCheckForUpdates === 'daily' ? 24 : 168;
      const updatedSourceMonitoring = {
        ...sourceMonitoring,
        title,
        description,
        contextDescription,
        ignoreTopicsDescription,
        sendNotificationsToEmails: emails,
        intervalCheckForUpdatesInHours,
        sendNotificationsIfNoChangesFound,
        isActive,
      };
      try {
        await updateSourceUpdateMonitoring(selectedSourceMonitoring?._id ?? '', {
          title,
          description,
          contextDescription,
          ignoreTopicsDescription,
          sendNotificationsToEmails: emails,
          intervalCheckForUpdatesInHours,
          sendNotificationsIfNoChangesFound,
          isActive,
        });
        setSourceMonitoring(updatedSourceMonitoring);
        onUpdateItem(updatedSourceMonitoring);
        notificationController.success({ message: t('common.success') });
      } catch (error) {
        if (error instanceof Error) {
          notificationController.error({ message: error.message });
        } else if (typeof error === 'string') {
          notificationController.error({ message: error });
        } else {
          notificationController.error({ message: 'Fehler beim Zugriff auf den Server' });
        }
      }
    }
  };

  return (
    <>
      {!companyState._id && <NormalText>{t('adminCreateCompany')}</NormalText>}
      <Col xs={24} xl={16}>
        <Form form={form}>
          <Form.Item
            key={'title'}
            labelCol={{ span: 24 }}
            label={
              <Space size={'large'}>
                <NormalText>{t('sourceUpdateMonitoring.title')}</NormalText>
                <Switch
                  checked={isActive}
                  onChange={setIsActive}
                  checkedChildren="Active"
                  unCheckedChildren="Inactive"
                />
              </Space>
            }
            name={'title'}
            rules={[{ required: true, message: t('requiredField') }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            key={'description'}
            labelCol={{ span: 24 }}
            label={t('sourceUpdateMonitoring.descriptionOptional')}
            name={'description'}
          >
            <Input.TextArea rows={2} />
          </Form.Item>
          <Form.Item
            name="intervalCheckForUpdates"
            labelCol={{ span: 24 }}
            label={t('sourceUpdateMonitoring.intervalCheckForUpdatesInHours')}
            rules={[{ required: true, message: t('requiredField') }]}
          >
            <BaseSelect placeholder={t('sourceUpdateMonitoring.intervalCheckForUpdatesInHours')}>
              <Option value="daily">{t('sourceUpdateMonitoring.daily')}</Option>
              <Option value="weekly">{t('sourceUpdateMonitoring.weekly')}</Option>
            </BaseSelect>
          </Form.Item>
          <Form.Item
            key={'sendNotificationsToEmails'}
            labelCol={{ span: 24 }}
            label={t('sourceUpdateMonitoring.sendNotificationsToEmail')}
            name={'sendNotificationsToEmails'}
            rules={[{ required: true, message: t('requiredField') }]}
          >
            <ReactMultiEmail
              placeholder="Email list"
              emails={emails}
              onChange={(_emails: string[]) => {
                setEmails(_emails);
              }}
              style={{ backgroundColor: 'var(--secondary-background-color)' }}
              getLabel={(email, index, removeEmail) => {
                return (
                  <div data-tag key={index}>
                    <div data-tag-item>{email}</div>
                    <span data-tag-handle onClick={() => removeEmail(index)}>
                      ×
                    </span>
                  </div>
                );
              }}
            />
          </Form.Item>
          <Form.Item
            name="sendNotificationsIfNoChangesFound"
            key="sendNotificationsIfNoChangesFound"
            label={t('sourceUpdateMonitoring.sendAlsoEmailIfNoChangesFound')}
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>
          <Form.Item
            key={'contextDescription'}
            labelCol={{ span: 24 }}
            label={
              <Space>
                <NormalText>{t('sourceUpdateMonitoring.contextDescription')}</NormalText>
                <Tooltip title={t('sourceUpdateMonitoring.contextDescriptionToolTip')}>
                  <InfoCircleOutlined />
                </Tooltip>
              </Space>
            }
            name={'contextDescription'}
          >
            <Input.TextArea rows={4} />
          </Form.Item>
          <Form.Item
            key={'ignoreTopicsDescription'}
            labelCol={{ span: 24 }}
            label={
              <Space>
                <NormalText>{t('sourceUpdateMonitoring.ignoreTopicsDescription')}</NormalText>
                <Tooltip title={t('sourceUpdateMonitoring.ignoreTopicsDescriptionToolTip')}>
                  <InfoCircleOutlined />
                </Tooltip>
              </Space>
            }
            name={'ignoreTopicsDescription'}
          >
            <Input.TextArea rows={4} />
          </Form.Item>
        </Form>
        <S.FooterContainer>
          <Row justify={'end'}>
            <div />
            <GradientButton type="primary" onClick={() => onSave()}>
              {t('save')}
            </GradientButton>
          </Row>
        </S.FooterContainer>
      </Col>
    </>
  );
};
