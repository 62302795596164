import { useEffect, useRef, useState } from 'react';
import { useAppSelector } from './reduxHooks';
import { useErrorHandling } from './useErrorHandling';
import { ILawChange, ILawEntry } from '@app/types/lawTypes';
import { readAllLawEntries, deleteLawEntry, updateLawEntry, getLawEntry } from '@app/api/lawEntries.api';

const ItemsPerPage = 200;
const AutoRefreshInterval = 20000;

type UseHandleLawEntriesProps = {
  autoRefreshIfSomeItemIsLoading?: boolean;
  autoRefreshStateCallback?: (autoRefreshActive: boolean) => void;
  specificLawId?: string;
};

export const useHandleLawEntries = (props: UseHandleLawEntriesProps) => {
  const [lawEntries, setLawEntries] = useState<ILawEntry[]>([]);
  const [lawEntryLoading, setLawEntryLoading] = useState<boolean>(true);
  const [moreLawEntriesAvailable, setMoreLawEntriesAvailable] = useState<boolean>(false);
  const [autoRefreshInitialized, setAutoRefreshInitialized] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);

  const companyState = useAppSelector((state) => state.company);

  const { handleApiError } = useErrorHandling();

  const refreshIntervalTimer = useRef<NodeJS.Timer>();

  useEffect(() => {
    if (props.autoRefreshIfSomeItemIsLoading && lawEntries.length && !autoRefreshInitialized) {
      setAutoRefreshInitialized(true);

      const someInProgress = (toCheckLawEntries?: ILawEntry[]) =>
        toCheckLawEntries
          ? toCheckLawEntries.some((law) => law.scanningState === 'IN_PROGRESS')
          : lawEntries.some((law) => law.scanningState === 'IN_PROGRESS');

      if (someInProgress()) {
        props.autoRefreshStateCallback?.(true);
        refreshIntervalTimer.current && clearInterval(refreshIntervalTimer.current);
        refreshIntervalTimer.current = setInterval(async () => {
          const inPrgress = someInProgress();
          if (inPrgress) {
            props.autoRefreshStateCallback?.(true);
            const reloadedEntries = await loadAndSetLawEntries();
            if (!someInProgress(reloadedEntries)) {
              clearInterval(refreshIntervalTimer.current);
            }
          } else {
            props.autoRefreshStateCallback?.(false);
          }
        }, AutoRefreshInterval);
      } else {
        props.autoRefreshStateCallback?.(false);
      }
    }
  }, [lawEntries]);

  useEffect(() => {
    return () => refreshIntervalTimer.current && clearInterval(refreshIntervalTimer.current);
  }, []);

  const getLawEntries = async (page: number) => {
    try {
      if (props.specificLawId) {
        const lawEntry = await getLawEntry(props.specificLawId ?? '');
        if (lawEntry) {
          return [lawEntry];
        }
      }
      // TODO: handle pagination
      return await readAllLawEntries(companyState._id ?? '', true);
    } catch (error) {
      console.log(error);
      handleApiError(error);
    } finally {
      setLawEntryLoading(false);
    }
  };

  const loadAndSetLawEntries = async () => {
    if (companyState) {
      const entries = await getLawEntries(page);
      setLawEntries(entries ?? []);
      if (entries) {
        setMoreLawEntriesAvailable(entries.length === ItemsPerPage);
        return entries;
      }
    }
  };

  const loadMoreEntries = async () => {
    setLawEntryLoading(true);
    const newPage = page + 1;
    setPage(newPage);
    const moreEntries = await getLawEntries(newPage);
    if (moreEntries) {
      setMoreLawEntriesAvailable(moreEntries.length === ItemsPerPage);
      setLawEntries((prevEntries) => [...prevEntries, ...moreEntries]);
    }
    setLawEntryLoading(false);
  };

  useEffect(() => {
    loadAndSetLawEntries();
  }, [companyState]);

  const onDeleteLawEntry = async (lawEntryId: string) => {
    try {
      await deleteLawEntry(lawEntryId);
      const lawEntriesToSave = lawEntries.filter((entry) => entry._id !== lawEntryId);
      setLawEntries(lawEntriesToSave);
    } catch (error) {
      console.log('****** onDeleteLawEntry error: ', error);
      handleApiError(error);
    }
  };

  const onLawEntryWasUpdated = async (lawEntryId: string, updatedEntry: ILawEntry) => {
    const updatedLawEntries = lawEntries.map((entry) => {
      if (entry._id === lawEntryId) {
        return { ...entry, ...updatedEntry };
      } else {
        return entry;
      }
    });
    setLawEntries(updatedLawEntries);
  };

  const onUpdateLawEntry = async (lawEntryId: string, updatedEntry: ILawEntry) => {
    try {
      await updateLawEntry(lawEntryId, updatedEntry);
      onLawEntryWasUpdated(lawEntryId, updatedEntry);
    } catch (error) {
      console.log('****** onDeleteLawEntry error: ', error);
      handleApiError(error);
    }
  };

  const onUpdateLawEntryChanges = async (lawEntryId: string, changes: ILawChange[]) => {
    try {
      await updateLawEntry(lawEntryId, { changes });
      const updatedLawEntries = lawEntries.map((entry) => {
        if (entry._id === lawEntryId) {
          return { ...entry, changes };
        } else {
          return entry;
        }
      });
      setLawEntries(updatedLawEntries);
    } catch (error) {
      console.log('****** onDeleteLawEntry error: ', error);
      handleApiError(error);
    }
  };

  const onEntryCreated = (createdEntry: ILawEntry) => {
    setLawEntries((prev) => [createdEntry, ...prev]);
  };

  return {
    lawEntries,
    lawEntryLoading,
    onDeleteLawEntry,
    loadMoreEntries,
    loadAndSetLawEntries,
    onEntryCreated,
    onUpdateLawEntry,
    onUpdateLawEntryChanges,
    onLawEntryWasUpdated,
    moreLawEntriesAvailable,
  };
};
