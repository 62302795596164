import styled from 'styled-components';
import { Layout } from 'antd';

const { Content } = Layout;

export default styled(Content)`
  padding: 0;
  height: 100vh;
  display: grid;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: var(--background-color);
`;
