import React, { useEffect, useState } from 'react';
import { Modal, Form, Input, Select, Button, message } from 'antd';
import { autoImportLawEntry, createLawEntry } from '@app/api/lawEntries.api';
import { ILawEntry, LawSource } from '@app/types/lawTypes';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { useErrorHandling } from '@app/hooks/useErrorHandling';
import { useTranslation } from 'react-i18next';
import { DownloadOutlined } from '@ant-design/icons';
import moment from 'moment';

interface ModalCreateLawEntryProps {
  visible: boolean;
  editLawEntry: ILawEntry | null;
  onClose: () => void;
  onEntryCreated: (createdEntry: ILawEntry) => void;
  onUpdateLawEntry: (lawEntryId: string, createdEntry: ILawEntry) => void;
}

const ModalCreateLawEntry: React.FC<ModalCreateLawEntryProps> = ({
  editLawEntry,
  visible,
  onClose,
  onUpdateLawEntry,
  onEntryCreated,
}) => {
  const [form] = Form.useForm();

  const [autoImportLoading, setAutoImportLoading] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const companyState = useAppSelector((state) => state.company);
  const user = useAppSelector((state) => state.user.user);

  const { handleApiError } = useErrorHandling();
  const { t } = useTranslation();

  useEffect(() => {
    form.resetFields();
    if (editLawEntry) {
      form.setFieldsValue({ ...editLawEntry });
      form.setFieldsValue({ source: 'fedlex' });
    }
  }, [editLawEntry]);

  const handleCreate = async () => {
    form.validateFields().then(async (values: ILawEntry) => {
      try {
        setConfirmLoading(true);
        if (editLawEntry) {
          const updatedEntry: ILawEntry = {
            ...values,
            lawId: values.lawId.trim(),
            title: values.title.trim(),
            shortTitle: values.shortTitle?.trim(),
            url: values.url.trim(),
            historyUrl: values.historyUrl.trim(),
          };
          await onUpdateLawEntry(editLawEntry._id, updatedEntry);
        } else {
          const createdEntry: ILawEntry = {
            ...values,
            lawId: values.lawId.trim(),
            title: values.title.trim(),
            shortTitle: values.shortTitle?.trim(),
            url: values.url.trim(),
            historyUrl: values.historyUrl.trim(),
            currentVersion: values.currentVersion.trim(),
            companyId: companyState._id ?? '',
            createdBy: user?._id ?? '',
            createdAt: moment().unix(),
          };
          const id = await createLawEntry(createdEntry);
          createdEntry._id = id;
          onEntryCreated(createdEntry);
        }
        form.resetFields();
        onClose();
      } catch (error) {
        console.error('Error creating law entry:', error);
        handleApiError(error);
      } finally {
        setConfirmLoading(false);
      }
    });
  };

  const handleAutoImport = async () => {
    form.validateFields(['url', 'source']).then(async (values: ILawEntry) => {
      try {
        setAutoImportLoading(true);
        const autoImportData = await autoImportLawEntry(values.url.trim(), values.source.trim() as LawSource);

        if (autoImportData.lawIdAlreadyExists) {
          message.warn(`The Law \"${autoImportData.lawId}\" already exists`);
        }
        delete autoImportData.lawIdAlreadyExists;

        form.setFieldsValue({ ...autoImportData });
      } catch (error) {
        console.error('Error creating law entry:', error);
        handleApiError(error);
      } finally {
        setAutoImportLoading(false);
      }
    });
  };

  return (
    <Modal
      title={editLawEntry ? t('lawRadar.editLawEntry') : t('lawRadar.createLawEntry')}
      open={visible}
      onCancel={onClose}
      onOk={handleCreate}
      confirmLoading={confirmLoading}
    >
      <Form form={form} layout="vertical">
        <Form.Item
          key={'source'}
          labelCol={{ span: 24 }}
          label={t('lawRadar.source')}
          name={'source'}
          rules={[{ required: true, message: t('requiredField') }]}
        >
          <Select placeholder={t('lawRadar.source')}>
            <Select.Option value="fedlex">Fedlex</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item name="url" label={t('lawRadar.url')} rules={[{ required: true }]}>
          <Input />
        </Form.Item>

        <Button
          size="small"
          onClick={() => handleAutoImport()}
          loading={autoImportLoading}
          style={{ marginBottom: '1rem' }}
          icon={<DownloadOutlined />}
        >
          {t('lawRadar.autoImport')}
        </Button>

        <Form.Item name="historyUrl" label={t('lawRadar.historyUrl')} rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item name="lawId" label={t('lawRadar.lawId')} rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item name="title" label={t('lawRadar.inputTitle')} rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item name="shortTitle" label={t('lawRadar.inputShortTitle')}>
          <Input />
        </Form.Item>
        <Form.Item name="currentVersion" label={t('lawRadar.currentVersion')} rules={[{ required: true }]}>
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ModalCreateLawEntry;
