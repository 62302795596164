import styled from 'styled-components';
import { Tabs } from 'antd';
import { media } from '@app/styles/themes/constants';

export const LayoutContainer = styled.div`
  overflow-y: scroll;
  @media only screen and ${media.md} {
    height: calc(90vh - 120px);
  }

  @media only screen and ${media.xl} {
    height: calc(90vh - 95px);
  }
`;

export const HeaderContainer = styled.div`
  padding: 0px 12px;
  background-color: var(--background-color);
  height: 30px;
`;

export const StyledTabs = styled(Tabs)`
  & .ant-tabs-nav-wrap {
    background-color: var(--background-color);
    border-bottom: 2px solid var(--border-color);
  }
`;
