import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface LawRerpotState {
  optionUseAiForReport: boolean;
  optionConsiderDiffSummary: boolean;
  optionAddCustomPrompt: boolean;
  optionExamplesForOutputFormat: boolean;
  additionalPrompt?: string;
  examplesForOutputFormat?: string;
  lastSelectedStartDate?: number;
  lastSelectedEndDate?: number;
}

const initialState: LawRerpotState = {
  optionUseAiForReport: false,
  optionConsiderDiffSummary: false,
  optionAddCustomPrompt: false,
  optionExamplesForOutputFormat: false,
};

export const lawReportsSlice = createSlice({
  name: 'lawReportsSlice',
  initialState,
  reducers: {
    setOptionConsiderDiffSummary: (state, { payload }: PayloadAction<boolean>) => {
      state.optionConsiderDiffSummary = payload;
    },
    setOptionOptionUseAiForReport: (state, { payload }: PayloadAction<boolean>) => {
      state.optionUseAiForReport = payload;
    },
    setOptionAddCustomPrompt: (state, { payload }: PayloadAction<boolean>) => {
      state.optionAddCustomPrompt = payload;
    },
    setOptionExamplesForOutputFormat: (state, { payload }: PayloadAction<boolean>) => {
      state.optionExamplesForOutputFormat = payload;
    },
    setAdditionalPrompt: (state, { payload }: PayloadAction<string | undefined>) => {
      state.additionalPrompt = payload;
    },
    setExamplesForOutputFormat: (state, { payload }: PayloadAction<string | undefined>) => {
      state.examplesForOutputFormat = payload;
    },
    setLastSelectedStartDate: (state, { payload }: PayloadAction<number | undefined>) => {
      state.lastSelectedStartDate = payload;
    },
    setLastSelectedEndDate: (state, { payload }: PayloadAction<number | undefined>) => {
      state.lastSelectedEndDate = payload;
    },
  },
});

export const {
  setOptionConsiderDiffSummary,
  setOptionAddCustomPrompt,
  setOptionExamplesForOutputFormat,
  setAdditionalPrompt,
  setExamplesForOutputFormat,
  setLastSelectedStartDate,
  setLastSelectedEndDate,
  setOptionOptionUseAiForReport,
} = lawReportsSlice.actions;

export default lawReportsSlice.reducer;
