import React, { useState } from 'react';
import * as S from './DocumentContainer.styles';
import { NormalText } from '../common/BaseTexts/BaseTexts';
import { useTranslation } from 'react-i18next';
import { Avatar, Col, Dropdown, Modal, Row, Space, Table } from 'antd';
import { DocumentChecklist } from '@app/types/documentCheckTypes';
import { Button } from '../common/buttons/Button/Button';
import moment from 'moment';
import { IconCopy, IconDotsVertical, IconEdit, IconTrash } from '@tabler/icons-react';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { themeObject } from '@app/styles/themes/themeVariables';
import { Link, useNavigate } from 'react-router-dom';
import { ModalCreateDocumentChecklistBasic } from '../gpttemplates/DocumentCheck/ModalCreateDocumentChecklistBasic';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useHandleChecklists } from '@app/hooks/useHandleChecklists';

export const ChecklistsOverviewContainer: React.FC = () => {
  const { t } = useTranslation();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const theme = useAppSelector((state) => state.theme.theme);

  const {
    documentChecklists,
    documentChecklistLoading,
    onDuplicate,
    onDeleteDocumentChecklist,
    getUserCreatorOfChecklist,
  } = useHandleChecklists();

  const navigate = useNavigate();

  const showModal = () => {
    setIsModalVisible(true);
  };

  const menuItems = [
    { label: 'Edit', key: 'edit', icon: <IconEdit size={16} /> },
    { label: 'Duplicate', key: 'duplicate', icon: <IconCopy size={16} /> },
    { label: 'Delete', key: 'delete', icon: <IconTrash size={16} /> },
  ];

  const onDelete = async (item: DocumentChecklist) => {
    Modal.confirm({
      title: t('documentChecker.deleteChecklist'),
      icon: <ExclamationCircleOutlined />,
      content: t('documentChecker.areYouSureToDeleteChecklist'),
      okText: t('yes'),
      okType: 'danger',
      cancelText: t('no'),
      onOk() {
        onDeleteDocumentChecklist(item._id ?? '');
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };

  const openEditModal = (item: DocumentChecklist) => {
    navigate(`/zen/edit-checklist/${item._id}`);
  };

  const getCategoryText = (item: DocumentChecklist) => {
    if (item.category === 'LEGAL_DOCUMENT') {
      return 'Legal';
    } else if (item.category === 'TECHNICAL_DOCUMENT') {
      return 'Technical';
    } else {
      return 'Other';
    }
  };

  return (
    <S.ChecklistContainerWrapper>
      <S.HeaderContainerWrapper>
        <Row justify={'space-between'} align={'bottom'}>
          <Col>
            <NormalText bold size="xl">
              {t('documentChecker.overviewChecklists')}
            </NormalText>
            <NormalText verticalPadding>{t('documentChecker.overviewChecklistsDescription')}</NormalText>
          </Col>
          <Col>
            <Button type="primary" style={{ float: 'right' }} onClick={showModal}>
              {t('documentChecker.createChecklist')}
            </Button>
          </Col>
        </Row>
      </S.HeaderContainerWrapper>
      <Table
        dataSource={documentChecklists}
        loading={documentChecklistLoading}
        pagination={{ hideOnSinglePage: true, defaultPageSize: 50, size: 'small' }}
        rowKey={(item) => `${item._id}`}
        columns={[
          {
            title: t('documentChecker.checklistName') as string,
            dataIndex: 'name',
            key: 'name',
            render: (_, record) => (
              <Link to={`/zen/edit-checklist/${record._id}`}>
                <NormalText semiBold size="s">
                  {record.name}
                </NormalText>
              </Link>
            ),
            defaultSortOrder: null,
            sorter: (a, b) => a.name.localeCompare(b.name),
          },
          {
            title: t('documentChecker.checklistCategory') as string,
            dataIndex: 'category',
            key: 'category',
            defaultSortOrder: null,
            sorter: (a, b) => {
              const aCompare = a.category || 'OTHER_DOCUMENT';
              const bCompare = b.category || 'OTHER_DOCUMENT';
              return aCompare.localeCompare(bCompare);
            },
            render: (_, record) => <NormalText size="s">{getCategoryText(record)}</NormalText>,
          },
          {
            title: t('documentChecker.numberOfQuestions') as string,
            dataIndex: 'numberOfQuestions',
            key: 'numberOfQuestions',
            defaultSortOrder: null,
            sorter: (a, b) => {
              const aCompare = a.elements.length || 0;
              const bCompare = b.elements.length || 0;
              return aCompare - bCompare;
            },
            render: (_, record) => <NormalText size="s">{record.elements.length || 0}</NormalText>,
          },
          {
            title: t('dataroomTable.createdBy') as string,
            dataIndex: 'userTitle',
            key: 'userTitle',
            defaultSortOrder: null,
            sorter: (a, b) => {
              const userA = getUserCreatorOfChecklist(a.createdBy);
              const userB = getUserCreatorOfChecklist(b.createdBy);
              return userA.firstName.localeCompare(userB.firstName);
            },
            render: (_, record) => {
              const user = getUserCreatorOfChecklist(record.createdBy);
              return (
                <Space>
                  <Avatar
                    alt="User"
                    shape="circle"
                    size={'default'}
                    src={user?.profileImage?.smallUrl}
                    style={{ backgroundColor: themeObject[theme].avatarBg }}
                  >
                    {user?.firstName ? user?.firstName.slice(0, 2).toUpperCase() : ''}
                  </Avatar>
                  <NormalText size="s">{user?.firstName ? `${user.firstName} ${user.lastName}` : '-'}</NormalText>
                </Space>
              );
            },
          },
          {
            title: t('documentChecker.checklistCreatedAt') as string,
            dataIndex: 'timestampLastUpdatedAt',
            key: 'timestampLastUpdatedAt',
            defaultSortOrder: 'descend',
            sorter: (a, b) => (a.timestamp && b.timestamp ? a.timestamp - b.timestamp : 0),
            render: (_, record) => (
              <NormalText size="s">
                {record.timestamp ? moment.unix(record.timestamp).format('DD.MM.YY HH:mm') : '-'}
              </NormalText>
            ),
          },
          {
            title: t('documentChecker.checklistActions') as string,
            key: 'actions',
            width: '50px',
            render: (_, item) => (
              <Dropdown
                menu={{
                  items: menuItems,
                  onClick: ({ key }) => {
                    if (key === 'edit') openEditModal(item);
                    if (key === 'duplicate') onDuplicate(item);
                    if (key === 'delete') onDelete(item);
                  },
                }}
                trigger={['click']}
              >
                <div style={{ padding: '0.5rem', cursor: 'pointer' }}>
                  <IconDotsVertical color={themeObject[theme].textLight} />
                </div>
              </Dropdown>
            ),
          },
        ]}
      />
      <ModalCreateDocumentChecklistBasic
        showDocumentChecklistModal={isModalVisible}
        onCloseModal={() => setIsModalVisible(false)}
        onDocumentChecklistCreated={(id) => navigate(`/zen/edit-checklist/${id}`)}
      />
    </S.ChecklistContainerWrapper>
  );
};
