import styled from 'styled-components';
import { Button, Input } from 'antd';

export const SelectionOptions = styled.div`
  margin-top: 10px;
`;

export const OptionInput = styled(Input)`
  width: calc(100% - 32px);
  margin-right: 8px;
`;

export const PageLayout = styled.div`
  padding: 16px;
`;

export const ElementConfiguration = styled.div`
  flex-basis: 100%;
`;

export const ElementCard = styled.div`
  width: 100%;
  position: relative;
  padding: 16px;
  border: 1px solid var(--border-color);
  border-radius: 8px;
  margin-bottom: 10px;
  background-color: var(--dragable-item-background-color);
`;

export const EmptyContainer = styled.div`
  margin-top: 25%;
`;

export const AddInputContainer = styled.div`
  margin: 2rem auto;
  text-align: center;
`;

export const RemoveIconButton = styled(Button)`
  position: absolute;
  z-index: 10;
  top: 10px;
  right: 10px;
`;

export const OptionItem = styled.div`
  margin-bottom: 1rem;
`;
