import React from 'react';
import {
  DatabaseOutlined,
  RocketOutlined,
  FilePdfOutlined,
  ThunderboltOutlined,
  ScanOutlined,
  FolderOpenFilled,
  BankOutlined,
  NotificationOutlined,
  UserOutlined,
  SettingOutlined,
  FileSearchOutlined,
} from '@ant-design/icons';
import { IconDeviceDesktopAnalytics } from '@tabler/icons-react';
import { UserRole } from '@app/domain/UserModel';
import { AppEntityIds } from '@app/domain/AccessRightsModel';

export interface SidebarNavigationItem {
  title: string;
  key: string;
  url?: string;
  children?: SidebarNavigationItem[];
  icon?: React.ReactNode;
  visibleForRole?: UserRole[];
  hidden?: boolean;
}

export const sidebarNavigation: SidebarNavigationItem[] = [
  {
    title: 'dashboard.shortTitle',
    key: AppEntityIds.SecureChatGpt,
    url: '/',
    icon: <ThunderboltOutlined />,
  },
  {
    title: 'gptTemplates.shortTitle',
    key: AppEntityIds.SuperDos,
    url: '/superdos',
    icon: <RocketOutlined />,
  },
  {
    title: 'contractAnalyze.shortTitle',
    key: AppEntityIds.BasicDocumentAnalysis,
    url: '/contractanalyze',
    icon: <FilePdfOutlined />,
  },
  {
    title: 'sourceUpdateMonitoring.shortTitle',
    key: AppEntityIds.WebsiteMonitoring,
    url: '/source-monitoring',
    icon: <ScanOutlined />,
  },
  {
    title: 'lawRadar.shortTitle',
    key: AppEntityIds.LawRadar,
    url: '/law-radar',
    icon: <FileSearchOutlined />,
    hidden: process.env.REACT_APP_ENABLE_LAW_RADAR_APP === 'true' ? false : true,
  },
  // {
  //   title: 'legalApps.shortTitle',
  //   key: 'legalApps',
  //   icon: <ReadOutlined />,
  //   children: [
  //     {
  //       title: 'draft.shortTitle',
  //       key: 'legaldraft',
  //       url: '/legaldraft',
  //     },
  //   ],
  // },
  // {
  //   title: 'industryApps.shortTitle',
  //   key: 'industryApps',
  //   icon: <ExceptionOutlined />,
  //   children: [
  //     {
  //       title: 'caveat.shortTitle',
  //       key: 'caveatanalyze',
  //       url: '/caveatanalyze',
  //     },
  //     {
  //       title: 'techOfferDocument.shortTitle',
  //       key: 'techofferdocumentanalyze',
  //       url: '/techofferdocumentanalyze',
  //     },
  //   ],
  // },
  {
    title: 'dataroom.shortTitle',
    key: AppEntityIds.DataRoom,
    url: '/dataroom',
    icon: <DatabaseOutlined />,
  },
];

export const settingsNavigation: SidebarNavigationItem[] = [
  {
    title: 'myAccount.shortTitle',
    key: 'account',
    url: '/profile',
    icon: <UserOutlined />,
  },
  {
    title: 'myNotifications.shortTitle',
    key: 'notifications',
    url: '/notifications',
    icon: <NotificationOutlined />,
  },
  {
    title: 'myCompany.shortTitle',
    key: 'company',
    url: '/company-overview',
    icon: <BankOutlined />,
    visibleForRole: ['ADMIN', 'MANAGER'],
  },
  {
    title: 'myRightsManagement.shortTitle',
    key: 'rights-management',
    url: '/rights-management',
    icon: <FolderOpenFilled />,
    visibleForRole: ['ADMIN', 'MANAGER'],
  },
  {
    title: 'usageAnalytics.shortTitle',
    key: 'usage-analytics',
    url: '/usage-analytics',
    icon: <IconDeviceDesktopAnalytics />,
    visibleForRole: ['ADMIN', 'MANAGER'],
  },
  {
    title: 'generalSettings.shortTitle',
    key: 'settings',
    url: '/platform-settings',
    icon: <SettingOutlined />,
    visibleForRole: ['ADMIN'],
  },
];
