import React, { useContext } from 'react';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { Row, Dropdown, Menu, Modal } from 'antd';
import { NodeRendererProps } from 'react-arborist';
import {
  IconChevronRight,
  IconChevronDown,
  IconDots,
  IconPencil,
  IconBrandDatabricks,
  IconPlus,
  IconTrash,
} from '@tabler/icons-react';
import { MenuNode } from '@app/types/menuFolderTypes';
import { NormalText } from '@app/components/common/BaseTexts/BaseTexts';
import {
  MenuItem,
  FolderMark,
  MenuItemActionsContainer,
  NodeContent,
  TextLimitContainer,
  ChevronIconWrapper,
} from './MenuNodeItem.styles';
import { TreeActionsContext } from './SiderKnowledgeMenu';
import { darkenHexColor } from '@app/styles/styleUtils';

export const MenuNodeItem: React.FC<NodeRendererProps<MenuNode>> = ({ node, style, dragHandle }) => {
  const { data } = node;
  const context = useContext(TreeActionsContext);
  const { t } = useTranslation();
  const navigation = useNavigate();
  const { loadedKnowledgeAreas } = useAppSelector((state) => state.knowledgeArea);
  const { user } = useAppSelector((state) => state.user);
  const isAdmin = user?.role === 'ADMIN';
  const isEmptyFolder = data.isFolder && data.children.length === 0;
  const theme = useAppSelector((state) => state.theme.theme);

  const {
    openEditFolderModal,
    openModalCreateNewDataRoom,
    openModalEditKnowledgeAreaModal,
    onDeleteNestedMenuItem,
    defaultSelectedKey,
    isInitialLoad,
  } = context;

  if (isInitialLoad && data.isFolder && data.children.some((child) => child.id === defaultSelectedKey)) {
    node.open();
  }

  const openInformModal = () => {
    Modal.warning({
      title: t('common.info'),
      content: t('deleteMenuFolderInformation'),
      okText: t('ok'),
    });
  };

  const FolderActionMenu = (
    <Menu>
      <Menu.Item icon={<IconPencil size={14} />} onClick={() => openEditFolderModal(data)}>
        {t('edit')}
      </Menu.Item>
      <Menu.Item icon={<IconPlus size={14} />} onClick={() => openModalCreateNewDataRoom(data.id)}>
        {t('newKnowledgeArea')}
      </Menu.Item>
      {isAdmin && (
        <Menu.Item
          icon={<IconTrash size={14} />}
          danger
          onClick={() => (isEmptyFolder ? onDeleteNestedMenuItem(data.id) : openInformModal())}
        >
          {t('delete')}
        </Menu.Item>
      )}
    </Menu>
  );

  const KnowledgeAreaActionMenu = (
    <Menu>
      <Menu.Item
        icon={<IconPencil size={14} />}
        onClick={() =>
          openModalEditKnowledgeAreaModal(loadedKnowledgeAreas.find((item) => item._id === data.id) ?? null)
        }
      >
        {t('edit')}
      </Menu.Item>
      <Menu.Item
        icon={<IconBrandDatabricks size={14} />}
        onClick={() =>
          navigation('/zen/dataroom-selected', { state: loadedKnowledgeAreas.find((item) => item._id === data.id) })
        }
      >
        {t('goToDataroom')}
      </Menu.Item>
    </Menu>
  );

  if (!context) {
    return null;
  }

  return (
    <MenuItem
      style={style}
      ref={dragHandle}
      key={data.id}
      selected={
        defaultSelectedKey === data.id ||
        (data.isFolder && !node.isOpen && data.children.some((item) => item.id == defaultSelectedKey))
      }
      isOuterChild={!data.isFolder && node.level === 0}
      isFocusedFolder={data.isFolder && node.willReceiveDrop}
    >
      <NodeContent onClick={() => node.isInternal && node.toggle()}>
        <Row justify={'space-between'} align={'middle'} style={{ paddingRight: 8 }}>
          <Row style={{ paddingLeft: 4, flexGrow: 2 }} align={'middle'}>
            {data.isFolder &&
              (node.isOpen ? (
                <ChevronIconWrapper
                  className="ChevronIconWrapper"
                  style={{
                    backgroundColor: theme === 'dark' ? 'var(--actions-background-base-color)' : 'rgba(0, 0, 0, 0.07)',
                  }}
                >
                  <IconChevronDown style={{ verticalAlign: 'middle' }} size={18} />
                </ChevronIconWrapper>
              ) : (
                <ChevronIconWrapper
                  className="ChevronIconWrapper"
                  style={{
                    backgroundColor: theme === 'dark' ? 'var(--actions-background-base-color)' : 'rgba(0, 0, 0, 0.07)',
                  }}
                >
                  <IconChevronRight style={{ verticalAlign: 'middle' }} size={18} />
                </ChevronIconWrapper>
              ))}
            {data.isFolder && (
              <FolderMark
                className="FolderMark"
                color={theme === 'dark' ? darkenHexColor(data.hexColor, 50) : data.hexColor}
              >
                {data.name.slice(0, 1).toUpperCase()}
              </FolderMark>
            )}
            {data.isFolder ? (
              <NormalText size="s">
                <TextLimitContainer className="TextLimitContainer">{data.name}</TextLimitContainer>
              </NormalText>
            ) : (
              <Link to={`/knowledge/${data.id}`} style={{ display: 'inline-block', flexGrow: 2 }}>
                <TextLimitContainer className="TextLimitContainer">{data.name}</TextLimitContainer>
              </Link>
            )}
          </Row>
          <MenuItemActionsContainer
            className="MenuItemActionsContainer"
            onClick={(event) => {
              event.stopPropagation();
            }}
          >
            <Dropdown
              className="MenuItemActionsContainer"
              overlay={data.isFolder ? FolderActionMenu : KnowledgeAreaActionMenu}
            >
              <IconDots size={16} style={{ cursor: 'pointer' }} />
            </Dropdown>
          </MenuItemActionsContainer>
        </Row>
      </NodeContent>
    </MenuItem>
  );
};
