import React, { useRef, useState } from 'react';
import { NormalText } from '../common/BaseTexts/BaseTexts';
import { useTranslation } from 'react-i18next';
import { useErrorHandling } from '@app/hooks/useErrorHandling';
import { Button, Col, Modal, Row, Space, Switch, Select, message, Divider, Tooltip } from 'antd';
import {
  EditorActionsContainer,
  GeneratedContentContainer,
  MainContainer,
  ReportsContainer,
  ReportsContentContainer,
  TextEditorContainer,
} from './LawDetailsPage.styles';
import { useHandleLawChangeReports } from '@app/hooks/useHandleLawChangeReports';
import { ILawChangeReport, ILawEntry, LawReportState } from '@app/types/lawTypes';

import { CKEditor } from '@ckeditor/ckeditor5-react';
import {
  BalloonEditor,
  Bold,
  Essentials,
  Italic,
  Paragraph,
  List,
  Markdown,
  EventInfo,
  Highlight,
  BlockToolbar,
  Indent,
  Heading,
  Table,
  Link,
  Font,
} from 'ckeditor5';
import 'ckeditor5/ckeditor5.css';
import '../gpttemplates/ckeditor-custom.css';
import { ExclamationCircleOutlined, InfoCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import ModalCreateLawReport from './ModalCreateLawReport';
import LawReportItem from './LawReportItem';
import moment from 'moment';
import { createLawChangeSummaryReport } from '@app/api/lawChangeRport.api';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import {
  IconArrowBackUp,
  IconCheck,
  IconCircleDashed,
  IconEyeCheck,
  IconInfoCircle,
  IconSparkles,
  IconX,
} from '@tabler/icons-react';
import { useAppDispatch, useAppSelector } from '@app/hooks/reduxHooks';
import TextArea from 'antd/lib/input/TextArea';
import {
  setAdditionalPrompt,
  setExamplesForOutputFormat,
  setOptionAddCustomPrompt,
  setOptionConsiderDiffSummary,
  setOptionExamplesForOutputFormat,
  setOptionOptionUseAiForReport,
} from '@app/store/slices/lawReportsSlice';
import { LawRadarDefaultDateFormat } from '@app/utils/constants';

type LawReportsOverviewProps = { lawEntry: ILawEntry | null };

export const LawReportsOverview: React.FC<LawReportsOverviewProps> = ({ lawEntry }) => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [createReportModalVisible, setCreateReportModalVisible] = useState(false);
  const [reportChangedByUser, setReportChangedByUser] = useState(false);
  const [selectedReport, setSelectedReport] = useState<ILawChangeReport | null>(null);
  const [currentSelectedState, setCurrentSelectedState] = useState<LawReportState | null>(null);

  const [genereatedText, setGenereatedText] = useState<string>('');

  const { handleApiError } = useErrorHandling();

  const currentUser = useAppSelector((state) => state.user.user);
  const {
    optionConsiderDiffSummary,
    optionUseAiForReport,
    optionAddCustomPrompt,
    optionExamplesForOutputFormat,
    additionalPrompt,
    examplesForOutputFormat,
  } = useAppSelector((state) => state.lawReports);

  const dispatch = useAppDispatch();

  const ckEditor = useRef<BalloonEditor | null>(null);
  const reportSelectionChanging = useRef<boolean>(false);

  const {
    lawChangeReports,
    lawChangeReportLoading,
    onDeleteLawChangeReport,
    onReportCreated,
    onReportChanged,
    onUpdateLawChangeReport,
  } = useHandleLawChangeReports(lawEntry?._id ?? '');

  const onAddReport = async () => {
    setModalVisible(true);
  };

  const onSelectReport = async (id: string) => {
    const onSelect = () => {
      reportSelectionChanging.current = true;
      const foundReport = lawChangeReports.find((item) => item._id === id) || null;
      setSelectedReport(foundReport);
      if (foundReport) {
        setCurrentSelectedState(foundReport.state || 'INITIAL');
        setGenereatedText(foundReport.content ?? '');
        setReportChangedByUser(false);
      }
      setTimeout(() => {
        reportSelectionChanging.current = false;
      }, 200);
    };

    if (reportChangedByUser) {
      Modal.confirm({
        title: t('lawRadar.unsavedChanges'),
        icon: <ExclamationCircleOutlined />,
        content: t('lawRadar.areYouSureDiscardChanges'),
        okText: t('yes'),
        okType: 'danger',
        cancelText: t('no'),
        onOk() {
          onSelect();
        },
        onCancel() {
          console.log(t('lawRadar.cancel'));
        },
      });
    } else {
      onSelect();
    }
  };

  const onDeleteReport = async (id: string) => {
    Modal.confirm({
      title: t('lawRadar.deleteReport'),
      icon: <ExclamationCircleOutlined />,
      content: t('lawRadar.areYouSureDeleteReport'),
      okText: t('yes'),
      okType: 'danger',
      cancelText: t('no'),
      onOk() {
        onDeleteLawChangeReport(id);
      },
      onCancel() {
        console.log(t('lawRadar.cancel'));
      },
    });
  };

  const onStartCreateReport = async () => {
    if (!loading && selectedReport?._id) {
      try {
        setCreateReportModalVisible(false);
        setLoading(true);
        const report = await createLawChangeSummaryReport(selectedReport?._id, {
          useAiForReport: optionUseAiForReport || false,
          considerDiffSummary: optionConsiderDiffSummary,
          additionalPrompt: optionAddCustomPrompt ? additionalPrompt : undefined,
          examplesForOutputFormat: optionExamplesForOutputFormat ? examplesForOutputFormat : undefined,
        });
        setGenereatedText(report);

        const updatedReport = {
          ...selectedReport,
          content: report,
          editedAt: moment().unix(),
          state: 'MUST_BE_REVIEWED' as LawReportState,
        };

        onReportChanged(selectedReport?._id, updatedReport);
        setSelectedReport(updatedReport);
        setCurrentSelectedState('MUST_BE_REVIEWED');
        setReportChangedByUser(false);
      } catch (error) {
        handleApiError(error);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleEditorChange = (event: EventInfo, editor: BalloonEditor) => {
    if (!loading) {
      const data = editor.getData();
      setGenereatedText(data);
      if (!reportSelectionChanging.current) {
        setReportChangedByUser(true);
      }
    }
  };

  const handleSaveEditorChanges = async () => {
    if (!loading && selectedReport?._id) {
      try {
        const editedAt = moment().unix();
        await onUpdateLawChangeReport(selectedReport?._id, {
          content: genereatedText,
          editedAt,
          editedBy: currentUser?._id ?? '',
        });
        const updatedReport = { ...selectedReport, editedAt };
        setSelectedReport(updatedReport);
        setReportChangedByUser(false);
      } catch (error) {
        handleApiError(error);
      }
    }
  };

  const handleResetEditorChanges = async () => {
    if (!loading && selectedReport?._id) {
      try {
        const oldContent = selectedReport.content ?? '';
        setGenereatedText(oldContent);
        await onUpdateLawChangeReport(selectedReport?._id, { content: oldContent });
        setReportChangedByUser(false);
      } catch (error) {
        handleApiError(error);
      }
    }
  };

  const handleStateChange = async (value: LawReportState, showConfirmModal = true) => {
    if (selectedReport?._id) {
      const updateState = async () => {
        const updatedReport = { ...selectedReport, state: value };
        setSelectedReport(updatedReport);
        setCurrentSelectedState(value);
        await onUpdateLawChangeReport(selectedReport._id ?? '', { state: value });
      };

      if (showConfirmModal && value === 'APPROVED') {
        Modal.confirm({
          title: (
            <Space>
              <IconCheck style={{ color: 'var(--success-color)' }} size={24} />
              <NormalText size="l">{t('lawRadar.approveReport')}</NormalText>
            </Space>
          ),
          icon: null,
          content: t('lawRadar.areYouSureApproveReport'),
          okText: t('yes'),
          cancelText: t('no'),
          onOk() {
            updateState();
          },
          onCancel() {
            console.log(t('lawRadar.cancel'));
          },
        });
      } else {
        updateState();
      }
    }
  };

  const handleCreateReport = async (createdReport: ILawChangeReport) => {
    reportSelectionChanging.current = true;
    onReportCreated(createdReport);
    setSelectedReport(createdReport);
    setCurrentSelectedState(createdReport.state || 'INITIAL');
    setGenereatedText(createdReport.content ?? '');
    setReportChangedByUser(false);
    message.success('Report wurde erstellt');
    setTimeout(() => {
      reportSelectionChanging.current = false;
    }, 200);
  };

  const reportTextExists = selectedReport?.content?.length ? selectedReport?.content?.length > 0 : false;

  const noReportsAvailable = lawChangeReports.length === 0;

  return (
    <MainContainer>
      <Row>
        <Col md={6} xl={4}>
          <ReportsContainer>
            <Row justify={'space-between'} style={{ paddingLeft: '4px', marginTop: '4px' }}>
              <NormalText horizontalPadding verticalPadding semiBold colorType="primary">
                {t('lawRadar.availableReports')}
              </NormalText>
              <Button type="link" onClick={() => onAddReport()}>
                <PlusCircleOutlined size={20} />
              </Button>
            </Row>
            {lawChangeReportLoading && (
              <SkeletonTheme
                baseColor="var(--skeleton-background-color)"
                highlightColor="var(--secondary-background-selected-color)"
              >
                <Skeleton count={3} style={{ height: 22, width: '100%', padding: '0 8px' }} />
              </SkeletonTheme>
            )}
            {!lawChangeReportLoading && lawChangeReports.length === 0 && (
              <NormalText
                horizontalPadding
                verticalPadding
                light
                centered
                colorType="light"
                style={{ marginTop: '20px' }}
              >
                {t('lawRadar.noReports')}
              </NormalText>
            )}
            {lawChangeReports.map((report, index) => (
              <LawReportItem
                key={`report_item_${index}`}
                {...report}
                index={index}
                isSelected={selectedReport?._id === report._id}
                onDeleteReport={(id: string) => onDeleteReport(id)}
                onSelect={(id: string) => onSelectReport(id)}
              />
            ))}
          </ReportsContainer>
        </Col>
        <Col md={18} xl={20}>
          <ReportsContentContainer>
            {selectedReport ? (
              <GeneratedContentContainer>
                <Row gutter={16}>
                  <Col xs={24} md={18}>
                    <Row justify={'space-between'} style={{ padding: '0 8px' }}>
                      <NormalText size="m" bottomMargin="s" colorType="primary" semiBold>
                        Report{' '}
                        {`${moment.unix(selectedReport.startDate).format(LawRadarDefaultDateFormat)} - ${moment.unix(selectedReport.endDate).format(LawRadarDefaultDateFormat)}`}
                      </NormalText>
                      <NormalText size="s" bottomMargin="s" colorType="light" light>
                        {`${selectedReport.editedAt ? t('lawRadar.editedOn', { date: moment.unix(selectedReport.editedAt).format('D MMMM, YYYY HH:mm') }) : t('lawRadar.createdOn', { date: moment.unix(selectedReport.createdAt).format('D MMMM, YYYY HH:mm') })}`}
                      </NormalText>
                    </Row>
                    <TextEditorContainer>
                      <CKEditor
                        editor={BalloonEditor}
                        disabled={selectedReport.state === 'APPROVED'}
                        config={{
                          plugins: [
                            Essentials,
                            Indent,
                            Bold,
                            Heading,
                            BlockToolbar,
                            Italic,
                            Paragraph,
                            List,
                            Markdown,
                            Highlight,
                            Table,
                            Link,
                            Font,
                          ],
                          toolbar: [
                            'undo',
                            'redo',
                            '|',
                            'heading',
                            'bold',
                            'italic',
                            '|',
                            'numberedList',
                            'bulletedList',
                          ],
                          language: 'de',
                          placeholder: t('lawRadar.aiEditorEmptyPlaceholder'),
                          blockToolbar: ['undo', 'redo', '|', 'heading', 'bulletedList', 'numberedList'],
                        }}
                        data={genereatedText}
                        onReady={(editor) => {
                          ckEditor.current = editor;
                        }}
                        onChange={handleEditorChange}
                      />
                    </TextEditorContainer>
                  </Col>
                  <Col xs={24} md={6}>
                    <EditorActionsContainer>
                      <Button
                        style={{ width: 250, marginTop: '2.5rem', textAlign: 'left' }}
                        onClick={() => setCreateReportModalVisible(true)}
                        loading={loading}
                      >
                        <Space align="center">
                          {!loading && <IconSparkles stroke={1} />}
                          {genereatedText.length
                            ? t('lawRadar.recreateReportWithAI')
                            : t('lawRadar.createReportWithAI')}
                        </Space>
                      </Button>

                      <Select
                        defaultValue={'INITIAL'}
                        value={currentSelectedState}
                        style={{ width: 250, marginTop: '1rem' }}
                        onChange={(value) => handleStateChange(value as LawReportState)}
                      >
                        <Select.Option value="INITIAL">
                          <Space>
                            <IconCircleDashed style={{ marginTop: 10 }} size={18} />
                            <NormalText>{t('lawRadar.notSet')}</NormalText>
                          </Space>
                        </Select.Option>
                        <Select.Option value="APPROVED">
                          <Space>
                            <IconCheck style={{ color: 'var(--success-color)', marginTop: 10 }} size={18} />
                            <NormalText style={{ color: 'var(--success-color)' }}>{t('lawRadar.approved')}</NormalText>
                          </Space>
                        </Select.Option>
                        <Select.Option value="DECLINED">
                          <Space>
                            <IconX style={{ color: 'var(--error-color)', marginTop: 10 }} size={18} />
                            <NormalText style={{ color: 'var(--error-color)' }}>{t('lawRadar.declined')}</NormalText>
                          </Space>
                        </Select.Option>
                        <Select.Option value="MUST_BE_REVIEWED" style={{ color: 'var(--warning-color)' }}>
                          <Space>
                            <IconEyeCheck style={{ color: 'var(--warning-color)', marginTop: 10 }} size={18} />
                            <NormalText style={{ color: 'var(--warning-color)' }}>
                              {t('lawRadar.mustBeReviewed')}
                            </NormalText>
                          </Space>
                        </Select.Option>
                      </Select>

                      {reportChangedByUser && !loading && (
                        <div
                          style={{
                            marginTop: '2rem',
                            borderTop: '1px solid var(--border-base-color)',
                            paddingTop: '1rem',
                            maxWidth: 230,
                          }}
                        >
                          <Button type="link" onClick={() => handleSaveEditorChanges()}>
                            <Space>
                              <IconCheck size={20} />
                              {t('lawRadar.saveChanges')}
                            </Space>
                          </Button>
                          <Button type="link" onClick={() => handleResetEditorChanges()}>
                            <Space>
                              <IconArrowBackUp size={20} />
                              {t('lawRadar.discardChanges')}
                            </Space>
                          </Button>
                        </div>
                      )}
                    </EditorActionsContainer>
                  </Col>
                </Row>
              </GeneratedContentContainer>
            ) : (
              <div style={{ paddingTop: '10%' }}>
                {noReportsAvailable ? (
                  <div style={{ textAlign: 'center' }}>
                    <NormalText verticalPadding centered size="l">
                      {t('lawRadar.noReportAvailable')}
                    </NormalText>
                    <Space>
                      <NormalText verticalPadding centered colorType="light">
                        {t('lawRadar.noReportAvailableDescription')}
                      </NormalText>
                      <Button type="link" onClick={() => onAddReport()}>
                        <PlusCircleOutlined size={30} />
                        {t('common.create')}
                      </Button>
                    </Space>
                  </div>
                ) : (
                  <NormalText verticalPadding centered>
                    {t('lawRadar.noReportSelected')}
                  </NormalText>
                )}
              </div>
            )}
          </ReportsContentContainer>
        </Col>
      </Row>
      <ModalCreateLawReport
        lawEntry={lawEntry}
        visible={modalVisible}
        onClose={() => setModalVisible(false)}
        onUpdateReport={() => setModalVisible(false)}
        onReportCreated={(report) => handleCreateReport(report)}
      />
      <Modal
        title={
          <Space>
            <IconSparkles stroke={1} />
            <NormalText size="l">{t('lawRadar.createReportWithAI')}</NormalText>
          </Space>
        }
        open={createReportModalVisible}
        onCancel={() => setCreateReportModalVisible(false)}
        onOk={onStartCreateReport}
        okText={t('generateText')}
      >
        {reportTextExists && (
          <Space style={{ margin: '0 0 1rem 0' }}>
            <InfoCircleOutlined />
            <NormalText light>{t('lawRadar.exisitingReportWillBeReplaced')}</NormalText>
          </Space>
        )}
        <Row justify={'space-between'}>
          <Space>
            <NormalText>{t('lawRadar.optionUseAiForReport')}</NormalText>
            <Tooltip title={t('lawRadar.optionUseAiForReportToolTip')}>
              <IconInfoCircle size={'1rem'} color="gray" />
            </Tooltip>
          </Space>
          <Switch
            checked={optionUseAiForReport}
            onChange={() => {
              dispatch(setOptionOptionUseAiForReport(optionUseAiForReport ? false : true));
            }}
          />
        </Row>
        {optionUseAiForReport && (
          <>
            <Divider />
            <NormalText bold>{t('lawRadar.aiOptions')}</NormalText>
            <Row justify={'space-between'} style={{ marginTop: 8, marginBottom: 4 }}>
              <NormalText>{t('lawRadar.optionConsiderDiffSummary')}</NormalText>
              <Switch
                checked={optionConsiderDiffSummary}
                onChange={() => {
                  dispatch(setOptionConsiderDiffSummary(optionConsiderDiffSummary ? false : true));
                }}
              />
            </Row>
            <Row justify={'space-between'} style={{ marginTop: 8, marginBottom: 4 }}>
              <NormalText>{t('lawRadar.optionAddCustomPrompt')}</NormalText>
              <Switch
                checked={optionAddCustomPrompt}
                onChange={() => {
                  const isActive = !optionAddCustomPrompt;
                  dispatch(setOptionAddCustomPrompt(isActive));
                }}
              />
            </Row>
            {optionAddCustomPrompt && (
              <TextArea
                autoSize={{ minRows: 3, maxRows: 6 }}
                placeholder={t('lawRadar.customPromptPlaceholder')}
                allowClear
                value={additionalPrompt}
                onChange={(e) => {
                  dispatch(setAdditionalPrompt(e.target.value));
                }}
              />
            )}
            <Row justify={'space-between'} style={{ marginTop: 8, marginBottom: 4 }}>
              <NormalText>{t('lawRadar.optionExamplesForOutputFormat')}</NormalText>
              <Switch
                checked={optionExamplesForOutputFormat}
                onChange={() => {
                  const isActive = !optionExamplesForOutputFormat;
                  dispatch(setOptionExamplesForOutputFormat(isActive));
                }}
              />
            </Row>
            {optionExamplesForOutputFormat && (
              <TextArea
                autoSize={{ minRows: 6, maxRows: 10 }}
                placeholder={t('lawRadar.examplesForOutputFormatPlaceholder')}
                allowClear
                value={examplesForOutputFormat}
                onChange={(e) => {
                  dispatch(setExamplesForOutputFormat(e.target.value));
                }}
              />
            )}
          </>
        )}
      </Modal>
    </MainContainer>
  );
};
