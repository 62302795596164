import styled, { css } from 'styled-components';
import { FONT_SIZE } from '@app/styles/themes/constants';
import { darkenHexColor } from '@app/styles/styleUtils';

type MenuItemProps = {
  selected: boolean;
  isOuterChild: boolean;
  isFocusedFolder: boolean;
};

export const MenuItem = styled.div<MenuItemProps>`
  position: relative;
  display: flex;
  align-items: center;
  ${({ isOuterChild }) =>
    isOuterChild &&
    css`
      padding-left: 12px !important;
    `}
  font-size: ${FONT_SIZE.xs};
  height: 2.25rem;
  color: var(--text-sider-secondary-color);
  cursor: pointer;
  ${({ selected }) =>
    selected &&
    css`
      background-color: rgba(2, 121, 208, 0.1);
      transition: background-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    `}
  ${({ isFocusedFolder }) =>
    isFocusedFolder &&
    css`
      background-color: rgba(2, 121, 208, 0.1);
      box-shadow: var(--box-shadow);
      transition: background-color 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
      border: 1px solid var(--primary-color);
      border-radius: 6px;
    `}

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    border-right: 3px solid var(--primary-color);
    transform: scaleY(0.0001);
    opacity: 0;
    transition:
      transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1),
      opacity 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
    content: '';
    ${({ selected }) =>
      selected &&
      css`
        transform: scaleY(1);
        opacity: 1;
        transition:
          transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1),
          opacity 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
      `}
  }

  & .TextLimitContainer {
    ${({ selected }) =>
      selected &&
      css`
        color: var(--primary-color);
        transition: color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      `}
  }

  & a {
    color: var(--text-sider-secondary-color);
  }

  &:hover .MenuItemActionsContainer {
    display: flex;
  }

  &:hover {
    background-color: rgba(2, 121, 208, 0.04);
    transition: background-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);

    & .TextLimitContainer {
      color: var(--primary-color);
      transition: color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    }

    & .FolderMark {
      display: none;
    }

    & .ChevronIconWrapper {
      display: flex;
    }
  }
`;

export const NodeContent = styled.div`
  width: 100%;
`;

export const FolderMark = styled.p`
  width: 22px;
  height: 22px;
  margin: 0 8px;
  border-radius: 4px;
  color: ${({ color }) => (color ? darkenHexColor(color, 70) : '#fff')};
  background-color: ${({ color }) => color || '#f6b73c'};
  text-align: center;
  line-height: 22px;
  font-size: ${FONT_SIZE.xxs};
  font-weight: bold;
  cursor: pointer;
`;

export const ChevronIconWrapper = styled.div`
  display: none;
  align-items: center;
  justify-content: center;
  width: 22px;
  height: 22px;
  margin: 0 8px;
  border-radius: 4px;
`;

export const MenuItemActionsContainer = styled.div`
  display: none;
  gap: 4px;
  align-items: center;
  justify-content: flex-end;
`;

export const TextLimitContainer = styled.span`
  display: inline-block;
  width: 160px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  vertical-align: middle;
  color: var(--text-sider-secondary-color);
`;
