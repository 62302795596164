import styled from 'styled-components';
import { Typography } from 'antd';
import { media } from '@app/styles/themes/constants';

interface TextProps {
  size?: 'xs' | 's' | 'm' | 'l' | 'xl' | 'xxl' | 'xxxl';
  colorType?: 'primary' | 'light' | 'gray' | 'warning';
  light?: boolean;
  bold?: boolean;
  semiBold?: boolean;
  centered?: boolean;
  verticalPadding?: boolean;
  horizontalPadding?: boolean;
  bottomMargin?: 'xs' | 's' | 'm' | 'l';
  topMargin?: 'xs' | 's' | 'm' | 'l';
}

const getColor = (type?: 'primary' | 'light' | 'gray' | 'warning') => {
  if (type === 'primary') return 'var(--primary-color)';
  else if (type === 'light') return 'var(--text-light-color)';
  else if (type === 'warning') return 'var(--text-warning-color)';
  return 'var(--text-main-color)';
};

const getMargin = (size?: 'xs' | 's' | 'm' | 'l') => {
  if (size === 'xs') return '0.25rem';
  else if (size === 's') return '0.5rem';
  else if (size === 'm') return '1rem';
  else if (size === 'l') return '2rem';
  return 0;
};

export const getFontSize = (mobile: boolean, size?: 'xs' | 's' | 'm' | 'l' | 'xl' | 'xxl' | 'xxxl') => {
  if (size === 'xs') return mobile ? '0.65rem' : '0.75rem';
  else if (size === 's') return mobile ? '0.75rem' : '0.875rem';
  else if (size === 'm') return '1rem';
  else if (size === 'l') return '1.125rem';
  else if (size === 'xl') return '1.25rem';
  else if (size === 'xxl') return '1.5rem';
  else if (size === 'xxxl') return '1.75rem';
  return '1rem';
};

const getLineHeight = (size?: 'xs' | 's' | 'm' | 'l' | 'xl' | 'xxl' | 'xxxl') => {
  if (size === 'xs') return '1.5rem';
  else if (size === 's') return '1.5rem';
  else if (size === 'm') return '1.75rem';
  else if (size === 'l') return '1.75rem';
  else if (size === 'xl') return '1.75rem';
  else if (size === 'xxl') return '1.875rem';
  else if (size === 'xxxl') return '2rem';
  return '1.75rem';
};

export const NormalText = styled(Typography.Text)<TextProps>`
  font-weight: ${(props) => (props.bold ? 700 : props.semiBold ? 600 : props.light ? 400 : 500)};
  font-size: ${(props) => getFontSize(true, props.size)};
  display: block;

  padding-top: ${(props) => (props.verticalPadding ? '0.5rem' : undefined)};
  padding-bottom: ${(props) => (props.verticalPadding ? '0.5rem' : undefined)};
  padding-left: ${(props) => (props.horizontalPadding ? '0.5rem' : undefined)};
  padding-right: ${(props) => (props.horizontalPadding ? '0.5rem' : undefined)};
  margin-bottom: ${(props) => getMargin(props.bottomMargin)};
  margin-top: ${(props) => getMargin(props.topMargin)};
  line-height: ${(props) => getLineHeight(props.size)};

  color: ${(props) => props.color || getColor(props.colorType)};
  text-align: ${(props) => (props.centered ? 'center' : undefined)};

  @media only screen and ${media.md} {
    font-size: ${(props) => getFontSize(false, props.size)};
  }
`;
