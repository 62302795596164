import React, { useEffect, useMemo, useState } from 'react';
import { Modal, message, DatePicker, Space } from 'antd';
import { ILawChangeReference, ILawChangeReport, ILawEntry } from '@app/types/lawTypes';
import { useAppDispatch, useAppSelector } from '@app/hooks/reduxHooks';
import { useErrorHandling } from '@app/hooks/useErrorHandling';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { createLawChangeReport } from '@app/api/lawChangeRport.api';
import { RangePickerProps } from 'antd/lib/date-picker';
import { RangeValue } from '../gptchatbox/SidebarFilter/DocumentFilter';
import { NormalText } from '../common/BaseTexts/BaseTexts';
import { setLastSelectedEndDate, setLastSelectedStartDate } from '@app/store/slices/lawReportsSlice';
import { LoadingOutlined } from '@ant-design/icons';
import { IconCheck, IconInfoSquareRounded } from '@tabler/icons-react';
import { LawRadarDefaultDateFormat } from '@app/utils/constants';

interface ModalCreateLawEntryProps {
  visible: boolean;
  lawEntry: ILawEntry | null;
  onClose: () => void;
  onReportCreated: (createdReport: ILawChangeReport) => void;
  onUpdateReport: (lawEntryId: string, updatedReport: ILawChangeReport) => void;
}

interface ILawChangeReferenceWithState extends ILawChangeReference {
  countInitialState: number;
  countApprovedState: number;
  countIgnoredState: number;
  countAllChanges: number;
}

const ModalCreateLawReport: React.FC<ModalCreateLawEntryProps> = ({
  lawEntry,
  visible,
  onClose,
  // onUpdateReport,
  onReportCreated,
}) => {
  const [confirmLoading, setConfirmLoading] = useState(false);

  const [changesLoading, setChangesLoading] = useState(true);

  const [changesConsideredByDate, setChangesConsideredByDate] = useState<ILawChangeReferenceWithState[]>([]);

  const { lastSelectedStartDate, lastSelectedEndDate } = useAppSelector((state) => state.lawReports);
  const companyState = useAppSelector((state) => state.company);
  const user = useAppSelector((state) => state.user.user);

  const dispatch = useAppDispatch();

  const { handleApiError } = useErrorHandling();
  const { t } = useTranslation();

  const showChangesIncluded = useMemo(
    () => Boolean(lastSelectedStartDate && lastSelectedEndDate && lastSelectedStartDate > 0 && lastSelectedEndDate > 0),
    [lastSelectedStartDate, lastSelectedEndDate],
  );

  useEffect(() => {
    setChangesConsideredByDate([]);
    if (lawEntry) {
      setTimeout(() => {
        setChangesLoading(false);
      }, 2500);
    }
  }, [lawEntry]);

  useEffect(() => {
    if (lawEntry && lastSelectedStartDate && lastSelectedEndDate) {
      const changesConsidered: ILawChangeReferenceWithState[] = findRelevantChanges(
        lawEntry,
        moment.unix(lastSelectedStartDate),
        moment.unix(lastSelectedEndDate),
      );
      setChangesConsideredByDate(changesConsidered);
    }
  }, [lastSelectedStartDate, lastSelectedEndDate, lawEntry]);

  const findRelevantChanges = (lawEntry: ILawEntry, startDate: moment.Moment | null, endDate: moment.Moment | null) => {
    const changesConsidered: ILawChangeReferenceWithState[] = [];
    lawEntry.changes.forEach((change) => {
      const changeDate = moment(change.comingIntoForceDate, 'D.M.YYYY');
      if (changeDate.isBetween(startDate, endDate)) {
        let countInitialState = 0;
        let countApprovedState = 0;
        let countIgnoredState = 0;

        change.changeDetails?.forEach((detail) => {
          if (!detail.state || detail.state === 'INITIAL') {
            countInitialState += 1;
          } else if (detail.state === 'APPROVED') {
            countApprovedState += 1;
          } else if (detail.state === 'IGNORE') {
            countIgnoredState += 1;
          }
        });
        changesConsidered.push({
          changeId: change._id ?? '',
          changeTitle: change.changeTitle,
          comingIntoForceDate: change.comingIntoForceDate,
          countInitialState,
          countApprovedState,
          countIgnoredState,
          countAllChanges: change.changeDetails?.length || 0,
        });
      }
    });
    return changesConsidered;
  };

  const handleCreate = async () => {
    try {
      if (!lawEntry?._id) {
        message.error('Law-entry id must be set');
        return;
      }
      if (changesConsideredByDate.length === 0) {
        message.error('Es kann Report erstellt werden, da zu Ihrer Auswahl keine Änderungen gefunden wurden');
        return;
      }

      setConfirmLoading(true);

      const createdReport: ILawChangeReport = {
        changesConsidered: changesConsideredByDate,
        lawId: lawEntry?._id,
        startDate: lastSelectedStartDate || 0,
        endDate: lastSelectedEndDate || 0,
        createdAt: moment().unix(),
        companyId: companyState._id ?? '',
        createdBy: user?._id ?? '',
        state: 'INITIAL',
      };

      const id = await createLawChangeReport(createdReport);
      createdReport._id = id;
      onReportCreated(createdReport);
      onClose();
    } catch (error) {
      console.error('Error creating law entry:', error);
      handleApiError(error);
    } finally {
      setConfirmLoading(false);
    }
  };

  const handleDateChange: RangePickerProps['onChange'] = (dates: RangeValue) => {
    const datesToSave = dates ? dates : [null, null];
    const [startDate, endDate] = datesToSave;
    dispatch(setLastSelectedStartDate(startDate ? startDate.unix() : undefined));
    dispatch(setLastSelectedEndDate(endDate ? endDate.unix() : undefined));
  };

  return (
    <Modal
      title={t('lawRadar.createLawReport')}
      open={visible}
      onCancel={onClose}
      onOk={handleCreate}
      confirmLoading={confirmLoading}
    >
      <DatePicker.RangePicker
        onChange={handleDateChange}
        format={LawRadarDefaultDateFormat}
        value={
          lastSelectedStartDate && lastSelectedEndDate
            ? [moment.unix(lastSelectedStartDate), moment.unix(lastSelectedEndDate)]
            : undefined
        }
      />
      {showChangesIncluded && !changesLoading && (
        <div style={{ margin: '1rem 0' }}>
          {changesConsideredByDate.length ? (
            <>
              <NormalText verticalPadding horizontalPadding colorType="primary" semiBold>
                {t('lawRadar.relevantChangesForReport', { number: changesConsideredByDate.length })}
              </NormalText>
              {changesConsideredByDate.map((change, index) => (
                <Space key={`change_title_item_${index}`}>
                  <NormalText horizontalPadding>
                    {change.changeTitle} - {change.comingIntoForceDate}
                  </NormalText>
                  {change.countAllChanges > 0 ? (
                    <>
                      {change.countApprovedState === change.countAllChanges - change.countIgnoredState ? (
                        <Space size={'small'}>
                          <IconCheck
                            style={{ color: 'var(--success-color)', marginLeft: 4, marginTop: 4, marginRight: -2 }}
                            size={20}
                          />
                          <NormalText colorType="light" light size="s">
                            {t('lawRadar.allChangeDetailsApproved')}
                          </NormalText>
                        </Space>
                      ) : (
                        <Space size={'small'}>
                          <IconInfoSquareRounded
                            style={{ color: 'var(--text-light-color)', marginLeft: 4, marginTop: 6, marginRight: -2 }}
                            size={18}
                          />
                          <NormalText colorType="light" light size="s">
                            {t('lawRadar.someChangeDetailsNotApproved', {
                              number: `${change.countApprovedState}/${change.countAllChanges}`,
                            })}
                          </NormalText>
                        </Space>
                      )}
                    </>
                  ) : (
                    <NormalText horizontalPadding colorType="light" light size="s">
                      {t('lawRadar.noChangeDetails')}
                    </NormalText>
                  )}
                </Space>
              ))}
            </>
          ) : (
            <>
              <NormalText
                verticalPadding
                horizontalPadding
                colorType="light"
                semiBold
              >{`Für Ihre Auswahl wurden keine Änderungen gefunden`}</NormalText>
            </>
          )}
        </div>
      )}
      {changesLoading && (
        <div style={{ margin: '2rem 0.5rem' }}>
          <LoadingOutlined />
        </div>
      )}
    </Modal>
  );
};

export default ModalCreateLawReport;
