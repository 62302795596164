import React from 'react';
import styled from 'styled-components';

export const SidebarContainer = styled.div`
  padding: 20px;
  min-width: 25%;
  height: 90vh;
  margin: 0em;
  background-color: var(--background-color);
  border-left: 2px solid #7cc5d9;
  overflow: auto;
`;

export const SidebarLeftContainer = styled(SidebarContainer)`
  border-right: 2px solid #7cc5d9;
  border-left: 0px;
  height: 90vh;
`;

export const ContentStyle: React.CSSProperties = {
  backgroundColor: 'var(--background-color)',
  maxHeight: '80vh',
  overflowY: 'auto',
  padding: '12px 0px',
};
