import React, { useEffect, useRef, useState } from 'react';
import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { ClassicEditor, Bold, Essentials, Italic, Paragraph, List, Markdown, EventInfo } from 'ckeditor5';
import 'ckeditor5/ckeditor5.css';
import '../gpttemplates/ckeditor-custom.css';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { useResponsive } from '@app/hooks/useResponsive';

interface ModalEditLawChangesSummaryProps {
  visible: boolean;
  changeTitle: string;
  lawSummary: string;
  onClose: () => void;
  onUpdateSummary: (changeTitle: string, summary: string) => void;
}

const ModalEditLawChangesSummary: React.FC<ModalEditLawChangesSummaryProps> = ({
  changeTitle,
  lawSummary,
  visible,
  onClose,
  onUpdateSummary,
}) => {
  const [confirmLoading, setConfirmLoading] = useState(false);

  const [summary, setSummary] = useState(lawSummary);

  const { t } = useTranslation();
  const { desktopOnly } = useResponsive();

  const ckEditor = useRef<ClassicEditor | null>(null);

  useEffect(() => {
    if (lawSummary) {
      setSummary(lawSummary);
    }
  }, [lawSummary]);

  const handleEditorChange = (event: EventInfo, editor: ClassicEditor) => {
    const data = editor.getData();
    setSummary(data);
  };

  const handleSave = async () => {
    setConfirmLoading(true);
    await onUpdateSummary(changeTitle, summary);
    setConfirmLoading(false);
  };

  return (
    <Modal
      title={t('lawRadar.editChangesSummary')}
      open={visible}
      onCancel={onClose}
      onOk={() => handleSave()}
      confirmLoading={confirmLoading}
      width={desktopOnly ? '50%' : undefined}
    >
      <CKEditor
        editor={ClassicEditor}
        config={{
          plugins: [Essentials, Bold, Italic, Paragraph, List, Markdown],
          toolbar: ['undo', 'redo', '|', 'bold', 'italic', '|', 'numberedList', 'bulletedList'],
          language: 'de',
          placeholder: t('lawRadar.placeholderEditChangesSummary'),
          blockToolbar: ['undo', 'redo', '|', 'heading', 'bulletedList', 'numberedList'],
        }}
        data={summary}
        onReady={(editor) => {
          ckEditor.current = editor;
        }}
        onChange={handleEditorChange}
      />
    </Modal>
  );
};

export default ModalEditLawChangesSummary;
