import React from 'react';

import { Button } from 'antd';
import { LeftOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { useCurrentTheme } from '@app/hooks/useCurrentTheme';
import { useAppSelector } from '@app/hooks/reduxHooks';

const BackButton = ({
  marginLeft,
  onGoBack,
  style,
}: {
  marginLeft?: string;
  onGoBack?: () => void;
  style?: React.CSSProperties;
}) => {
  const navigation = useNavigate();
  const { currentTheme } = useCurrentTheme();
  const theme = useAppSelector((state) => state.theme.theme);

  return (
    <Button
      type={'link'}
      onClick={() => (onGoBack ? onGoBack() : navigation(-1))}
      style={{
        ...style,
        backgroundColor: theme === 'dark' ? currentTheme.actionsBackgroundColorBase : currentTheme.primaryLight,
        marginRight: '1rem',
        marginLeft: marginLeft || 0,
      }}
      icon={<LeftOutlined />}
    />
  );
};

export default BackButton;
