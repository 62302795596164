import React, { useMemo } from 'react';
import { Button, Dropdown, Menu, Space } from 'antd';
import { NormalText } from '../common/BaseTexts/BaseTexts';
import { LLM_MODEL_SELECTION } from '@app/utils/constants';
import { useAppDispatch, useAppSelector } from '@app/hooks/reduxHooks';
import { setGptChatBoxLLM } from '@app/store/slices/settingsSlice';

const LLMInlineSwitcher = ({ models }: { models: LLM_MODEL_SELECTION[] }) => {
  const { gptChatBoxLLM } = useAppSelector((state) => state.settings);
  const dispatch = useAppDispatch();
  const theme = useAppSelector((state) => state.theme.theme);

  const selectedLLM = useMemo(() => models.find((model) => model.value === gptChatBoxLLM), [models, gptChatBoxLLM]);

  return (
    <Dropdown
      overlay={
        <Menu>
          {models.map((model) => (
            <Menu.Item
              onClick={() => dispatch(setGptChatBoxLLM({ value: model.value }))}
              key={model.label}
              disabled={model.disabled || false}
            >
              <Space>
                <div
                  style={{
                    padding: 2,
                    borderRadius: 4,
                    backgroundColor: theme === 'dark' ? 'var(--text-main-color)' : '',
                  }}
                >
                  <img src={model.image} alt={model.label} style={{ width: 16 }} />
                </div>
                <NormalText
                  size="xs"
                  bold={selectedLLM?.value === model.value}
                  colorType={selectedLLM?.value === model.value ? 'primary' : model.disabled ? 'light' : 'gray'}
                >
                  {model.label}
                </NormalText>
              </Space>
            </Menu.Item>
          ))}
        </Menu>
      }
    >
      <Button type="text">
        <div style={{ padding: 2, borderRadius: 4, backgroundColor: theme === 'dark' ? 'var(--text-main-color)' : '' }}>
          <img src={selectedLLM ? selectedLLM.image : models[0].image} style={{ width: 14 }} />
        </div>
      </Button>
    </Dropdown>
  );
};

export default LLMInlineSwitcher;
