import React, { useState, useEffect, useRef } from 'react';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { useTranslation } from 'react-i18next';
import { BaseButtonsForm } from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';
import { Radio, Row, message } from 'antd';
import type { RadioChangeEvent } from 'antd';
import { Card } from '@app/components/common/Card/Card';
import { getUsageAnalytics } from '@app/api/usageAnalytics.api';
import { getCompanyTeamMembers } from '@app/api/company.api';
import { UserModel } from '@app/domain/UserModel';
import { NormalText } from '../common/BaseTexts/BaseTexts';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartData,
  ChartOptions,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { InfoBlock, ChartContainer } from './UsageAnalytics.styles';
import moment from 'moment';
import { UsageMode } from '@app/types/usageAnalyticsTypes';
import { getCurrencyPrice } from '@app/utils/utils';
import { LoadingOutlined } from '@ant-design/icons';
import { useCurrentTheme } from '@app/hooks/useCurrentTheme';

type UsageChartData = {
  month: string;
  data: number;
};

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

export const options: ChartOptions<'bar'> = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top',
    },
  },
  scales: {
    x: {
      grid: {
        display: false,
      },
    },
  },
};

export const UsageAnalytics: React.FC = () => {
  const { t } = useTranslation();

  const companyState = useAppSelector((state) => state.company);

  const [usageType, setUsageType] = useState<UsageMode>('TOKENS');
  const [usageChartData, setUsageChartData] = useState<UsageChartData[]>([]);
  const [usersCount, setUsersCount] = useState(0);
  const [overallSum, setOverallSum] = useState(0);
  const [currentYear] = useState(moment().year().toString());

  const [loading, setLoading] = useState(true);
  const companyIdRef = useRef('');

  const { currentTheme } = useCurrentTheme();

  const translateMonths = (usageAnalyticsData: UsageChartData[]) => {
    return usageAnalyticsData?.map((monthData) => ({
      ...monthData,
      month: t(`month.${monthData.month}`),
    }));
  };

  useEffect(() => {
    const readUsageAnalytics = async () => {
      try {
        setLoading(true);
        const usageAnalyticsData = await getUsageAnalytics(companyState._id ?? '', currentYear, usageType);
        const dataWithTranslatedData = usageAnalyticsData ? translateMonths(usageAnalyticsData.usageData) : [];
        setUsageChartData(dataWithTranslatedData);
        setOverallSum(usageAnalyticsData?.overallSum ?? 0);
      } catch (error) {
        message.error(t('common.loadingError'));
      } finally {
        setLoading(false);
      }
    };

    const countTeamMembers = async () => {
      if (companyState._id) {
        const members: UserModel[] = await getCompanyTeamMembers(companyState._id);
        setUsersCount(members.length);
      }
    };

    readUsageAnalytics();

    if (companyState._id !== companyIdRef.current) {
      companyIdRef.current = companyState._id ?? '-';
      countTeamMembers();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyState._id, usageType]);

  const data: ChartData<'bar', { month: string; data: number }[]> = {
    datasets: [
      {
        label: t(usageType === 'COSTS' ? 'usageAnalytics.overallCostsUsage' : 'usageAnalytics.overallTokenUsage'),
        data: usageChartData ?? [],
        parsing: {
          xAxisKey: 'month',
          yAxisKey: 'data',
        },
        backgroundColor: currentTheme.primary,
      },
    ],
  };

  const usageTypeOptions: { label: string; value: UsageMode }[] = [
    { label: t('usageAnalytics.usage'), value: 'TOKENS' },
    { label: t('usageAnalytics.costs'), value: 'COSTS' },
  ];

  const handleUsageType = ({ target: { value } }: RadioChangeEvent): void => {
    setUsageType(value);
  };

  const getMessagesCount = () => {
    const total = overallSum;

    if (usageType === 'COSTS') {
      return getCurrencyPrice(total, 'EUR');
    } else {
      if (total > 1000) {
        const valueToShow = total / 1000;
        return `${valueToShow.toFixed(2)} K`;
      }

      return `${total}`;
    }
  };

  return (
    <Card>
      <BaseButtonsForm.Item>
        <NormalText bold size="l">
          {t('usageAnalytics.headerTitle', { year: currentYear })}
        </NormalText>
      </BaseButtonsForm.Item>
      <NormalText size="l" bottomMargin="s" semiBold>
        {t('usageAnalytics.total')}
      </NormalText>
      <Row>
        <InfoBlock>
          <NormalText>{t('usageAnalytics.users')}</NormalText>
          <NormalText>{usersCount}</NormalText>
        </InfoBlock>
        <InfoBlock>
          <NormalText>{t(usageType === 'COSTS' ? 'usageAnalytics.costs' : 'usageAnalytics.messages')}</NormalText>
          {loading ? <LoadingOutlined /> : <NormalText>{getMessagesCount()}</NormalText>}
        </InfoBlock>
      </Row>
      <ChartContainer>
        <Radio.Group
          options={usageTypeOptions}
          onChange={handleUsageType}
          value={usageType}
          optionType="button"
          buttonStyle="solid"
          size="small"
          style={{ marginBottom: '1.5rem' }}
        />
        <Bar options={options} data={data} />
      </ChartContainer>
    </Card>
  );
};
