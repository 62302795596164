import { useAppSelector } from '@app/hooks/reduxHooks';
import { Avatar, Button, Col, Row, Space, Tooltip } from 'antd';
import React from 'react';
import { themeObject } from '@app/styles/themes/themeVariables';
import { MemoizedReactMarkdown } from '../common/Markdown/MemoizedReactMarkdown';
import remarkGfm from 'remark-gfm';
import remarkMath from 'remark-math';
import rehypeMathJaxSvg from 'rehype-mathjax';
import { CodeBlock } from '../common/Markdown/CodeBlock';
import 'react-loading-skeleton/dist/skeleton.css';

import {
  ActionsContainer,
  AppendedFile,
  CancelledInfo,
  ChatBubble,
  ChatBubbleContainer,
  ChatBubbleLeft,
  ChatBubbleLeftImage,
  ChatBubbleRight,
  CopyButton,
  EditInput,
  MessageContent,
  StyledIconCheck,
  StyledInlineP,
  StyledLi,
  StyledOl,
  StyledP,
  StyledTable,
  StyledTd,
  StyledTh,
  StyledUl,
  UserName,
} from './Message.styles';
import { IconCopy, IconEdit, IconRefresh } from '@tabler/icons-react';
import { MessageType } from '@app/types/chatBoxTypes';
import { NormalText } from '../common/BaseTexts/BaseTexts';
import { InfoCircleOutlined, PaperClipOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import useMessageActions from '@app/hooks/useMessageActions';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { GeneratedImageWithFallback } from '../common/GeneratedImageWithFallback/GeneratedImageWithFallback';
import AgentToolMessageComponent from './AgentToolMessageComponent';
import { useCurrentTheme } from '@app/hooks/useCurrentTheme';

interface MessageProps {
  message: MessageType;
  isGenerating?: boolean;
  errorOnMessageProcessing?: boolean;
  allowEdit?: boolean;
  onUpdateMessageText?: (messageId: string, newText: string) => void;
  isImageCreationMode?: boolean;
  onRefreshMessageText?: (messageId: string) => void;
}

const Message: React.FC<MessageProps> = ({
  message,
  isGenerating,
  errorOnMessageProcessing,
  allowEdit,
  onUpdateMessageText,
  isImageCreationMode,
  onRefreshMessageText,
}) => {
  const user = useAppSelector((state) => state.user.user);
  const theme = useAppSelector((state) => state.theme.theme);
  const {
    messagedCopied,
    messagedEditMode,
    messagedEditText,
    setMessagedEditText,
    setMessagedEditMode,
    copyOnClick,
    onEditClicked,
    saveNewMessageText,
  } = useMessageActions(message, onUpdateMessageText);

  const { t } = useTranslation();

  const isMyMessage = message.uid === user?._id;

  const renderAvatart = (message: MessageType) => {
    if (isMyMessage) {
      return message.avatar ? (
        <ChatBubbleLeftImage src={message.avatar} alt="user avatar" />
      ) : (
        <ChatBubbleLeft>
          <Avatar alt="User" shape="circle" size={30} style={{ backgroundColor: themeObject[theme].avatarBg }}>
            {user?.firstName ? user.firstName.slice(0, 2).toUpperCase() : ''}
          </Avatar>
        </ChatBubbleLeft>
      );
    }
    return <ChatBubbleLeftImage src={'/icons/gpt-oracle.png'} alt="user avatar" />;
  };

  const renderToolAgentTool = (message: MessageType) => {
    if (message.agentToolMessages?.length) {
      return message.agentToolMessages.map((item, index) => (
        <AgentToolMessageComponent
          key={'agent_tool_' + index}
          actionType={item.actionType}
          contextType={item.contextType}
          message={item.message}
          errorMessage={item.errorMessage}
          messageType={item.messageType}
        />
      ));
    }
    return null;
  };

  return (
    <ChatBubbleContainer isMyMessage={isMyMessage}>
      <ChatBubble isMyMessage={isMyMessage} isGenerating={false}>
        {renderAvatart(message)}
        <ChatBubbleRight>
          <Row justify={'space-between'} style={{ width: '100%' }}>
            <Col>
              <UserName>{message.name}</UserName>
            </Col>
            {isMyMessage && !isGenerating && allowEdit && (
              <Col>
                <Row></Row>
                <ActionsContainer className="ActionsContainer">
                  <CopyButton onClick={onEditClicked}>
                    <IconEdit size={16} />
                  </CopyButton>
                </ActionsContainer>
              </Col>
            )}
            {!isMyMessage && !isGenerating && !isImageCreationMode && (
              <Col>
                <ActionsContainer className="ActionsContainer">
                  <Row gutter={6}>
                    {onRefreshMessageText && (
                      <Col>
                        <CopyButton onClick={() => onRefreshMessageText(message.id)}>
                          <IconRefresh size={16} />
                        </CopyButton>
                      </Col>
                    )}
                    {!errorOnMessageProcessing && (
                      <Col>
                        {messagedCopied ? (
                          <StyledIconCheck size={16} />
                        ) : (
                          <CopyButton onClick={copyOnClick}>
                            <IconCopy size={16} />
                          </CopyButton>
                        )}
                      </Col>
                    )}
                  </Row>
                </ActionsContainer>
              </Col>
            )}
          </Row>

          {messagedEditMode ? (
            <Space direction="vertical">
              <EditInput
                isSidebarMode={false}
                value={messagedEditText}
                onChange={(e) => setMessagedEditText(e.target.value)}
                rows={4}
              ></EditInput>
              <Row justify={'center'} gutter={16}>
                <Col>
                  <Button size="small" type="primary" onClick={() => saveNewMessageText()}>
                    Save & submit
                  </Button>
                </Col>
                <Col>
                  <Button size="small" onClick={() => setMessagedEditMode(false)}>
                    Cancel
                  </Button>
                </Col>
              </Row>
            </Space>
          ) : (
            <MessageContent>
              {message?.imageUrls &&
                message.imageUrls.map((imageUrl) => {
                  return <GeneratedImageWithFallback key={imageUrl} src={imageUrl} />;
                })}
              {message.messageIsLoading ? (
                isImageCreationMode ? (
                  <SkeletonTheme
                    baseColor="var(--skeleton-background-color)"
                    highlightColor="var(--secondary-background-selected-color)"
                  >
                    <Skeleton style={{ height: 300, width: '100%', maxWidth: '300px' }} />
                  </SkeletonTheme>
                ) : (
                  <span className="animate-pulse-cursor">▍</span>
                )
              ) : isMyMessage ? (
                <NormalText style={{ whiteSpace: 'pre-wrap' }}>{message.text}</NormalText>
              ) : (
                <MemoizedReactMarkdown
                  className="prose dark:prose-invert flex-1"
                  remarkPlugins={[remarkGfm, remarkMath]}
                  rehypePlugins={[rehypeMathJaxSvg]}
                  disallowedElements={['markdown', 'language-markdown']}
                  components={{
                    a({ children, href }) {
                      return (
                        <Tooltip title={href}>
                          <a href={href}>{children}</a>
                        </Tooltip>
                      );
                    },
                    code({ className, children, ...props }) {
                      const childrenAny = children as any;

                      if (childrenAny && childrenAny.length) {
                        if (childrenAny[0] == '▍') {
                          return <span className="animate-pulse-cursor">▍</span>;
                        }
                        //children[0] = (children[0] as string).replace('`▍`', '▍');
                      }

                      // if (className === 'language-markdown') {
                      //   return (
                      //     <code className={className} {...props}>
                      //       {children}
                      //     </code>
                      //   );
                      // }

                      const match = /language-(\w+)/.exec(className || '');

                      if (!match) return <code>{children}</code>;
                      //BUG: ****** className:  – "language-markdown"

                      return (
                        <CodeBlock
                          key={Math.random()}
                          language={(match && match[1]) || ''}
                          value={String(children).replace(/\n$/, '')}
                          {...props}
                        />
                      );
                    },
                    table({ children }) {
                      return <StyledTable>{children}</StyledTable>;
                    },
                    th({ children }) {
                      return <StyledTh>{children}</StyledTh>;
                    },
                    td({ children }) {
                      return <StyledTd>{children}</StyledTd>;
                    },
                    ul({ children }) {
                      return <StyledUl>{children}</StyledUl>;
                    },
                    ol({ children }) {
                      return <StyledOl>{children}</StyledOl>;
                    },
                    li({ children }) {
                      return <StyledLi>{children}</StyledLi>;
                    },
                    p({ children }) {
                      return <StyledP>{children}</StyledP>;
                    },
                    strong({ children }) {
                      return <StyledInlineP bold>{children}</StyledInlineP>;
                    },
                    b({ children }) {
                      return <StyledInlineP bold>{children}</StyledInlineP>;
                    },
                  }}
                >
                  {`${message.text} ${message.isGenerating ? '`▍`' : ''}`}
                </MemoizedReactMarkdown>
              )}

              {!message.messageIsLoading &&
                message.agentToolMessages &&
                message.agentToolMessages.length > 0 &&
                renderToolAgentTool(message)}
            </MessageContent>
          )}

          {!isMyMessage && !isGenerating && errorOnMessageProcessing && !message.wasCancelled && (
            <Row gutter={10} justify={'center'}>
              <Col>
                <InfoCircleOutlined />
              </Col>
              <Col>
                <NormalText>{t('processingError')}</NormalText>
              </Col>
            </Row>
          )}

          {message.wasCancelled && (
            <CancelledInfo>
              <NormalText italic light size="s" colorType="light">
                {t('answerWasCancelled')}
              </NormalText>
            </CancelledInfo>
          )}
          {!message.messageIsLoading && message.attachedFileName && (
            <AppendedFile>
              <Space>
                <PaperClipOutlined />
                <NormalText semiBold size="s">
                  {message.attachedFileName}
                </NormalText>
              </Space>
            </AppendedFile>
          )}
        </ChatBubbleRight>
      </ChatBubble>
    </ChatBubbleContainer>
  );
};

export default Message;
