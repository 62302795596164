import { Tooltip } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const StepsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  margin: 20px 20px;
  padding: 0 2rem;
`;

const Step = styled.div<{ isSelected: boolean; isActive: boolean }>`
  position: relative;
  text-align: center;
  cursor: pointer;
  text-overflow: ellipsis;
  margin: 0 2px;

  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: ${({ isActive }) => (isActive ? '16px' : '10px')};
    height: ${({ isActive }) => (isActive ? '16px' : '10px')};
    background-color: ${({ isSelected }) => (isSelected ? 'var(--primary-color)' : '#ccc')};
    border-radius: 50%;
    border: 2px solid
      ${({ isSelected, isActive }) => (isActive ? '#23df50' : isSelected ? 'var(--primary-color)' : '#ccc')};
  }

  &:after {
    content: attr(data-date);
    position: absolute;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 10px;
    color: ${({ isSelected }) => (isSelected ? 'var(--primary-color)' : 'var(--text-main-color)')};
  }
`;

const Line = styled.div`
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  height: 2px;
  background-color: #ccc;
  z-index: 0;
`;

interface HistoryStepsProps {
  steps: { date: string; title: string; isSelected: boolean; isActive: boolean; onClick: () => void }[];
}

const HistorySteps: React.FC<HistoryStepsProps> = ({ steps }) => {
  const { t } = useTranslation();
  return (
    <StepsContainer>
      <Line />
      {steps.map((step, index) => (
        <Tooltip key={index} title={step.isActive ? `${t('lawRadar.activeVersion')} ${step.title}` : step.title}>
          <Step isSelected={step.isSelected} isActive={step.isActive} data-date={step.date} onClick={step.onClick} />
        </Tooltip>
      ))}
    </StepsContainer>
  );
};

export default HistorySteps;
