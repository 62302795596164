import { ILawChangeReport } from '@app/types/lawTypes';
import React from 'react';
import { NormalText } from '../common/BaseTexts/BaseTexts';
import { IconDotsVertical, IconFileText, IconTrash } from '@tabler/icons-react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Dropdown, Space } from 'antd';
import { LawRadarDefaultDateFormat } from '@app/utils/constants';
import { useCurrentTheme } from '@app/hooks/useCurrentTheme';

interface LawReportItemProps extends ILawChangeReport {
  index: number;
  isSelected: boolean;
  onDeleteReport: (id: string) => void;
  onSelect: (id: string) => void;
}

const LawReportItem: React.FC<LawReportItemProps> = ({
  isSelected,
  onDeleteReport,
  onSelect,
  startDate,
  endDate,
  state,
  changesConsidered,
  _id,
}) => {
  const { t } = useTranslation();

  const { currentTheme } = useCurrentTheme();

  const getStateText = () => {
    if (state === 'INITIAL') {
      return (
        <NormalText colorType="light" size="xs">
          {t('lawRadar.stateNotSet')}
        </NormalText>
      );
    } else if (state === 'APPROVED') {
      return (
        <NormalText style={{ color: 'var(--success-color)' }} size="xs">
          {t('lawRadar.approved')}
        </NormalText>
      );
    } else if (state === 'DECLINED') {
      return (
        <NormalText style={{ color: 'var(--error-color)' }} size="xs">
          {t('lawRadar.declined')}
        </NormalText>
      );
    } else if (state === 'MUST_BE_REVIEWED') {
      return (
        <NormalText style={{ color: 'var(--warning-color)' }} size="xs">
          {t('lawRadar.mustBeReviewed')}
        </NormalText>
      );
    }
    return null;
  };

  const menuItems = [{ label: t('common.delete'), key: 'delete', icon: <IconTrash size={16} /> }];

  return (
    <div style={isSelected ? { ...styles.container, ...styles.containerSelected } : styles.container}>
      <div style={styles.content} onClick={() => onSelect(_id ?? '')}>
        <div>
          <Space>
            <IconFileText size={16} />
            <NormalText size="m">
              {`${moment.unix(startDate).format(LawRadarDefaultDateFormat)} - ${moment.unix(endDate).format(LawRadarDefaultDateFormat)}`}
            </NormalText>
          </Space>
          <NormalText size="s" light colorType="light">
            {`${changesConsidered?.length || 0} ${t('lawRadar.changesIncluded')}`}
          </NormalText>
        </div>
      </div>
      <div style={styles.footer}>
        <span>{getStateText()}</span>
        <Dropdown
          menu={{
            items: menuItems,
            onClick: ({ key }) => {
              if (key === 'delete') onDeleteReport(_id ?? '');
            },
          }}
          trigger={['click']}
        >
          <div style={{ cursor: 'pointer' }}>
            <IconDotsVertical size={16} color={currentTheme.textLight} />
          </div>
        </Dropdown>
      </div>
    </div>
  );
};

const styles = {
  container: {
    backgroundColor: 'var(--secondary-background-color)',
    padding: '4px 12px',
    margin: '8px',
    borderRadius: '8px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
  },
  containerSelected: {
    border: '1px solid var(--primary-color)',
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '2px 0',
  },
  index: {
    color: 'var(--primary-color)',
    fontWeight: 'bold',
  },
  icon: {
    cursor: 'pointer',
    color: 'var(--subtext-color)',
    marginLeft: '4px',
  },
  disabledIcon: {
    cursor: 'pointer',
    color: 'var(--subtext-color)',
    marginLeft: '8px',
  },
  content: {
    marginTop: '4px',
    cursor: 'pointer',
  },
};

export default LawReportItem;
