import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@app/hooks/reduxHooks';
import { useResponsive } from '@app/hooks/useResponsive';
import { useTranslation } from 'react-i18next';
import { IconAdjustmentsAlt, IconX } from '@tabler/icons-react';
import { isEmpty } from 'lodash';
import moment from 'moment';
import { setKnowledgeAreaFilter } from '@app/store/slices/knowledgeAreaFilterSlice';
import * as S from './GptChatBoxFilter.styles';
import { Col, Row } from 'antd';

type GptChatBoxFilterProps = {
  openFilterDrawer: (value: boolean) => void;
  selectedKnowledgeAreaId: string;
};

type TagType = { id: string; text: string };

export const GptChatBoxFilter: React.FC<GptChatBoxFilterProps> = ({ openFilterDrawer, selectedKnowledgeAreaId }) => {
  const [tags, setTags] = useState<TagType[]>([]);
  const { t } = useTranslation();
  const { isTablet } = useResponsive();

  const knowledgeAreaFilters = useAppSelector((state) => state.knowledgeAreaFilter);

  const dispatch = useAppDispatch();

  const deleteFilter = (filterName: string) => {
    const valueToSet = filterName === 'validFrom' || filterName === 'validUntil' ? undefined : [];
    const knowledgeAreaFilter = knowledgeAreaFilters[selectedKnowledgeAreaId];
    const filterToSave = { ...knowledgeAreaFilter, [filterName]: valueToSet };

    dispatch(setKnowledgeAreaFilter({ knowledgeAreaId: selectedKnowledgeAreaId, value: filterToSave }));
  };

  useEffect(() => {
    if (selectedKnowledgeAreaId) {
      const knowledgeAreaFilter = knowledgeAreaFilters[selectedKnowledgeAreaId ?? ''];

      const tagsToSet = knowledgeAreaFilter
        ? Object.entries(knowledgeAreaFilter).reduce((acc, [filterKey, filterValue]) => {
            if (isEmpty(filterValue) && !(filterKey === 'validFrom' || filterKey === 'validUntil')) return acc;

            if (filterValue && (filterKey === 'validFrom' || filterKey === 'validUntil')) {
              return [
                ...acc,
                {
                  id: filterKey,
                  text: `${filterKey === 'validFrom' ? t('validFrom') : t('validUntil')}: ${moment.unix(filterValue).format('DD/MM/YY')}`,
                },
              ];
            }

            if (filterKey === 'selectedDocuments') {
              return [
                ...acc,
                {
                  id: filterKey,
                  text: `${filterValue.length} ${t(filterValue.length === 1 ? 'individualFileSelected' : 'individualFilesSelected')}`,
                },
              ];
            }

            // if (filterKey === 'filterDocumentTypes') {
            //   return [...acc, { id: filterKey, text: `${t('selectedFileTypes')} ${filterValue.join(', ')}` }];
            // }

            if (filterKey === 'selectedWebsites') {
              return [
                ...acc,
                {
                  id: filterKey,
                  text: `${filterValue.length} ${t(filterValue.length === 1 ? 'individualWebsiteSelected' : 'individualWebsitesSelected')}`,
                },
              ];
            }

            return acc;
          }, [] as TagType[])
        : [];

      setTags(tagsToSet);
    }
  }, [selectedKnowledgeAreaId, knowledgeAreaFilters]);

  return (
    <S.ActionsContainer>
      <S.TagsWrapper>
        {isTablet &&
          tags.map((tag) => (
            <S.TagStyled
              key={tag.id}
              closable
              onClose={() => deleteFilter(tag.id)}
              onClick={() => openFilterDrawer(true)}
              closeIcon={
                <IconX
                  size={12}
                  style={{
                    marginLeft: '0.5rem',
                    marginBottom: '0.2rem',
                    verticalAlign: 'middle',
                    color: 'var(--text-light-color)',
                  }}
                />
              }
            >
              {tag.text}
            </S.TagStyled>
          ))}
      </S.TagsWrapper>
      <S.ActionsWrapper>
        <S.ActionButtonWrapper type={'link'} onClick={() => openFilterDrawer(true)} style={{ fontSize: '0.75rem' }}>
          <Row align={'middle'} gutter={4}>
            <Col>
              <IconAdjustmentsAlt size={14} />
            </Col>
            <Col>{t('common.filter')}</Col>
          </Row>
        </S.ActionButtonWrapper>
      </S.ActionsWrapper>
    </S.ActionsContainer>
  );
};
