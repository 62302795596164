import React, { useEffect, useState } from 'react';
import { Button, Col, Row, message, Progress, Space, Modal, Tooltip, Dropdown, Tabs, Tag, Input, Menu } from 'antd';
import type { TabsProps } from 'antd';
import { NormalText } from '@app/components/common/BaseTexts/BaseTexts';
import { SpecialZoomLevel, Viewer } from '@react-pdf-viewer/core';
import { Worker } from '@react-pdf-viewer/core';
import { RenderShowSearchPopoverProps, SingleKeyword, searchPlugin } from '@react-pdf-viewer/search';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/search/lib/styles/index.css';
import '@react-pdf-viewer/highlight/lib/styles/index.css';
import { MainContainer } from '../GptTemplatesDetail.styles';
import {
  TextWrapper,
  ContentWrapper,
  ProgressContainer,
  ResultWrapper,
  ResultItem,
  RootContainer,
  EditorMainColumn,
  ResultContainer,
  StatisticContainer,
  BottomStatisticContainer,
  ResultItemContainer,
  ActionsContainer,
  ActionsContainerPlaceholder,
} from './GptDocumentChecker.styles';
import { LocalStoredFile } from '../../singleFileUploader/SingleFileUploader';
import { executeDocumentCheck, executeDocumentGapAnalysisCheck } from '@app/api/gpt.api';
import { BEDROCK_GPT_MODEL, GPT_4o, GPT_MODEL } from '@app/utils/constants';
import {
  DocumentCheckResult,
  DocumentSource,
  DocumentChecklist,
  PostiveAnswerType,
  NegativeAnswerType,
  UnsureAnswerType,
  DocumentCheckFullResult,
  DocumentCheckGapAnalysisResult,
} from '@app/types/documentCheckTypes';
import useSocketMessageHandling from '@app/hooks/useSocketMessageHandling';
import {
  IconArrowNarrowLeft,
  IconArrowNarrowRight,
  IconCircleCheck,
  IconCircleX,
  IconFileDownload,
  IconHelp,
  IconReportSearch,
  IconEdit,
  IconMessageForward,
  IconBug,
  IconReload,
} from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';
import { FilePdfOutlined, ReloadOutlined, SearchOutlined, WarningOutlined } from '@ant-design/icons';
import { debounce } from 'lodash';
import { stringToShortSnippetsArray } from '@app/utils/utils';
import { NavigationWrapper } from '@app/components/gptchatbox/PdfViewer.styles';
import { shortenString } from '@app/utils/stringHelpers';
import { useLocation, useNavigate } from 'react-router-dom';
import { useHandleChecklists } from '@app/hooks/useHandleChecklists';
import GptChatBox from '@app/components/gptchatbox/GptChatBox';
import { IChatHistory } from '@app/domain/ChatHistoryModel';
import { updateChecklistReviewResult } from '@app/api/checklistReviewResults.api';
import { IChecklistReviewResults } from '@app/domain/ChecklistReviewResultsModel';
import { SourceDocumentType } from '@app/types/chatBoxTypes';
import { LoadingSpin } from '@app/components/common/LoadingSpin';
import NavigationHeader from '@app/components/common/NavigationHeader/NavigationHeader';
import { useErrorHandling } from '@app/hooks/useErrorHandling';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { pageNavigationPlugin } from '@react-pdf-viewer/page-navigation';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { useCurrentTheme } from '@app/hooks/useCurrentTheme';

type AiResultOptions = 'Results' | 'AI-Gap-Analysis' | 'AI-Chat';

const GptDocumentChecker: React.FC = () => {
  const [resultsView, setResultsView] = useState<AiResultOptions>('Results');
  const [filesToReview, setFilesToReview] = useState<LocalStoredFile[]>([]);
  const [fileURLToShow, setFileURLToShow] = useState<string>('');
  const [currentFileName, setCurrentFileName] = useState<string>('');
  const [uploadedDocumentIds, setUploadedDocumentIds] = useState<string[]>([]);
  const [reviewResult, setReviewResult] = useState<IChecklistReviewResults | null>(null);
  const [generatedResults, setGeneratedResults] = useState<DocumentCheckResult[]>([]);
  const [generatedGapAnalysisResult, setGeneratedGapAnalysisResult] = useState<DocumentCheckGapAnalysisResult | null>(
    null,
  );
  const [selectedChecklist, setSelectedChecklist] = useState<DocumentChecklist | null>(null);

  const [positveAnswers, setPositiveAnswers] = useState<number>(0);
  const [negativeAnswers, setNegativeAnswers] = useState<number>(0);
  const [unclearAnswers, setUnclearAnswers] = useState<number>(0);

  const [requiredCriteriasNotMet, setRequiredCriteriasNotMet] = useState<number>(0);
  const [shouldCriteriasNotMet, setShouldCriteriasNotMet] = useState<number>(0);
  const [optionalCriteriasNotMet, setOptionalCriteriasNotMet] = useState<number>(0);

  const [hasRequiredCriterias, setHasRequiredCriterias] = useState(false);
  const [hasShouldCriterias, setHasShouldCriterias] = useState(false);
  const [hasOptionalCriterias, setHasOptionalCriterias] = useState(false);

  const [allExecutedChecks, setAllExecutedChecks] = useState<number>(0);

  const [generating, setGenerating] = useState<boolean>(false);
  const [generatingGapAnalysis, setGeneratingGapAnalysis] = useState<boolean>(false);
  const [sourceAuditActiveIndex, setSourceAuditActiveIndex] = useState<string | null>(null);
  const [showNavigationBlock, setShowNavigationBlock] = useState(false);
  const [currentTextMatchIndex, setCurrentTextMatchIndex] = useState(0);
  const [allTextMatches, setAllTextMatches] = useState(0);
  const [couldNotHighlightTextIndex, setCouldNotHighlightTextIndex] = useState('');

  const [editModeIndex, setEditModeIndex] = useState<string | null>(null);
  const [editedAnswer, setEditedAnswer] = useState<string>('');
  const [editedAnswerType, setEditedAnswerType] = useState<string>('');

  const [selectedQuestionForDeepDive, setSelectedQuestionForDeepDive] = useState<string>('');

  const { appName } = useAppSelector((state) => state.appLayout);
  const currentUser = useAppSelector((state) => state.user.user);

  const location = useLocation();

  const { currentTheme } = useCurrentTheme();

  const { t } = useTranslation();

  const navigate = useNavigate();

  const { aiProgress, aiProgressDoneStepInfo, socketChanelId, resetState } = useSocketMessageHandling();

  const { documentChecklists } = useHandleChecklists();

  const { handleApiError } = useErrorHandling();

  const searchPluginInstance = searchPlugin();
  const { highlight, clearHighlights, jumpToPreviousMatch, jumpToNextMatch, ShowSearchPopover } = searchPluginInstance;

  const pageNavigationPluginInstance = pageNavigationPlugin();
  const { jumpToPage } = pageNavigationPluginInstance;

  useEffect(() => {
    if (location.state?.startNewReview) {
      debauncedOnPrepareForStart();
    }
  }, [location]);

  useEffect(() => {
    if (location.state?.reviewResult) {
      debauncedOnPrepareForEdit();
    } else if (location.state?.checklistId) {
      setCurrentChecklist(location.state?.checklistId);
    }
  }, [location, documentChecklists]);

  const onPrepareForEdit = () => {
    const reviewResult: IChecklistReviewResults = location.state?.reviewResult;
    setReviewResult(reviewResult);
    if (reviewResult.reviewTasks.length) {
      if (reviewResult.reviewTasks[0].checkResults) setCheckResults(reviewResult.reviewTasks[0].checkResults);
      setCurrentChecklist(reviewResult.reviewTasks[0].checklistId);
    }
    setGeneratedGapAnalysisResult(reviewResult.gapAnalysisResult || null);
    setCurrentFileName(location.state?.documentTitle ?? '');
    setFileURLToShow(location.state?.documentUrl ?? '');
    setUploadedDocumentIds(location.state?.uploadedDocumentIds || []);
  };

  const debauncedOnPrepareForEdit = debounce(onPrepareForEdit, 500);

  const onPrepareForStart = () => {
    if (location.state?.timestampStartClicked) {
      const secondsAgo = moment().diff(moment.unix(location.state?.timestampStartClicked), 'seconds');
      if (secondsAgo > 5) {
        // user navigated back -> prevent restart check
        navigate('/contractanalyze?tab=2');
        return;
      }
    }
    if (location.state?.filesToReview?.length) {
      setCurrentFileName(location.state?.filesToReview[0].name);
      setFilesToReview(location.state?.filesToReview);
      setCurrentChecklist(location.state?.checklistId);
      onStartDocumentCheck(location.state?.filesToReview, location.state?.modelName, location.state?.checklistId);
    }
  };

  const debauncedOnPrepareForStart = debounce(onPrepareForStart, 500);

  const setCurrentChecklist = (checklistId: string) => {
    const checklist = documentChecklists.find((documentChecklist) => documentChecklist._id === checklistId);
    if (checklist) {
      setSelectedChecklist(checklist);
    }
  };

  const onShowSourcesInDocument = (result: DocumentCheckResult, index: string) => {
    const sources: DocumentSource[] = result.sources || [];
    const allSources: string[] = result.allSources || [];

    setShowNavigationBlock(false);
    setCouldNotHighlightTextIndex('');

    const jumToFirstMatchPage = () => {
      if (sources.length && sources[0].pageNumber) {
        jumpToPage(sources[0].pageNumber - 1);
      }
    };

    const highlightData: SingleKeyword | RegExp[] = [];
    try {
      for (const source of sources) {
        //const regexString = source.pageContent.replace(/ /g, '\\s*').replace(/,/g, '\\s*,\\s*');
        const regexString = source.pageContent.replace(/\s+/g, '\\s*');
        const regex = new RegExp(regexString, 'g');
        highlightData.push(regex);
      }
    } catch (error) {
      jumToFirstMatchPage();
      return;
    }

    clearHighlights();
    setSourceAuditActiveIndex(index);
    setTimeout(async () => {
      try {
        const matchRes = await highlight(highlightData);
        if (matchRes.length === 0) {
          const highlightDataByLines: SingleKeyword[] = [];

          for (const content of sources) {
            const lines = stringToShortSnippetsArray(content.pageContent);
            lines.forEach((keyword) => {
              if (keyword.length > 1) {
                const regexString = keyword.replace(/\s+/g, '\\s*');
                const regex = new RegExp(regexString, 'g');
                highlightDataByLines.push(regex);
              }
            });
          }
          const matchRes2 = await highlight(highlightDataByLines);
          if (matchRes2.length === 0) {
            jumToFirstMatchPage();
            fallbackHighlighting(allSources);
            setCouldNotHighlightTextIndex(index);
          } else if (matchRes.length > 1) {
            setShowNavigationBlock(true);
            setCurrentTextMatchIndex(1);
            setAllTextMatches(matchRes.length);
          }
        } else if (matchRes.length > 1) {
          setShowNavigationBlock(true);
          setCurrentTextMatchIndex(1);
          setAllTextMatches(matchRes.length);
        }
      } catch (error) {
        jumToFirstMatchPage();
        fallbackHighlighting(allSources);
        setCouldNotHighlightTextIndex(index);
      }
    }, 1000);
  };

  const fallbackHighlighting = (allSources: string[], onFallback?: () => void) => {
    if (allSources.length) {
      try {
        const highlightData: SingleKeyword[] = [];

        for (const content of allSources) {
          const snippets = stringToShortSnippetsArray(content);

          // Sortiere die Keywords nach ihrer Länge in absteigender Reihenfolge
          const sortedKeywords = snippets.sort((a, b) => b.length - a.length);

          // Berechne die Anzahl der Top 70% Keywords
          const topPercentCount = Math.ceil(sortedKeywords.length * 0.7);

          // Wähle die Top 70% Keywords aus
          const topPercentKeywords = sortedKeywords.slice(0, topPercentCount);

          topPercentKeywords.forEach((keyword) => {
            if (keyword.length > 1) {
              highlightData.push({ keyword, matchCase: true });
            }
          });
        }

        clearHighlights();
        setTimeout(async () => {
          const matchRes = await highlight(highlightData);
          if (matchRes.length > 1) {
            setShowNavigationBlock(true);
            setCurrentTextMatchIndex(1);
            setAllTextMatches(matchRes.length);
          } else {
            onFallback?.();
          }
        }, 1000);
      } catch (error) {
        console.log('****** s2 highlight passages error: ', error);
      }
    }
  };

  const setCheckResults = (result: DocumentCheckFullResult) => {
    if (result && Array.isArray(result.results)) {
      const sortedResults: DocumentCheckResult[] = result.results.sort((a, b) => {
        const aType = a.type === 'OPTIONAL' ? 3 : a.type === 'SHOULD' ? 2 : 1;
        const bType = b.type === 'OPTIONAL' ? 3 : b.type === 'SHOULD' ? 2 : 1;
        return aType - bType;
      });

      setGeneratedResults(sortedResults);

      let pa = 0;
      let na = 0;
      let ua = 0;

      let criteriasNotMet = 0;
      let shouldCriteriasNotMet = 0;
      let optionalCriteriasNotMet = 0;

      const countAnswers = (item: DocumentCheckResult) => {
        if (item.answerType === PostiveAnswerType) {
          pa += 1;
        } else if (item.answerType === NegativeAnswerType) {
          na += 1;
        } else if (item.answerType === UnsureAnswerType) {
          ua += 1;
        }
        const isRequired = (!item.type && item.isOptional === false) || item.type === 'MUST';
        const isShould = item.type === 'SHOULD';
        const isOptional = item.type === 'OPTIONAL';
        if (isRequired && item.answerType !== PostiveAnswerType) {
          criteriasNotMet += 1;
        } else if (isShould && item.answerType !== PostiveAnswerType) {
          shouldCriteriasNotMet += 1;
        } else if (isOptional && item.answerType !== PostiveAnswerType) {
          optionalCriteriasNotMet += 1;
        }
      };

      const countAllAnswers = (results: DocumentCheckResult[]) => {
        for (const item of results) {
          if (item.children?.length) {
            countAllAnswers(item.children);
          } else {
            countAnswers(item);
          }
        }
      };

      setHasRequiredCriterias(
        sortedResults.some((item) => item.type === 'MUST' || (!item.type && item.isOptional === false)),
      );
      setHasShouldCriterias(sortedResults.some((item) => item.type === 'SHOULD'));
      setHasOptionalCriterias(sortedResults.some((item) => item.type === 'OPTIONAL'));

      countAllAnswers(sortedResults);

      setPositiveAnswers(pa);
      setNegativeAnswers(na);
      setUnclearAnswers(ua);
      setAllExecutedChecks(pa + na + ua);
      setRequiredCriteriasNotMet(criteriasNotMet);
      setShouldCriteriasNotMet(shouldCriteriasNotMet);
      setOptionalCriteriasNotMet(optionalCriteriasNotMet);
    } else {
      message.error('Unexpeted server error: empty data');
    }
  };

  const onStartDocumentCheck = async (
    filesToReview: LocalStoredFile[],
    modelName: GPT_MODEL | BEDROCK_GPT_MODEL,
    checklistId: string,
  ) => {
    if (!filesToReview.length) {
      message.warn(t('documentChecker.uploadToCheckDocument'));
      return;
    }
    if (!checklistId) {
      message.warn(t('documentChecker.selectChecklist'));
      return;
    }
    if (generating) return;

    const formData = new FormData();
    formData.append('files', filesToReview[0].originFileObj as Blob, encodeURIComponent(filesToReview[0].name));

    formData.append(`outputFormat`, 'Text');
    formData.append(`modelName`, modelName);
    formData.append(`checklistId`, checklistId);
    formData.append(`socketChanelId`, socketChanelId.current);

    try {
      setGenerating(true);
      setGeneratedResults([]);

      setHasRequiredCriterias(false);
      setHasShouldCriterias(false);
      setHasOptionalCriterias(false);

      setPositiveAnswers(0);
      setNegativeAnswers(0);
      setUnclearAnswers(0);
      setAllExecutedChecks(0);
      setRequiredCriteriasNotMet(0);
      setShouldCriteriasNotMet(0);
      setOptionalCriteriasNotMet(0);

      resetState();

      const fileUrlToShow = filesToReview[0].url;
      setFileURLToShow(fileUrlToShow);

      const result = await executeDocumentCheck(formData);
      setReviewResult(result);

      if (result.reviewTasks.length && result.reviewTasks[0].checkResults) {
        setCheckResults(result.reviewTasks[0].checkResults);
        if (result.reviewTasks[0].uploadedDocuments.length) {
          setUploadedDocumentIds([result.reviewTasks[0].uploadedDocuments[0]._id]);
        }
      }
    } catch (error) {
      message.error('Unexpeted server error: ' + error);
      handleApiError(error);
    } finally {
      setGenerating(false);
    }
  };

  const onStartAiGapAnalysis = async () => {
    if (!selectedChecklist?._id) {
      message.warn(t('documentChecker.selectChecklist'));
      return;
    }
    if (generating) return;

    const formData = new FormData();

    formData.append('documentId', uploadedDocumentIds[0]);
    formData.append(`modelName`, reviewResult?.aiModelName ?? GPT_4o);
    formData.append(`checklistId`, selectedChecklist?._id);
    formData.append(`checklistResultsId`, reviewResult?._id ?? '');
    formData.append(`socketChanelId`, socketChanelId.current);

    try {
      setGeneratingGapAnalysis(true);
      setGeneratedGapAnalysisResult(null);

      resetState();

      const analysisResult = await executeDocumentGapAnalysisCheck(formData);
      setGeneratedGapAnalysisResult(analysisResult);
    } catch (error) {
      message.error('Unexpeted server error: ' + error);
      handleApiError(error);
    } finally {
      setGeneratingGapAnalysis(false);
    }
  };

  const onReset = (): void => {
    setFilesToReview([]);
    setCurrentFileName('');
    setGeneratedResults([]);
    setResultsView('Results');
    setSourceAuditActiveIndex(null);
    setShowNavigationBlock(false);
    setCurrentTextMatchIndex(0);
    setCouldNotHighlightTextIndex('');
    setAllTextMatches(0);
    resetState();

    navigate('/contractanalyze?tab=1', { state: { presetChecklistId: selectedChecklist?._id } });
  };

  const onDownloadAsCSV = (): void => {
    const documentCheckResultAsCSV = (checkResults: DocumentCheckResult[]) => {
      const headers = ['topic', 'criteria', 'isOptional', 'answer', 'answerType'];
      const csvRows = [headers.join(';')];

      const processResult = (result: DocumentCheckResult) => {
        const row = [
          result.topic || '',
          result.criteria,
          result.isOptional.toString(),
          result.answer || '',
          result.answerType || '',
        ];
        csvRows.push(row.join(';'));

        if (result.children) {
          result.children.forEach((child) => processResult(child));
        }
      };

      checkResults.forEach((result) => processResult(result));

      csvRows.push('------------');

      if (generatedGapAnalysisResult?.results) {
        csvRows.push('Erweiterte Gap-Analyse durch die KI');
        generatedGapAnalysisResult?.results.forEach((result) => processResult(result));
        csvRows.push('------------');
      }

      csvRows.push(`file: ${currentFileName}`);
      csvRows.push(`checklist: ${selectedChecklist?.name}`);

      const csvContent = csvRows.join('\n');
      return csvContent;
    };

    const csvContent = documentCheckResultAsCSV(generatedResults);

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'document-review-results.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const onDownloadAsMarkdown = (markdown: boolean): void => {
    const headerPrefix = markdown ? '# ' : '';

    const documentCheckResultAsMarkdown = (checkResults: DocumentCheckResult[]) => {
      let markdownContent = `${headerPrefix}Dokumenten-Prüfung mit ${appName}\n\n`;
      markdownContent += `Dokument: ${currentFileName}\n`;
      markdownContent += `Checkliste: ${selectedChecklist?.name}\n\n`;

      markdownContent += `Positive Antworten ${positveAnswers}\n`;
      markdownContent += `Negative Antworten ${negativeAnswers}\n`;
      markdownContent += `Unklar / Nicht relevant ${unclearAnswers}\n\n`;

      markdownContent += `Erforderliche Kriterien: ${requiredCriteriasNotMet > 0 ? requiredCriteriasNotMet + ' nicht erfüllt' : 'Alle erfüllt'}\n`;
      markdownContent += `Sollte Kriterien: ${shouldCriteriasNotMet > 0 ? shouldCriteriasNotMet + ' nicht erfüllt' : 'Alle erfüllt'}\n`;
      markdownContent += `Optionale Kriterien: ${optionalCriteriasNotMet > 0 ? optionalCriteriasNotMet + ' nicht erfüllt' : 'Alle erfüllt'}\n\n`;

      const processResult = (result: DocumentCheckResult, level = 2) => {
        const prefix = `${headerPrefix}`.repeat(level).replaceAll(`# #`, `##`);
        if (result.topic) {
          markdownContent += `${prefix}${result.topic}\n`;
        }
        markdownContent += `${prefix.trim()}${headerPrefix}${result.criteria}\n`;
        markdownContent += `${result.answer}\n\n`;

        if (result.children) {
          result.children.forEach((child) => processResult(child, level + 1));
        }
      };

      checkResults.forEach((result) => processResult(result));

      if (generatedGapAnalysisResult?.results) {
        markdownContent += `\n# Erweiterte Gap-Analyse durch die KI\n`;
        markdownContent += `Ergebnisse:\n`;
        generatedGapAnalysisResult?.results.forEach((result) => processResult(result));
      }

      return markdownContent;
    };

    const markdownContent = documentCheckResultAsMarkdown(generatedResults);

    const blob = new Blob([markdownContent], {
      type: markdown ? 'text/markdown;charset=utf-8;' : 'text/plain;charset=utf-8;',
    });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', markdown ? 'document-review-results.md' : 'document-review-results.txt');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const onChatHistoryCreated = async (history: IChatHistory) => {
    if (reviewResult?._id) {
      await updateChecklistReviewResult(reviewResult._id, { chatHistoryId: history._id });
      setReviewResult((prev) => {
        if (!prev) return null;
        else {
          return { ...prev, chatHistoryId: history._id };
        }
      });
    }
  };

  const onInlineSourceClick = async (source: SourceDocumentType) => {
    if (source) {
      const sources = source.relevantSenteces
        ? source.relevantSenteces.map((item) => ({
            pageContent: item,
            pageNumber: source.page || 0,
            lineFrom: 0,
            lineTo: 0,
          }))
        : [{ pageContent: source.pageContent, pageNumber: source.page || 0, lineFrom: 0, lineTo: 0 }];
      onShowSourcesInDocument(
        {
          criteria: '',
          isOptional: false,
          sources,
          allSources: [source.pageContent],
        },
        '0',
      );
    }
  };

  const renderResultTypeIcon = (answerType?: string) => {
    if (answerType === PostiveAnswerType) {
      return <IconCircleCheck style={{ color: 'green' }} />;
    } else if (answerType === NegativeAnswerType) {
      return <IconCircleX style={{ color: 'red' }} />;
    } else if (answerType === UnsureAnswerType) {
      return <IconHelp style={{ color: 'orange' }} />;
    } else {
      return <IconBug style={{ color: 'red' }} />;
    }
  };

  const renderQuestionType = (result: DocumentCheckResult) => {
    let text = '';
    if (result.type === 'MUST' || (!result.type && result.isOptional === false)) {
      text = t('documentChecker.mustType');
    } else if (result.type === 'SHOULD') {
      text = t('documentChecker.shouldType');
    } else {
      text = t('documentChecker.optionalType');
    }
    return (
      <Tag
        style={{
          fontSize: '0.75rem',
          border: 0,
          color: 'var(--text-light-color)',
          backgroundColor: 'var(--secondary-background-color)',
        }}
      >
        {text}
      </Tag>
    );
  };

  const handleEditClick = (index: string, result: DocumentCheckResult) => {
    setEditModeIndex(index);
    setEditedAnswer(result.answer ?? '');
    setEditedAnswerType(result.answerType ?? '');
  };

  const handleSave = async (index: string, isAiGapAnalysis: boolean) => {
    if (isAiGapAnalysis && generatedGapAnalysisResult) {
      generatedGapAnalysisResult.results.forEach((item, i) => {
        if (`${i}` === index) {
          item.answer = editedAnswer.trim();
          item.answerType = editedAnswerType;
          item.editedByUser = currentUser?._id;
          item.editedAt = moment().unix();
        }
      });
      setGeneratedGapAnalysisResult({ ...generatedGapAnalysisResult });

      if (reviewResult?._id) {
        try {
          await updateChecklistReviewResult(reviewResult._id, { gapAnalysisResult: generatedGapAnalysisResult });
          setEditModeIndex(null);
        } catch (error) {
          handleApiError(error);
        }
      }
    } else {
      generatedResults.forEach((item, i) => {
        if (`${i}` === index) {
          item.answer = editedAnswer.trim();
          item.answerType = editedAnswerType;
          item.editedByUser = currentUser?._id;
          item.editedAt = moment().unix();
        }
      });
      setGeneratedResults([...generatedResults]);

      if (reviewResult?._id && reviewResult?.reviewTasks?.length) {
        try {
          const currentResults = reviewResult?.reviewTasks[0];
          if (currentResults?.checkResults?.results) {
            currentResults.checkResults.results = generatedResults;
            await updateChecklistReviewResult(reviewResult._id, { reviewTasks: [currentResults] });
          }
          setEditModeIndex(null);
        } catch (error) {
          handleApiError(error);
        }
      }
    }
  };

  const handleCancel = () => {
    setEditModeIndex(null);
  };

  const renderResultItem = (result: DocumentCheckResult, index: string, isNested: boolean, aiGapAnalysis: boolean) => (
    <ResultItem key={'resultItem_' + index} isSelected={index === sourceAuditActiveIndex} isNested={isNested}>
      <Row justify={'space-between'} align={'middle'}>
        <Col>
          {result.topic && (
            <NormalText semiBold bottomMargin="s">
              {result.topic}
            </NormalText>
          )}
        </Col>
        {!aiGapAnalysis && <Col>{renderQuestionType(result)}</Col>}
      </Row>
      <NormalText bottomMargin="s">{result.criteria}</NormalText>
      <ResultItemContainer>
        <Space align="start">
          {editModeIndex === index ? null : renderResultTypeIcon(result.answerType)}
          <div>
            {editModeIndex === index ? (
              <>
                <Row gutter={4}>
                  <Col>
                    <Dropdown
                      overlay={
                        <Menu>
                          <Menu.Item key={PostiveAnswerType} onClick={() => setEditedAnswerType(PostiveAnswerType)}>
                            <IconCircleCheck style={{ color: 'green' }} />
                          </Menu.Item>
                          <Menu.Item key={NegativeAnswerType} onClick={() => setEditedAnswerType(NegativeAnswerType)}>
                            <IconCircleX style={{ color: 'red' }} />
                          </Menu.Item>
                          <Menu.Item key={UnsureAnswerType} onClick={() => setEditedAnswerType(UnsureAnswerType)}>
                            <IconHelp style={{ color: 'orange' }} />
                          </Menu.Item>
                        </Menu>
                      }
                    >
                      <Button>{renderResultTypeIcon(editedAnswerType)}</Button>
                    </Dropdown>
                  </Col>
                  <Col>
                    <Input.TextArea value={editedAnswer} onChange={(e) => setEditedAnswer(e.target.value)} rows={4} />
                    <Space style={{ marginTop: '0.5rem' }}>
                      <Button size="small" onClick={handleCancel}>
                        Cancel
                      </Button>
                      <Button size="small" type="primary" onClick={() => handleSave(index, aiGapAnalysis)}>
                        Save
                      </Button>
                    </Space>
                  </Col>
                </Row>
              </>
            ) : (
              <>
                <NormalText light>{result.answer}</NormalText>
                <Space style={{ marginLeft: -16 }}>
                  <ActionsContainerPlaceholder className="ActionsContainerPlaceholder" />
                  <ActionsContainer className="ActionsContainer">
                    <Row justify={'space-between'}>
                      {result.answerType !== UnsureAnswerType && result.sources && result.sources.length > 0 && (
                        <Button
                          type="text"
                          onClick={() => onShowSourcesInDocument(result, index)}
                          style={{ fontWeight: 'normal' }}
                        >
                          <IconReportSearch size={18} />
                        </Button>
                      )}
                      {couldNotHighlightTextIndex === index && (
                        <Tooltip
                          title={t('documentChecker.resultsCouldNotBeMarked', {
                            text: shortenString(
                              result.sources?.map((source) => source.pageContent).join(' ') || '',
                              500,
                            ),
                          })}
                        >
                          <WarningOutlined style={{ fontSize: '1rem', color: '#dc9e20' }} />
                        </Tooltip>
                      )}
                      <Button
                        type="text"
                        style={{ fontWeight: 'normal' }}
                        icon={<IconEdit size={18} />}
                        onClick={() => handleEditClick(index, result)}
                      />
                      <Button
                        type="text"
                        style={{ fontWeight: 'normal' }}
                        icon={<IconMessageForward size={18} />}
                        onClick={() => {
                          setSelectedQuestionForDeepDive(result.criteria ?? '');
                          setResultsView('AI-Chat');
                        }}
                      />
                    </Row>
                  </ActionsContainer>
                </Space>
              </>
            )}
          </div>
        </Space>
      </ResultItemContainer>
    </ResultItem>
  );

  const onJumpToMatch = (step: number) => {
    let nextHighlightIndex = currentTextMatchIndex + step;

    if (nextHighlightIndex <= 0) {
      nextHighlightIndex = allTextMatches;
    } else if (nextHighlightIndex > allTextMatches) {
      nextHighlightIndex = 1;
    }

    setCurrentTextMatchIndex(nextHighlightIndex);

    if (step > 0) {
      jumpToNextMatch();
    } else {
      jumpToPreviousMatch();
    }
  };

  const menuItems = [
    { label: t('documentChecker.downloadAsCSV'), key: 'csv' },
    { label: t('documentChecker.downloadAsTxt'), key: 'txt' },
    { label: t('documentChecker.downloadAsMarkdown'), key: 'markdown' },
  ];

  const resultMode = generatedResults.length > 0;
  const noValidResults = reviewResult?._id && !generating && !resultMode;

  const tabItems: TabsProps['items'] = [
    {
      key: 'Results',
      label: 'Results',
      children: undefined,
    },
    {
      key: 'AI-Gap-Analysis',
      label: 'AI-Gap-Analysis',
      children: undefined,
    },
    {
      key: 'AI-Chat',
      label: 'AI-Chat',
      children: undefined,
    },
  ];

  const showResultView = resultMode || generating;

  return (
    <>
      <NavigationHeader
        title={t('documentChecker.reviewCheckResults')}
        description={
          <Space>
            <FilePdfOutlined size={18} />
            <NormalText centered>{currentFileName}</NormalText>
          </Space>
        }
        onGoBack={() => navigate('/contractanalyze?tab=2')}
        rightComponent={
          !resultMode || generating ? undefined : (
            <Button type="primary" onClick={() => onReset()} size="small" icon={<ReloadOutlined />}>
              {t('documentChecker.reset')}
            </Button>
          )
        }
      />
      {showResultView ? (
        <MainContainer>
          <Row>
            <EditorMainColumn span={14} flex="column">
              <ContentWrapper>
                <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                  {fileURLToShow && (
                    <Viewer
                      fileUrl={fileURLToShow}
                      plugins={[searchPluginInstance, pageNavigationPluginInstance]}
                      enableSmoothScroll
                      defaultScale={SpecialZoomLevel.PageWidth}
                    />
                  )}
                </Worker>
                {showNavigationBlock && (
                  <NavigationWrapper>
                    <Button type="primary" onClick={() => onJumpToMatch(-1)}>
                      <IconArrowNarrowLeft />
                    </Button>
                    <NormalText size="l" horizontalPadding>{`${currentTextMatchIndex} / ${allTextMatches}`}</NormalText>
                    <Button type="primary" onClick={() => onJumpToMatch(1)}>
                      <IconArrowNarrowRight />
                    </Button>
                  </NavigationWrapper>
                )}
                <div style={{ margin: '1rem', position: 'absolute', left: 18, top: 0 }}>
                  <div style={{ borderRadius: 100, backgroundColor: currentTheme.primaryLight }}>
                    <ShowSearchPopover>
                      {(props: RenderShowSearchPopoverProps) => (
                        <Button
                          type="text"
                          size="small"
                          onClick={props.onClick}
                          icon={<SearchOutlined style={{ color: currentTheme.primary }} />}
                        ></Button>
                      )}
                    </ShowSearchPopover>
                  </div>
                </div>
              </ContentWrapper>
            </EditorMainColumn>
            <Col span={10}>
              {fileURLToShow.length > 0 && (
                <ResultWrapper aiChatMode={resultMode && resultsView === 'AI-Chat'}>
                  {resultMode && (
                    <Tabs
                      tabBarStyle={{
                        paddingLeft: 20,
                        paddingRight: 20,
                        paddingTop: 0,
                        marginBottom: resultsView === 'Results' || resultsView === 'AI-Gap-Analysis' ? '1.5rem' : 0,
                      }}
                      defaultActiveKey={'Results'}
                      items={tabItems}
                      activeKey={resultsView}
                      centered
                      onChange={(key) => setResultsView(key as AiResultOptions)}
                    />
                  )}
                  {resultMode && resultsView === 'Results' && (
                    <>
                      <Row justify={'space-between'} style={{ marginBottom: '0.5rem' }}>
                        <NormalText colorType="primary" semiBold size="l">
                          {t('documentChecker.aiResults')}
                        </NormalText>
                        <div style={{ float: 'right' }}>
                          <Dropdown
                            menu={{
                              items: menuItems,
                              onClick: ({ key }) => {
                                if (key === 'csv') onDownloadAsCSV();
                                if (key === 'txt') onDownloadAsMarkdown(false);
                                if (key === 'markdown') onDownloadAsMarkdown(true);
                              },
                            }}
                            trigger={['click']}
                          >
                            <Button type="dashed" size="small" icon={<IconFileDownload size={18} />}></Button>
                          </Dropdown>
                        </div>
                      </Row>
                      <Row gutter={8}>
                        <Col span={8}>
                          <StatisticContainer>
                            <NormalText centered size="s" semiBold>
                              {t('documentChecker.positiveAnswers')}
                            </NormalText>
                            <NormalText centered size="l" bold style={{ color: 'green' }}>
                              {positveAnswers}
                            </NormalText>
                          </StatisticContainer>
                        </Col>
                        <Col span={8}>
                          <StatisticContainer>
                            <NormalText centered size="s" semiBold>
                              {t('documentChecker.negativeAnswers')}
                            </NormalText>
                            <NormalText centered size="l" bold style={{ color: 'red' }}>
                              {negativeAnswers}
                            </NormalText>
                          </StatisticContainer>
                        </Col>
                        <Col span={8}>
                          <StatisticContainer>
                            <NormalText centered size="s" semiBold>
                              {t('documentChecker.unclearAnswers')}
                            </NormalText>
                            <NormalText centered size="l" bold style={{ color: 'orange' }}>
                              {unclearAnswers}
                            </NormalText>
                          </StatisticContainer>
                        </Col>
                      </Row>
                      <BottomStatisticContainer>
                        {hasRequiredCriterias && (
                          <Col>
                            <Space>
                              <NormalText size="m" semiBold>
                                {t('documentChecker.requiredResult')}
                              </NormalText>
                              <NormalText
                                size="m"
                                bold
                                style={{ color: requiredCriteriasNotMet > 0 ? 'orange' : 'green' }}
                              >
                                {requiredCriteriasNotMet > 0
                                  ? t('documentChecker.numberNotMet', { criteriasNotMet: requiredCriteriasNotMet })
                                  : t('documentChecker.allRequiredMet')}
                              </NormalText>
                            </Space>
                          </Col>
                        )}
                        {hasShouldCriterias && (
                          <Col>
                            <Space>
                              <NormalText size="m" semiBold>
                                {t('documentChecker.shouldResult')}
                              </NormalText>
                              <NormalText
                                size="m"
                                bold
                                style={{ color: shouldCriteriasNotMet > 0 ? 'orange' : 'green' }}
                              >
                                {shouldCriteriasNotMet > 0
                                  ? t('documentChecker.numberNotMet', { criteriasNotMet: shouldCriteriasNotMet })
                                  : t('documentChecker.allRequiredMet')}
                              </NormalText>
                            </Space>
                          </Col>
                        )}
                        {hasOptionalCriterias && (
                          <Col>
                            <Space>
                              <NormalText size="m" semiBold>
                                {t('documentChecker.optionalResult')}
                              </NormalText>
                              <NormalText
                                size="m"
                                bold
                                style={{ color: optionalCriteriasNotMet > 0 ? 'orange' : 'green' }}
                              >
                                {optionalCriteriasNotMet > 0
                                  ? t('documentChecker.numberNotMet', { criteriasNotMet: optionalCriteriasNotMet })
                                  : t('documentChecker.allRequiredMet')}
                              </NormalText>
                            </Space>
                          </Col>
                        )}
                      </BottomStatisticContainer>

                      <Link to={`/zen/edit-checklist/${selectedChecklist?._id}`}>
                        <NormalText colorType="primary" light style={{ marginLeft: 4 }}>
                          {t('documentChecker.linkToChecklist')}{' '}
                          <strong>{shortenString(selectedChecklist?.name ?? '', 50)}</strong>
                        </NormalText>
                      </Link>
                    </>
                  )}
                  {resultsView === 'Results' && (
                    <TextWrapper>
                      {!generating &&
                        generatedResults.map((result, index) => {
                          const isNested = result.children && result.children.length > 0;
                          return isNested ? (
                            <ResultContainer key={'resultItem_' + index}>
                              <NormalText size="l" semiBold bottomMargin="m">
                                {result.criteria}
                              </NormalText>
                              {result.children?.map((child, childIndex) => {
                                return renderResultItem(child, `${index}.${childIndex}`, true, false);
                              })}
                            </ResultContainer>
                          ) : (
                            <ResultContainer key={'resultItem_' + index}>
                              {renderResultItem(result, `${index}`, false, false)}
                            </ResultContainer>
                          );
                        })}
                      {generatedResults.length === 0 && (
                        <NormalText colorType="light" centered size="l" light={generating}>
                          {generating ? t('documentChecker.waiting') : t('documentChecker.noResults')}
                        </NormalText>
                      )}
                      {generating && (
                        <ProgressContainer>
                          <Progress
                            percent={aiProgress || 0}
                            style={{ width: '60%', marginTop: '1rem', color: 'gray' }}
                          />
                          <NormalText light colorType="light">
                            {aiProgressDoneStepInfo}
                          </NormalText>
                        </ProgressContainer>
                      )}
                    </TextWrapper>
                  )}
                  {resultsView === 'AI-Gap-Analysis' && (
                    <>
                      <div style={{ margin: '0.5rem 0' }}>
                        <NormalText colorType="primary" semiBold size="l">
                          {t('documentChecker.aiGapAnalysisResults')}
                        </NormalText>
                        <NormalText light verticalPadding style={{ marginBottom: '0.5rem' }}>
                          {t('documentChecker.aiGapAnalysisResultsDescription')}
                        </NormalText>

                        {!generatingGapAnalysis &&
                          (!generatedGapAnalysisResult || generatedGapAnalysisResult?.results.length === 0) && (
                            <Button
                              onClick={() => onStartAiGapAnalysis()}
                              loading={generatingGapAnalysis}
                              style={{ marginBottom: '1rem' }}
                            >
                              {t('documentChecker.startAiGapAnalysis')}
                            </Button>
                          )}

                        {generatedGapAnalysisResult && generatedGapAnalysisResult.results.length > 0 && (
                          <Row justify={'space-between'} align={'top'} style={{ margin: '0 0 1.5rem 0' }}>
                            <Col>
                              <NormalText colorType="primary">{t('documentChecker.aiResults')}</NormalText>
                              <NormalText light colorType="light">
                                {t('documentChecker.aiGapAnalysisProcessingDate', {
                                  date: generatedGapAnalysisResult.executedAt
                                    ? moment.unix(generatedGapAnalysisResult.executedAt).fromNow()
                                    : '-',
                                })}
                              </NormalText>
                            </Col>
                            <Col>
                              <Button
                                onClick={() => onStartAiGapAnalysis()}
                                loading={generatingGapAnalysis}
                                icon={<IconReload size={18} />}
                              />
                            </Col>
                          </Row>
                        )}

                        {generatingGapAnalysis && (
                          <ProgressContainer>
                            <Progress
                              percent={aiProgress || 0}
                              style={{ width: '60%', marginTop: '1rem', color: 'gray' }}
                            />
                            <NormalText light colorType="light">
                              {aiProgressDoneStepInfo}
                            </NormalText>
                          </ProgressContainer>
                        )}

                        {!generatingGapAnalysis &&
                          generatedGapAnalysisResult?.results.map((result, index) => {
                            const isNested = result.children && result.children.length > 0;
                            return isNested ? (
                              <ResultContainer key={'resultGapItem_' + index}>
                                <NormalText size="l" semiBold bottomMargin="m">
                                  {result.criteria}
                                </NormalText>
                                {result.children?.map((child, childIndex) => {
                                  return renderResultItem(child, `${index}.${childIndex}`, true, true);
                                })}
                              </ResultContainer>
                            ) : (
                              <ResultContainer key={'resultGapItem_' + index}>
                                {renderResultItem(result, `${index}`, false, true)}
                              </ResultContainer>
                            );
                          })}
                      </div>
                    </>
                  )}

                  {resultsView === 'AI-Chat' && (
                    <GptChatBox
                      chatMode={'SIDEBAR_DOCUMENT_REVIEW'}
                      presetToAnalyzeFileIds={uploadedDocumentIds}
                      presetChatId={reviewResult?.chatHistoryId}
                      presetEntityId={reviewResult?._id}
                      onChatHistoryCreated={onChatHistoryCreated}
                      onInlineSourceClick={onInlineSourceClick}
                      presetQuestion={selectedQuestionForDeepDive}
                    />
                  )}
                </ResultWrapper>
              )}
            </Col>
          </Row>
        </MainContainer>
      ) : (
        <RootContainer>
          <LoadingSpin />
          {noValidResults && (
            <Modal
              title={t('documentChecker.noValidResutls')}
              open={true}
              onOk={() => navigate('/contractanalyze?tab=2')}
              onCancel={() => navigate('/contractanalyze?tab=2')}
            >
              <NormalText>{t('documentChecker.noValidResutlsDescription')}</NormalText>
            </Modal>
          )}
        </RootContainer>
      )}
    </>
  );
};

export default GptDocumentChecker;
