import { ILawChangeDetails, LawChangeDetailState } from '@app/types/lawTypes';
import React, { useState } from 'react';
import { NormalText } from '../common/BaseTexts/BaseTexts';
import {
  IconPencil,
  IconX,
  IconCheck,
  IconTrash,
  IconCircleDashed,
  IconEyeOff,
  IconReportSearch,
} from '@tabler/icons-react';
import MarkdownContainer from '../common/Markdown/MarkdownContainer';
import { Input, message, Tooltip, Dropdown, Menu } from 'antd';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

const { TextArea } = Input;

interface LawChangeDetailsProps extends ILawChangeDetails {
  index: number;
  isAddMode: boolean;
  showSourceButton: boolean;
  isSelectedSource: boolean;
  onChangeDetailsChanged: (
    parentChapter: string,
    nameOfArticle: string,
    heading: string,
    changeText: string,
    state: LawChangeDetailState,
  ) => void;
  onDeleteChangeDetails: () => void;
  onCancelClick?: () => void;
  onShowPdfSourceClick?: () => void;
}

const LawChangeDetails: React.FC<LawChangeDetailsProps> = ({
  index,
  parentChapter,
  nameOfArticle,
  isAddMode,
  showSourceButton,
  isSelectedSource,
  heading,
  changeText,
  state,
  editedAt,
  editedBy,
  onChangeDetailsChanged,
  onDeleteChangeDetails,
  onCancelClick,
  onShowPdfSourceClick,
}) => {
  const [isEditing, setIsEditing] = useState(isAddMode ? true : false);
  const [editedParentChapter, setEditedParentChapter] = useState(parentChapter);
  const [editedNameOfArticle, setEditedNameOfArticle] = useState(nameOfArticle);
  const [editedHeading, setEditedHeading] = useState(heading);
  const [editedChangeText, setEditedChangeText] = useState(changeText);

  const { t } = useTranslation();

  const handleEditClick = () => setIsEditing(true);
  const handleCancelClick = () => {
    onCancelClick?.();
    setIsEditing(false);
  };

  const handleSaveClick = () => {
    if (editedNameOfArticle.length === 0 || editedChangeText.length === 0) {
      message.warning(t('lawRadar.articleAndTextRequired'));
      return;
    }
    onChangeDetailsChanged(
      editedParentChapter?.trim() ?? '',
      editedNameOfArticle.trim(),
      editedHeading.trim(),
      editedChangeText.trim(),
      state || 'INITIAL',
    );
    setIsEditing(false);
  };

  const handleSaveState = (newState: LawChangeDetailState) => {
    onChangeDetailsChanged(parentChapter ?? '', nameOfArticle, heading, changeText, newState || 'INITIAL');
  };

  const handleMenuClick = (e: any) => {
    handleSaveState(e.key as LawChangeDetailState);
  };

  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="INITIAL">
        <IconCircleDashed style={{ marginTop: 6 }} size={18} />
      </Menu.Item>
      <Menu.Item key="APPROVED">
        <IconCheck style={{ color: 'var(--success-color)', marginTop: 6 }} size={18} />
      </Menu.Item>
      <Menu.Item key="IGNORE">
        <IconEyeOff style={{ color: 'var(--text-light-color)', marginTop: 6 }} size={18} />
      </Menu.Item>
    </Menu>
  );

  const getStateIcon = () => {
    if (state === 'INITIAL') {
      return (
        <Tooltip title={t('lawRadar.changeNotSet')} showArrow={false}>
          <IconCircleDashed size={18} />
        </Tooltip>
      );
    } else if (state === 'APPROVED') {
      return (
        <Tooltip title={t('lawRadar.changeApproved')} showArrow={false}>
          <IconCheck style={{ color: 'var(--success-color)' }} size={18} />
        </Tooltip>
      );
    } else if (state === 'IGNORE') {
      return (
        <Tooltip title={t('lawRadar.changeIgnored')} showArrow={false}>
          <IconEyeOff style={{ color: 'var(--text-light-color)' }} size={18} />
        </Tooltip>
      );
    }
    return null;
  };

  const showEditButtons = !state || state === 'INITIAL';

  console.log('****** isSelectedSource: ', isSelectedSource);

  return (
    <div style={isSelectedSource ? { ...styles.container, ...styles.containerSelected } : styles.container}>
      <div style={styles.header}>
        <NormalText size="m" style={styles.index}>
          {index > 0 ? t('lawRadar.change', { number: index }) : t('lawRadar.addChange')}
        </NormalText>
        {isEditing ? (
          <div>
            <IconX size={16} style={styles.icon} onClick={handleCancelClick} />
            <IconCheck size={16} style={styles.icon} onClick={handleSaveClick} />
          </div>
        ) : (
          <div>
            {showEditButtons && (
              <span style={styles.editIcon} onClick={onDeleteChangeDetails}>
                <IconTrash size={16} />
              </span>
            )}
            {showEditButtons && (
              <span style={styles.editIcon} onClick={handleEditClick}>
                <IconPencil size={16} />
              </span>
            )}
            {showSourceButton && (
              <span style={styles.editIcon} onClick={onShowPdfSourceClick}>
                <IconReportSearch size={16} />
              </span>
            )}
            <span style={styles.stateIcon}>
              <Dropdown overlay={menu} trigger={['click']}>
                <a onClick={(e) => e.preventDefault()}>{getStateIcon()}</a>
              </Dropdown>
            </span>
          </div>
        )}
      </div>
      <div style={styles.content}>
        {isEditing ? (
          <div>
            <Input
              value={editedParentChapter}
              onChange={(e) => setEditedParentChapter(e.target.value)}
              placeholder={t('lawRadar.parentChapter')}
              style={styles.input}
            />
            <Input
              value={editedNameOfArticle}
              onChange={(e) => setEditedNameOfArticle(e.target.value)}
              placeholder={t('lawRadar.articleName')}
              style={styles.input}
            />
            <Input
              value={editedHeading}
              onChange={(e) => setEditedHeading(e.target.value)}
              placeholder={t('lawRadar.articleHeaderText')}
              style={styles.input}
            />
            <TextArea
              value={editedChangeText}
              onChange={(e) => setEditedChangeText(e.target.value)}
              placeholder={t('lawRadar.changeText')}
              style={styles.textarea}
              rows={4}
            />
          </div>
        ) : (
          <div>
            {parentChapter && (
              <NormalText size="m" semiBold colorType="light" bottomMargin="xs">
                {parentChapter}
              </NormalText>
            )}
            <NormalText size="m" semiBold>
              {nameOfArticle} {heading}
            </NormalText>
            <MarkdownContainer text={changeText} isTextLoading={false} forceFonSize="s" />
          </div>
        )}
      </div>
      {editedAt && (
        <div>
          <NormalText size="xs" colorType="light">
            {t('lawRadar.editedOn', { date: moment.unix(editedAt).format('D MMMM, YYYY HH:mm') })}
          </NormalText>
        </div>
      )}
    </div>
  );
};

const styles = {
  container: {
    backgroundColor: 'var(--secondary-background-color)',
    borderRadius: '8px',
    padding: '16px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    margin: '16px 0',
  },
  containerSelected: {
    border: '1px solid var(--primary-color)',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '8px',
  },
  index: {
    color: 'var(--primary-color)',
    fontWeight: 'bold',
  },
  editIcon: {
    cursor: 'pointer',
    color: 'var(--primary-color)',
    marginLeft: '12px',
  },
  icon: {
    cursor: 'pointer',
    color: 'var(--primary-color)',
    marginLeft: '8px',
  },
  stateIcon: {
    cursor: 'pointer',
    marginLeft: '16px',
  },
  content: {
    marginTop: '8px',
  },
  input: {
    marginBottom: '8px',
  },
  textarea: {
    marginBottom: '8px',
  },
};

export default LawChangeDetails;
