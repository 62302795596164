import styled from 'styled-components';
import { Col, Row, Typography } from 'antd';
import { LeftOutlined } from '@ant-design/icons';
import { media } from '@app/styles/themes/constants';

export const MainContainer = styled.div`
  height: calc(100vh - 120px);
  background-color: var(--secondary-background-color);
  @media only screen and ${media.xl} {
    height: calc(100vh - 100px);
  }
`;

export const HeaderContainer = styled.div`
  padding: 12px;
  width: '100%';
  background-color: var(--background-color);
  border-bottom: 2px solid var(--border-color);
  min-height: 70px;
`;

export const HeaderIcon = styled('img')({
  width: '40px',
  height: '40px',
});

export const FooterContainerCreate = styled.div`
  padding: 20px;
  width: 100%;
  background-color: var(--background-color);
  border-top: 2px solid var(--border-color);
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const FooterContainer = styled.div`
  margin: 0 1rem 0 2rem;
  padding: 20px;
  background-color: var(--background-color);
  border: 2px solid var(--border-color);
  border-radius: 0 0 16px 16px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const EditMainColCreate = styled(Col)`
  border-right: 2px solid var(--primary-color);
`;

export const MainRowContainer = styled(Row)`
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
`;

export const EditMainCol = styled(Col)`
  background-color: var(--additional-background-color);
`;

export const EditShadowContainer = styled.div`
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
`;

export const EditContentRootContainer = styled.div`
  margin: 2rem 1rem 0 2rem;
  background-color: var(--additional-background-color);
  max-height: calc(100vh - 220px);
  border: 2px solid var(--border-color);
  border-radius: 16px 16px 0 0;
  border-bottom: 0;
  overflow-y: auto;
`;

export const EditContentContainer = styled.div`
  padding: 40px 20px;
  margin: 0 auto;
  background-color: var(--actions-background-base-color);
  height: auto;
  min-height: 60vh;
`;

export const ResultContainer = styled(Row)`
  //margin-top: 1rem;
  justify-content: space-between;
`;

export const EditorContainer = styled(Col)`
  background-color: var(--additional-background-color);
`;

export const GeneratedContentContainer = styled.div`
  padding: 40px;
  margin: 0 auto 0 0;
  max-width: 1000px;
  height: calc(100vh - 160px);
  min-height: 80vh;
  overflow: auto;
  @media only screen and ${media.xl} {
    height: calc(100vh - 80px);
  }
`;

export const StyledHeaderTextContainer = styled.div`
  line-height: 1.5rem;
  margin-right: 4rem;
`;

export const StyledHeaderTitle = styled(Typography.Text)`
  color: var(--primary-color);
  font-size: 1.125rem;
`;

export const StyledHeaderSubTitle = styled(Typography.Text)`
  color: var(--text-light-color);
  font-size: 0.875rem;
`;

export const ResultHeaderText = styled(Typography.Text)`
  color: var(--primary-color);
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 1rem;
`;

export const StyledGeneratedText = styled(Typography.Text)`
  font-size: 1rem;
  white-space: pre-wrap; /* or pre-line */
`;

export const LoadingContainer = styled(Col)`
  //height: 100%;
  width: 100%;
  display: grid;
  margin-top: 10vh;
  @media only screen and ${media.xl} {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }
`;

export const EmptyInfoText = styled(Typography.Text)`
  font-size: 1rem;
  text-align: center;
  align-items: center;
  padding: 60px;
  padding-top: 100px;
  color: var(--text-light-color);
`;

export const BackLeftIcon = styled(LeftOutlined)`
  color: var(--primary-color);
  width: '60px';
  height: '60px';
  font-size: 1.5rem;
`;
