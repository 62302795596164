import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Button, Col, Form, Modal, Row, Space } from 'antd';
import { IconPencil } from '@tabler/icons-react';
import {
  EditContentContainer,
  EditContentRootContainer,
  EditMainCol,
  EditorContainer,
  EmptyInfoText,
  FooterContainer,
  GeneratedContentContainer,
  HeaderContainer,
  LoadingContainer,
  MainContainer,
  ResultContainer,
  ResultHeaderText,
  StyledHeaderSubTitle,
  StyledHeaderTextContainer,
  StyledHeaderTitle,
} from './GptTemplatesDetail.styles';
import { useParams, useNavigate } from 'react-router-dom';
import GptTemplatesDetailInputControlls from './GptTemplatesDetailInputControlls';
import { notificationController } from '@app/controllers/notificationController';
import { CheckOutlined, CopyOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { useAppDispatch, useAppSelector } from '@app/hooks/reduxHooks';
import { themeObject } from '@app/styles/themes/themeVariables';
import { executeGptQuery, executeGptQueryStream, executeSuperDoAsStream } from '@app/api/gpt.api';
import useSocketMessageHandling from '@app/hooks/useSocketMessageHandling';
import { useTranslation } from 'react-i18next';
import { ChatResponseMessage, SuperDoToolResult } from '@app/types/socketMessageTypes';
import { LocalStoredFile } from '../singleFileUploader/SingleFileUploader';
import GeneratedImageWithFallback from '../common/GeneratedImageWithFallback/GeneratedImageWithFallback';
import { GPT_4o, GPT_MODEL_SELECTION } from '@app/utils/constants';
import { NormalText } from '../common/BaseTexts/BaseTexts';
import { SettingsButtonWrapperWithIcon } from '../gptchatbox/GptChatBoxAnalyze.styles';
import { setTriggerActionId } from '@app/store/slices/chatBoxSlice';
import { LlmIcon } from '../common/icons/LlmIcon';
import { SuperDoTemplate } from '@app/types/superDoTemplateTypes';
import { SuperDoTemplateInputInfo } from './templateDataInputMapping';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import {
  BalloonEditor,
  Bold,
  Essentials,
  Italic,
  Paragraph,
  List,
  Markdown,
  EventInfo,
  Highlight,
  BlockToolbar,
  Indent,
  Heading,
  Table,
  Link,
  Font,
} from 'ckeditor5';
import 'ckeditor5/ckeditor5.css';
import './ckeditor-custom.css';
import 'react-loading-skeleton/dist/skeleton.css';
import SuperDoSendMessage from '../gptchatbox/SuperDoSendMessage';
import BackButton from '../common/buttons/Button/BackButton';
import LlmSuperDoSettingsModal from '../gptchatbox/LlmSuperDoSettingsModal';
import { setGptSuperDoLLM } from '@app/store/slices/settingsSlice';
import { useErrorHandling } from '@app/hooks/useErrorHandling';

const GptTemplatesDetail: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const theme = useAppSelector((state) => state.theme.theme);
  const { superDoAreas } = useAppSelector((state) => state.superDoAreas);
  const user = useAppSelector((state) => state.user.user);
  const { gptSuperDoLLM, customSuperDoSystemPrompt } = useAppSelector((state) => state.settings);
  const primaryColor = themeObject[theme].primary;

  const [genereatedText, setGenereatedText] = useState<string>('');
  const [textIsLoading, setTextIsLoading] = useState<boolean>(false);
  const [textIsRegenerating, setTextIsRegenerating] = useState<boolean>(false);
  const [isCopied, setIsCopied] = useState<boolean>(false);

  const [filesToUpload, setFilesToUpload] = useState<LocalStoredFile[]>([]);
  const [imageUrls, setImageUrls] = useState<string[]>([]);

  const ckEditor = useRef<BalloonEditor | null>(null);

  const abortControllerRef = useRef<AbortController | null>(null);

  const { handleApiError } = useErrorHandling();

  useEffect(() => {
    if (!gptSuperDoLLM) {
      dispatch(setGptSuperDoLLM({ value: GPT_4o }));
    }
  }, [gptSuperDoLLM, dispatch]);

  // const [selectionState, setSelectionState] = useState({
  //   text: '',
  //   position: null,
  // });

  const handleEditorChange = (event: EventInfo, editor: BalloonEditor) => {
    if (!textIsLoading && !textIsRegenerating) {
      const data = editor.getData();
      setGenereatedText(data);

      // const selection = editor.model.document.selection;

      // const selectedText = selection.getSelectedElement();
      // const position = selection.getFirstPosition();

      // setTimeout(() => {
      //   if (ckEditor.current) {
      //     const model = ckEditor.current.model;
      //     const doc = model.document;

      //     model.change((writer) => {
      //       const insertPosition = doc.selection.getFirstPosition();
      //       writer.insertText('Test ' + data, { highlight: 'yellowMarker' }, insertPosition || undefined);
      //     });
      //   }
      // }, 2000);

      //editor.execute('highlight', { value: 'yellowMarker' });

      // setSelectionState({
      //   text: selectedText,
      //   position: position,
      // });
    }
  };

  const handleMessageFromSocket = (value: ChatResponseMessage | SuperDoToolResult): void => {
    const { messageType } = value;
    if (messageType === 'NEW_TOKEN' && 'token' in value) {
      const { token } = value;
      setGenereatedText((text) => text + token);
    } else if (messageType === 'STREAM_DONE') {
      setTextIsLoading(false);
      setTextIsRegenerating(false);
    } else if (messageType === 'SUPER_DO_TOOL_RESULT' && 'results' in value) {
      const { results, errorMessage } = value;
      setImageUrls(results);
      if (errorMessage) {
        notificationController.error({ message: `Error when creating the image: ${errorMessage}` });
      }
    }
  };

  const { socketChanelId, socketIO } = useSocketMessageHandling(handleMessageFromSocket);

  const { t } = useTranslation();

  const copyToClipboard = () => {
    if (!navigator.clipboard || !navigator.clipboard.writeText) {
      return;
    }

    navigator.clipboard.writeText(genereatedText).then(() => {
      setIsCopied(true);

      setTimeout(() => {
        setIsCopied(false);
      }, 2000);
    });
  };

  const scrollRef = useRef<HTMLDivElement>(null);

  const [form] = Form.useForm();

  const { templateId } = useParams();

  const { superDoItem, superDoInputs } = useMemo(() => {
    let superDoItem: SuperDoTemplate | undefined;
    let superDoInputs: SuperDoTemplateInputInfo[] = [];

    superDoAreas.every((area) => {
      superDoItem = area.templates.find((template) => template._id === templateId);
      if (superDoItem) {
        superDoInputs = superDoItem.elements;
        return false;
      }
      return true;
    });

    return { superDoItem, superDoInputs };
  }, [templateId]);

  const onGenerateGptText = () => {
    const sendToGptApi = async () => {
      if (superDoItem) {
        let templateText = superDoItem?.promptTemplate;
        type FieldInputId = { inputId: string; value: string };
        type FileInputId = { fileName: string; inputId: string };

        const parameters: FieldInputId[] = [];
        const fileInputIds: FileInputId[] = [];

        superDoItem.elements.forEach((input) => {
          if (input.type === 'FILE_UPLOAD') {
            const foundFile = filesToUpload.find((item) => item.inputId === input.inputId);
            foundFile &&
              fileInputIds.push({ inputId: input.inputId, fileName: encodeURIComponent(foundFile.name ?? '-') });
          } else {
            templateText = templateText.replace(input.inputId, form.getFieldValue(input.inputId));
            parameters.push({ inputId: input.inputId, value: form.getFieldValue(input.inputId) });
          }
        });

        const documentParseStrategy = superDoItem.documentParseStrategy;

        try {
          setTextIsLoading(true);

          const formData = new FormData();
          filesToUpload.forEach((file) => {
            formData.append('files', file.originFileObj as Blob, encodeURIComponent(file.name));
          });

          formData.append(
            `queryGptData`,
            JSON.stringify({
              queryText: templateText,
              parameters,
              fileInputIds,
              chatId: socketChanelId.current,
              enableHistory: false,
              aiToolsToUse: superDoItem.aiToolsToUse || [],
              aiModelName: gptSuperDoLLM,
              documentParseStrategy,
              customSystemPrompt:
                customSuperDoSystemPrompt === 'default' ? undefined : customSuperDoSystemPrompt?.trim(),
            }),
          );
          abortControllerRef.current = new AbortController();

          await executeSuperDoAsStream(formData, abortControllerRef.current.signal);
          return;
        } catch (error) {
          handleApiError(error);
          setTextIsLoading(false);
        }
      }
    };

    if (genereatedText.length) {
      Modal.confirm({
        title: t('regenerateText'),
        icon: <ExclamationCircleOutlined />,
        content: t('regenerateTextAreYouSure'),
        okText: t('yes'),
        okType: 'danger',
        cancelText: t('no'),
        onOk() {
          setGenereatedText('');
          setImageUrls([]);
          scrollRef.current?.scrollIntoView({ behavior: 'smooth' });
          sendToGptApi();
        },
        onCancel() {
          console.log('Cancel');
        },
      });
    } else {
      setGenereatedText('');
      setImageUrls([]);
      scrollRef.current?.scrollIntoView({ behavior: 'smooth' });
      sendToGptApi();
    }
  };

  const handleOnSendChatMessage = async (message: string) => {
    try {
      setTextIsRegenerating(true);

      const result = await executeGptQuery({
        queryText: `Du bist ein KI-Assistent zum Klassifizieren von Anweisungen.
Abhängig von der Anweisung gibst du entweder EXTEND_TEXT oder REWRITE_TEXT zurück.

Beispiel:
Anweisung: Erweitere den Text um diese zwei Kapitel "Punkt 1", "Punkt 2"
KI: EXTEND_TEXT

Anweisung: Mache den Text viel kürzer
KI: REWRITE_TEXT

Anweisung: Fassen den Text zusammen
KI: REWRITE_TEXT

Anweisung: Passen das Kapitel Fazit so an, dass freundlicher klingt
KI: REWRITE_TEXT

Anweisung: Erweitere den Text um den Abschnitt Fazit
KI: EXTEND_TEXT

Anweisung: Mache das Kapitel "über das Unternehmen" etwas kürzer
KI: REWRITE_TEXT

Beginne!

Anweisung: ${message}
KI:`,
        aiModelName: 'gpt-3.5-turbo',
      });

      const rewriteText = result.includes('REWRITE_TEXT');
      if (rewriteText) {
        setGenereatedText('');
      } else {
        setGenereatedText(genereatedText + '\n\n');
      }
      abortControllerRef.current = new AbortController();

      await executeGptQueryStream(
        {
          queryText: `Du bist ein hilfreicher KI-Assistent für Unternehmen.
Hier ist ein Text, der zuvor durch ein LLM erstellt wurde: ${genereatedText}
${rewriteText ? 'Analysiere den erstellten Text und passe diesen wie folgt an:' : 'Analysiere den erstellten Text und folge der Anweisung des Benutzers und gib dabei nur den Text aus, der im vorherigen Text nicht vorkommt:'} ${message}`,
          aiModelName: gptSuperDoLLM,
          chatId: socketChanelId.current,
          enableHistory: false,
        },
        abortControllerRef.current.signal,
      );
    } catch (error) {
      notificationController.error({
        message: 'Das hat leider nicht geklappt. Bitte wiederhole die Anfrage erneut.',
      });
    }
  };

  const handleOnStopMessageStream = () => {
    socketIO.current?.emit('request-abort-message-stream', { userId: user?._id, chatId: socketChanelId.current });
    abortControllerRef.current?.abort();
  };

  const onResetFields = () => {
    form.resetFields();
  };

  const handleEditTemplate = (id?: string) => {
    const superDoArea = superDoAreas.find((superDoArea) =>
      superDoArea.templates.some((template) => template._id === id),
    );
    const superDoTemplate = superDoArea && superDoArea.templates.find((template) => template._id === templateId);

    if (superDoTemplate) {
      navigate('/zen/superdo-create', { state: { mode: 'edit', template: superDoTemplate } });
    }
  };

  const handleFileUpload = (file: LocalStoredFile, inputId: string): void => {
    setFilesToUpload([...filesToUpload, { ...file, inputId }]);
  };

  const handleFileRemove = (uid: string): void => {
    const filesToSave = filesToUpload.filter((file) => file.uid !== uid);
    setFilesToUpload(filesToSave);
  };

  const getModelName = () => GPT_MODEL_SELECTION.find((m) => m.value === gptSuperDoLLM)?.label ?? '';

  if (!templateId) return null;

  const textWasGenerated = genereatedText?.length > 0;

  const SkeletonAnswerText = () => (
    <SkeletonTheme
      baseColor="var(--skeleton-background-color)"
      highlightColor="var(--secondary-background-selected-color)"
    >
      <Skeleton count={5} style={{ height: 22, width: '100%' }} />
    </SkeletonTheme>
  );

  return (
    <MainContainer>
      <HeaderContainer>
        <Row align={'top'} justify={'space-between'}>
          <Col span={18}>
            <Space size={'middle'} align="center">
              <BackButton marginLeft="1rem" />
              <StyledHeaderTextContainer>
                <StyledHeaderTitle>{superDoItem?.title}</StyledHeaderTitle>
                <br />
                <StyledHeaderSubTitle>{superDoItem?.description}</StyledHeaderSubTitle>
              </StyledHeaderTextContainer>
            </Space>
          </Col>
          <Col span={6}>
            <Row align={'middle'} justify={'end'}>
              <SettingsButtonWrapperWithIcon
                icon={<LlmIcon style={{ marginTop: '0.25rem', marginRight: '0.25rem' }} />}
                onClick={() => dispatch(setTriggerActionId({ value: 'SHOW_LLM_SETTINGS_MODAL' }))}
              >
                <NormalText size="xs" colorType="primary" style={{ marginRight: '0.75rem' }}>
                  {getModelName()}
                </NormalText>
              </SettingsButtonWrapperWithIcon>
            </Row>
          </Col>
        </Row>
      </HeaderContainer>
      <Row>
        <EditMainCol xs={24} md={10} lg={8} xl={7}>
          <EditContentRootContainer>
            <EditContentContainer>
              <GptTemplatesDetailInputControlls
                superDoInputs={superDoInputs}
                form={form}
                handleFileUpload={handleFileUpload}
                handleFileRemove={handleFileRemove}
                uploadedFiles={filesToUpload}
              />
              {(user?.role === 'ADMIN' || user?._id === superDoItem?.createdBy) && (
                <Button
                  type="link"
                  onClick={() => handleEditTemplate(superDoItem?._id)}
                  icon={<IconPencil size={18} style={{ verticalAlign: 'text-top', marginRight: '0.2rem' }} />}
                >
                  {t('editThisSuperDo')}
                </Button>
              )}
            </EditContentContainer>
          </EditContentRootContainer>
          <FooterContainer>
            {superDoInputs && (
              <Row justify={'space-between'} style={{ width: '100%' }}>
                <Button type="dashed" onClick={() => onResetFields()} size="small">
                  {t('resetAll')}
                </Button>
                <Button type="primary" onClick={() => onGenerateGptText()} size="small">
                  {textWasGenerated ? t('regenerateText') : t('generateText')}
                </Button>
              </Row>
            )}
          </FooterContainer>
        </EditMainCol>
        <EditorContainer xs={24} md={14} lg={16} xl={17}>
          <GeneratedContentContainer ref={scrollRef}>
            {(textWasGenerated || textIsLoading) && (
              <ResultContainer>
                <ResultHeaderText>{t('common.result')}</ResultHeaderText>
                {!textIsLoading && (
                  <Row justify={'space-around'}>
                    <Button type="text" onClick={copyToClipboard}>
                      {isCopied ? (
                        <CheckOutlined style={{ fontSize: '1rem', color: primaryColor }} />
                      ) : (
                        <CopyOutlined style={{ fontSize: '1rem', color: primaryColor }} />
                      )}
                    </Button>
                  </Row>
                )}
              </ResultContainer>
            )}
            {(!genereatedText || genereatedText.length === 0) && textIsLoading && <SkeletonAnswerText />}
            {(genereatedText || textIsRegenerating) && (
              <CKEditor
                editor={BalloonEditor}
                config={{
                  plugins: [
                    Essentials,
                    Indent,
                    Bold,
                    Heading,
                    BlockToolbar,
                    Italic,
                    Paragraph,
                    List,
                    Markdown,
                    Highlight,
                    Table,
                    Link,
                    Font,
                  ],
                  toolbar: ['undo', 'redo', '|', 'heading', 'bold', 'italic', '|', 'numberedList', 'bulletedList'],
                  language: 'de',
                  placeholder: t('aiEditorEmptyPlaceholder'),
                  blockToolbar: ['undo', 'redo', '|', 'heading', 'bulletedList', 'numberedList'],
                }}
                data={genereatedText}
                onReady={(editor) => {
                  ckEditor.current = editor;
                }}
                onChange={handleEditorChange}
              />
            )}
            {!genereatedText && !textIsLoading && !textIsRegenerating && (
              <LoadingContainer>
                <EmptyInfoText>{t('generateTextEmptyTextHint')}</EmptyInfoText>
              </LoadingContainer>
            )}
            {genereatedText && <div style={{ height: '1rem' }} />}
            {genereatedText && (
              <SuperDoSendMessage
                messageIsProcessing={textIsLoading || textIsRegenerating}
                onSendMessage={handleOnSendChatMessage}
                handleOnStop={handleOnStopMessageStream}
              />
            )}
            {genereatedText && <div style={{ height: '40px' }} />}
            {imageUrls && imageUrls.length > 0 && <div style={{ height: 20 }} />}
            {imageUrls &&
              imageUrls.map((imageUrl) => {
                return <GeneratedImageWithFallback key={imageUrl} src={imageUrl} small />;
              })}
          </GeneratedContentContainer>
        </EditorContainer>
      </Row>
      <LlmSuperDoSettingsModal />
    </MainContainer>
  );
};

export default GptTemplatesDetail;
