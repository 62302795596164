import React from 'react';
import { MemoizedReactMarkdown } from '@app/components/common/Markdown/MemoizedReactMarkdown';
import supersub from 'remark-supersub';
import remarkGfm from 'remark-gfm';
import remarkMath from 'remark-math';
import rehypeMathJaxSvg from 'rehype-mathjax';
import {
  StyledBlockquote,
  StyledInlineP,
  StyledLi,
  StyledOl,
  StyledP,
  StyledTableFixedWidth,
  StyledTd,
  StyledTh,
  StyledUl,
} from '@app/components/gptchatbox/Message.styles';
import { CodeBlock } from './CodeBlock';
import { getFontSize } from '../BaseTexts/BaseTexts';

export type MarkdownContainerProps = {
  text: string;
  isTextLoading?: boolean;
  forceFonSize?: 'xs' | 's' | 'm' | 'l' | 'xl' | 'xxl' | 'xxxl';
};

const MarkdownContainer: React.FC<MarkdownContainerProps> = ({ text, isTextLoading, forceFonSize }) => {
  return (
    <MemoizedReactMarkdown
      className="prose dark:prose-invert flex-1"
      remarkPlugins={[remarkGfm, remarkMath, supersub]}
      rehypePlugins={[rehypeMathJaxSvg]}
      components={{
        code({ className, children, ...props }) {
          const childrenAny = children as any;

          if (childrenAny && childrenAny.length) {
            if (childrenAny[0] == '▍') {
              return <span className="animate-pulse-cursor">▍</span>;
            }
          }

          const match = /language-(\w+)/.exec(className || '');

          if (!match) return <code>{children}</code>;

          return (
            <CodeBlock
              key={Math.random()}
              language={(match && match[1]) || ''}
              value={String(children).replace(/\n$/, '')}
              {...props}
            />
          );
        },
        table({ children }) {
          return <StyledTableFixedWidth>{children}</StyledTableFixedWidth>;
        },
        th({ children }) {
          return <StyledTh fontSize={forceFonSize ? getFontSize(false, forceFonSize) : undefined}>{children}</StyledTh>;
        },
        td({ children }) {
          return <StyledTd fontSize={forceFonSize ? getFontSize(false, forceFonSize) : undefined}>{children}</StyledTd>;
        },
        ul({ children }) {
          return <StyledUl fontSize={forceFonSize ? getFontSize(false, forceFonSize) : undefined}>{children}</StyledUl>;
        },
        ol({ children }) {
          return <StyledOl fontSize={forceFonSize ? getFontSize(false, forceFonSize) : undefined}>{children}</StyledOl>;
        },
        li({ children }) {
          return <StyledLi fontSize={forceFonSize ? getFontSize(false, forceFonSize) : undefined}>{children}</StyledLi>;
        },
        p({ children }) {
          return <StyledP size={forceFonSize || 'm'}>{children}</StyledP>;
        },
        h1({ children }) {
          return (
            <StyledP size={forceFonSize || 'xxxl'} semiBold>
              {children}
            </StyledP>
          );
        },
        h2({ children }) {
          return (
            <StyledP size={forceFonSize || 'xxl'} semiBold>
              {children}
            </StyledP>
          );
        },
        h3({ children }) {
          return (
            <StyledP size={forceFonSize || 'xl'} semiBold>
              {children}
            </StyledP>
          );
        },
        h4({ children }) {
          return (
            <StyledP size={forceFonSize || 'l'} semiBold>
              {children}
            </StyledP>
          );
        },
        h5({ children }) {
          return (
            <StyledP size={forceFonSize || 'm'} semiBold>
              {children}
            </StyledP>
          );
        },
        blockquote({ children }) {
          return <StyledBlockquote>{children}</StyledBlockquote>;
        },
        strong({ children }) {
          return (
            <StyledInlineP size={forceFonSize || 'm'} bold>
              {children}
            </StyledInlineP>
          );
        },
        b({ children }) {
          return (
            <StyledInlineP size={forceFonSize || 'm'} bold>
              {children}
            </StyledInlineP>
          );
        },
        br() {
          return <br />;
        },
      }}
    >
      {`${text} ${isTextLoading ? '`▍`' : ''}`}
    </MemoizedReactMarkdown>
  );
};

export default MarkdownContainer;
