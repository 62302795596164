import { sendGetRequest, sendPostRequest, sendDeleteRequest } from '@app/api/http.api';
import { ILawChangeReport, ILawReportGenerateOptions } from '@app/types/lawTypes';

//
export const readAllLawChangeReports = (companyId: string, lawId: string): Promise<ILawChangeReport[]> => {
  return sendGetRequest<ILawChangeReport[]>(`/api/v1/lawChangeReport/readAll/${companyId}/${lawId}`);
};

//
export const getLawChangeReport = (id: string): Promise<ILawChangeReport> => {
  return sendGetRequest<ILawChangeReport>(`/api/v1/lawChangeReport/read/${id}`);
};

//
export const createLawChangeReport = (changeReport: ILawChangeReport): Promise<string> => {
  return sendPostRequest<{ changeReport: ILawChangeReport }, string>('/api/v1/lawChangeReport/create', {
    changeReport,
  });
};

//
export const updateLawChangeReport = (id: string, updatedChangeReport: Partial<ILawChangeReport>): Promise<boolean> => {
  return sendPostRequest<{ updatedChangeReport: Partial<ILawChangeReport> }, boolean>(
    `/api/v1/lawChangeReport/update/${id}`,
    {
      updatedChangeReport,
    },
  );
};

//
export const createLawChangeSummaryReport = (id: string, options: ILawReportGenerateOptions): Promise<string> => {
  return sendPostRequest<{ options: ILawReportGenerateOptions }, string>(
    `/api/v1/lawChangeReport/createSummaryReport/${id}`,
    { options },
  );
};

//
export const deleteLawChangeReport = (id: string): Promise<void> => {
  return sendDeleteRequest<void>(`/api/v1/lawChangeReport/delete/${id}`);
};
