import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppSelector, useAppDispatch } from '@app/hooks/reduxHooks';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { Button, Col, Row, Form, Modal } from 'antd';
import { v4 as uuidv4 } from 'uuid';
import { uniqBy, countBy, pickBy, isEmpty } from 'lodash';
import type { TabsProps } from 'antd';
import { notificationController } from '@app/controllers/notificationController';
import { EditMainColCreate, FooterContainerCreate, MainContainer } from './GptTemplatesDetail.styles';
import { LayoutContainer, HeaderContainer, StyledTabs } from './GPTTemplateCreate.styles';
import { SuperDoLayoutConstructor } from './CreateTemplate/SuperDoLayoutConstructor';
import { SuperDoConstructor } from './CreateTemplate/SuperDoConstructor';
import { SuperDoLayoutPreview } from './CreateTemplate/SuperDoLayoutPreview';
import { SuperDoTemplateInputInfo, SuperDoInputElementType } from './templateDataInputMapping';
import { createSuperDoTemplate, updateSuperDoTemplate } from '@app/api/superDoTemplate.api';
import { loadSuperDoAreas } from '@app/store/slices/superDoAreaSlice';
import { ModalCreateAiConstructor } from './CreateTemplate/ModalCreateAiConstructor';
import { SuperDoAiTool, SuperDoTemplate } from '@app/types/superDoTemplateTypes';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import BackButton from '../common/buttons/Button/BackButton';
import { DocumentParseStrategy } from '@app/domain/KnowledgeAreaModel';

export interface InputElement extends SuperDoTemplateInputInfo {
  _id?: string;
}

export type SuperDoFormValuesProps = {
  title: string;
  description: string;
  promptTemplate: string;
  areaId: string;
  elements: InputElement[];
  aiToolsToUse: string[];
  documentParseStrategy?: DocumentParseStrategy;
};

const GptTemplateCreate: React.FC = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  const [form] = Form.useForm();
  const companyState = useAppSelector((state) => state.company);
  const { superDoAreas } = useAppSelector((state) => state.superDoAreas);
  const user = useAppSelector((state) => state.user.user);

  const dispatch = useAppDispatch();

  const [activeKey, setActiveKey] = useState('1');
  const [numberElements, setNumberElements] = useState(0);
  const [inputIds, setInputIds] = useState<string[]>([]);
  const [promptValue, setPromptValue] = useState<string>('');
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [wasChanged, setWasChanged] = useState<boolean>(false);

  const scrollRef = useRef<HTMLDivElement>(null);

  const handleIsModalVisible = (state: boolean) => {
    setIsModalVisible(state);
  };

  const setGeneratedFields = (valuesToSet: SuperDoFormValuesProps) => {
    form.setFieldsValue({ ...valuesToSet });
    setPromptValue(valuesToSet.promptTemplate);
    setNumberElements(valuesToSet.elements.length);
    setInputIds(valuesToSet.elements.map((element) => element.inputId));
  };

  useEffect(() => {
    const mode = location.state?.mode;
    const template: SuperDoTemplate = location.state?.template;
    if (mode === 'edit' && template) {
      const valueToSet: SuperDoFormValuesProps = {
        title: template.title,
        description: template.description ?? '',
        promptTemplate: template.promptTemplate,
        areaId: template.areaId,
        elements: template.elements,
        aiToolsToUse: template.aiToolsToUse?.map((item) => item.name) || [],
        documentParseStrategy: template.documentParseStrategy,
      };
      setGeneratedFields(valueToSet);
    }
  }, [location, form]);

  const addInputElement = (type: SuperDoInputElementType) => {
    const elements = form.getFieldValue('elements');
    const newElement: InputElement = {
      type,
      inputTitle: '',
      defaultValue: '',
      defaultSelectionValues: [],
      inputId: '',
      _id: uuidv4(),
    };
    setNumberElements(elements.length + 1);
    form.setFieldValue('elements', [...elements, newElement]);
    setTimeout(() => {
      scrollRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, 0);
  };

  const addInputIdToThePrompt = (inputId: string) => {
    const promptToSave = promptValue + ` ${inputId}`;
    form.setFieldValue('promptTemplate', promptToSave);
    setPromptValue(promptToSave);
  };

  const updateInputIds = () => {
    const elements = form.getFieldValue('elements');
    if (elements) {
      const inputIds = elements.map((element: InputElement) => element.inputId);
      setInputIds(inputIds);
    }
  };

  const fileUpladerPresent = useMemo(() => {
    const elements = form.getFieldValue('elements');
    if (elements) {
      return elements.find((element: InputElement) => element.type === 'FILE_UPLOAD');
    }
    return false;
  }, [numberElements]);

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: t('createSuperDo.designer'),
      children: (
        <LayoutContainer>
          <SuperDoLayoutConstructor
            addInputElement={addInputElement}
            setElements={form.setFieldValue}
            getFieldValue={form.getFieldValue}
            getElements={() => form.getFieldValue('elements')}
            numberElements={numberElements}
            updateInputIds={updateInputIds}
          />
          <div ref={scrollRef} />
        </LayoutContainer>
      ),
    },
    {
      key: '2',
      label: t('createSuperDo.preview'),
      children: (
        <LayoutContainer>
          <SuperDoLayoutPreview elements={form.getFieldValue('elements')} />
        </LayoutContainer>
      ),
    },
  ];

  const onTabSelect = (key: string) => {
    setActiveKey(key);
  };

  const validateInputIds = (elements: InputElement[]): boolean => {
    const elementsWithUniqIds = uniqBy(elements, 'inputId');
    const isInputIdsContainSpaces = elements.some((element) => element.inputId.trim().includes(' '));

    return elements.length === elementsWithUniqIds.length && !isInputIdsContainSpaces;
  };

  const setErrorsForElements = (elements: InputElement[]): void => {
    const repeatedIds = pickBy(countBy(elements, 'inputId'), (count) => count > 1);

    const defaultErrors: { name: (string | number)[]; errors: string[] }[] = [];

    const errors = elements.reduce((acc, element, index) => {
      const isInputIdContainSpaces = element.inputId.trim().includes(' ');

      if (!repeatedIds[element.inputId] && !isInputIdContainSpaces) {
        return acc;
      }

      return [
        ...acc,
        {
          name: ['elements', index, 'inputId'],
          errors: [
            isInputIdContainSpaces
              ? t('createSuperDo.errors.elementIdContainSpaces')
              : t('createSuperDo.errors.doubledElementId'),
          ],
        },
      ];
    }, defaultErrors);

    form.setFields(errors);
  };

  const onSaveSuperDo = async (values: SuperDoFormValuesProps) => {
    if (isEmpty(values.elements)) {
      notificationController.error({ message: t('createSuperDo.errors.elementsEmpty') });
      return;
    }

    const elementsToSave = values.elements.map((element) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { _id, ...rest } = element;
      return rest;
    });

    const aiToolsToUse: SuperDoAiTool[] = values.aiToolsToUse.map((element) => {
      return { name: element, parameters: [] } as SuperDoAiTool;
    });

    const valueToSave = {
      companyId: companyState._id ?? '',
      createdBy: user?._id ?? '',
      ...values,
      elements: elementsToSave,
      aiToolsToUse,
    };

    const isValidInputIds = validateInputIds(values.elements);

    if (isValidInputIds) {
      if (location.state?.mode === 'edit') {
        const templateId = location.state?.template?._id;
        await updateSuperDoTemplate(templateId ?? '', valueToSave);
      } else {
        await createSuperDoTemplate(valueToSave);
      }
      const successMessage =
        location.state?.mode === 'edit'
          ? t('createSuperDo.superDoTemplateUpdatedSuccessfully', {
              title: values.title,
            })
          : t('createSuperDo.superDoTemplateCreatedSuccessfully', {
              title: values.title,
            });
      notificationController.success({ message: successMessage });
      await dispatch(loadSuperDoAreas());
      setWasChanged(false);
    } else {
      setErrorsForElements(values.elements);
    }
  };

  const handleInputIdsChange = (changedValues: Partial<SuperDoFormValuesProps>, allValues: SuperDoFormValuesProps) => {
    if (changedValues.elements) {
      const inputIds = allValues.elements.map((element) => element.inputId);
      setInputIds(inputIds);
      setNumberElements(changedValues.elements.length);
    } else if (changedValues.promptTemplate) {
      setPromptValue(changedValues.promptTemplate);
    }
    setWasChanged(true);
  };

  const onGoBack = () => {
    if (!wasChanged) {
      navigate('/superdos');
    } else {
      Modal.confirm({
        title: t('createSuperDo.notSavedChanges'),
        icon: <ExclamationCircleOutlined />,
        content: t('createSuperDo.notSavedChangesAsk'),
        okText: t('yes'),
        okType: 'danger',
        cancelText: t('no'),
        onOk() {
          navigate('/superdos');
        },
        onCancel() {
          console.log('Cancel');
        },
      });
    }
  };

  const initialValues: SuperDoFormValuesProps = {
    title: '',
    description: '',
    promptTemplate: '',
    areaId: '',
    elements: [],
    aiToolsToUse: [],
  };

  return (
    <Form form={form} onFinish={onSaveSuperDo} initialValues={initialValues} onValuesChange={handleInputIdsChange}>
      <MainContainer>
        <Row>
          <EditMainColCreate span={12}>
            <HeaderContainer>
              <Row>
                <BackButton onGoBack={onGoBack} style={{ position: 'relative', top: '12px', zIndex: 10 }} />
              </Row>
            </HeaderContainer>
            <StyledTabs activeKey={activeKey} defaultActiveKey="1" items={items} onChange={onTabSelect} centered />
            <FooterContainerCreate>
              <Row>
                <Col flex={'auto'}></Col>
                <Col>
                  <Button type="primary" htmlType="submit" size="middle">
                    {t('createSuperDo.save')}
                  </Button>
                </Col>
              </Row>
            </FooterContainerCreate>
          </EditMainColCreate>
          <Col span={12}>
            <SuperDoConstructor
              inputIds={inputIds}
              promptValue={promptValue}
              superDoAreas={superDoAreas}
              showDocumentParseStrategy={fileUpladerPresent}
              handleIsModalVisible={handleIsModalVisible}
              addInputIdToThePrompt={addInputIdToThePrompt}
            />
          </Col>
        </Row>
      </MainContainer>
      <ModalCreateAiConstructor
        isModalVisible={isModalVisible}
        closeModal={() => setIsModalVisible(false)}
        setGeneratedFields={setGeneratedFields}
      />
    </Form>
  );
};

export default GptTemplateCreate;
