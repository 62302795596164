import { sendGetRequest, sendPostRequest, sendDeleteRequest } from '@app/api/http.api';
import { ILawEntry, LawEntryAutoImport, LawSource } from '@app/types/lawTypes';

// Function to read all law entries for a company
export const readAllLawEntries = (companyId: string, withoutChanges?: boolean): Promise<ILawEntry[]> => {
  return sendGetRequest<ILawEntry[]>(`/api/v1/lawEntries/readAll/${companyId}/${withoutChanges ? 1 : 0}`);
};

// Function to get a law entry by ID
export const getLawEntry = (id: string): Promise<ILawEntry> => {
  return sendGetRequest<ILawEntry>(`/api/v1/lawEntries/${id}`);
};

//
export const createTempPdfUrl = (url: string): Promise<string> => {
  return sendPostRequest<{ url: string }, string>(`/api/v1/lawEntries/createTempPdfUrl/`, {
    url,
  });
};

// Function to create a new law entry
export const createLawEntry = (lawEntry: ILawEntry): Promise<string> => {
  return sendPostRequest<{ lawEntry: ILawEntry }, string>('/api/v1/lawEntries/create', { lawEntry });
};

// Function to create a new law entry
export const autoImportLawEntry = (lawUrl: string, source: LawSource): Promise<LawEntryAutoImport> => {
  return sendPostRequest<{ lawUrl: string; source: LawSource }, LawEntryAutoImport>('/api/v1/lawEntries/autoImport', {
    lawUrl,
    source,
  });
};

// Function to recreate the summary of changes
export const recreateChangesSummary = (lawEntryId: string, changeTitle: string): Promise<ILawEntry> => {
  return sendPostRequest<{ lawEntryId: string; changeTitle: string }, ILawEntry>(
    '/api/v1/lawEntries/recreateChangesSummary',
    {
      lawEntryId,
      changeTitle,
    },
  );
};

// Function to recreate the diff summary of changes
export const recreateChangesDiffSummary = (lawEntryId: string, changeTitle: string): Promise<ILawEntry> => {
  return sendPostRequest<{ lawEntryId: string; changeTitle: string }, ILawEntry>(
    '/api/v1/lawEntries/recreateChangesDiffSummary',
    {
      lawEntryId,
      changeTitle,
    },
  );
};

// Function to update a law entry by ID
export const updateLawEntry = (id: string, updatedLawEntry: Partial<ILawEntry>): Promise<boolean> => {
  return sendPostRequest<{ updatedLawEntry: Partial<ILawEntry> }, boolean>(`/api/v1/lawEntries/update/${id}`, {
    updatedLawEntry,
  });
};

// Function to start ingest a law entry by ID
export const startFullIngestLawEntry = (id: string, socketChanelId?: string): Promise<ILawEntry> => {
  return sendPostRequest<{ socketChanelId?: string }, ILawEntry>(`/api/v1/lawEntries/startIngest/${id}`, {
    socketChanelId,
  });
};

// Function to start ingest a specific law change entry by ID
export const startIngestLawChanges = (id: string, changeId: string, socketChanelId?: string): Promise<ILawEntry> => {
  return sendPostRequest<{ socketChanelId?: string }, ILawEntry>(
    `/api/v1/lawEntries/startIngestChange/${id}/${changeId}`,
    { socketChanelId },
  );
};

// Function to delete a law entry by ID
export const deleteLawEntry = (id: string): Promise<void> => {
  return sendDeleteRequest<void>(`/api/v1/lawEntries/${id}`);
};
