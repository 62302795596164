import React, { useEffect, useMemo, useState } from 'react';
import { Button, Col, Form, Modal, Row, Space, Table, message, DatePicker, Dropdown, Menu } from 'antd';
import type { DatePickerProps } from 'antd';
import type { ColumnType } from 'antd/es/table';
import * as S from './KnowledgeFiles.styles';
import { notificationController } from '@app/controllers/notificationController';
import moment from 'moment';
import { AdditionalDocumentFields, KnowledgeAreaModel } from '@app/domain/KnowledgeAreaModel';
import { debounce } from 'lodash';
import { NormalText } from '../common/BaseTexts/BaseTexts';
import { FileUploader } from '../fileUploader/FileUploader';
import {
  DeleteOutlined,
  DownloadOutlined,
  EditOutlined,
  InfoCircleOutlined,
  LoadingOutlined,
  MoreOutlined,
  ReloadOutlined,
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useHandleMultipleFileUploads } from '@app/hooks/useHandleMultipleFileUploads';
import { BaseInput } from '../common/inputs/BaseInput/BaseInput';
import PdfViewer from '../gptchatbox/PdfViewer';
import { IUploadedDocument } from '@app/domain/UploadedDocumentModel';
import { SearchInput } from '../common/inputs/SearchInput/SearchInput.styles';
import EditableDate from '../common/EditableDate/EditableDate';
import EditableText from '../common/EditableText/EditableText';

type KnowledgeFilesProps = {
  selectedKnowledgeArea?: KnowledgeAreaModel;
  onTriggerShowAdditionalDocumentFields?: (selectedKnowledgeAreaId: string, fields: AdditionalDocumentFields) => void;
};

type FileInfo = {
  title: string;
  url: string;
};

const MaxKnowledgeFilesPerAreas = Number.parseInt(process.env.REACT_APP_MAX_FILES_PER_KNOWLEDGE_AREAS ?? '') || 100;

export const KnowledgeFiles: React.FC<KnowledgeFilesProps> = ({
  selectedKnowledgeArea,
  onTriggerShowAdditionalDocumentFields,
}) => {
  const { t } = useTranslation();

  const [showPDFViewer, setShowPDFViewer] = useState(false);
  const [showValidityPeriodFields, setShowValidityPeriodFields] = useState(false);

  const [toEditFileId, setToEditFileId] = useState<string | null>(null);
  const [toShowFile, setToShowFile] = useState<FileInfo | null>(null);

  const [form] = Form.useForm();

  const [searchText, setSearchText] = useState('');

  // Use the custom hook for handling multiple file uploads
  const {
    alreadyUploadedFiles,
    onNewFileUploaded,
    readAllFiles,
    debouncedReadAllFiles,
    documentIsProcessing,
    onPreviewFile,
    onDeleteFile,
    onEditFileName,
    onEditDocumentData,
    getSignedFileUrl,
  } = useHandleMultipleFileUploads({
    mode: 'KNOWLEDGE_FILES',
    selectedKnowledgeArea,
    onProcessQueueError: (error) => {
      if (error instanceof Error) {
        notificationController.error({ message: error.message });
      } else if (typeof error === 'string') {
        notificationController.error({ message: error });
      } else {
        notificationController.error({ message: 'Fehler beim Zugriff auf den Server' });
      }
      debouncedReadAllFiles();
    },
  });

  const maxLimitExceed = alreadyUploadedFiles.length > MaxKnowledgeFilesPerAreas;

  useEffect(() => {
    readAllFiles();
    setShowValidityPeriodFields(
      selectedKnowledgeArea?.additionalDocumentFields?.some((item) => item === 'VALIDITY_PERIOD') || false,
    );
  }, [readAllFiles, selectedKnowledgeArea]);

  const onRefreshClick = async () => {
    debouncedReadAllFiles();
  };

  const onEditClick = (id: string, title: string) => {
    setToEditFileId(id);
    form.setFieldValue('title', title);
  };

  const handleUpdateFileName = async () => {
    if (toEditFileId) {
      const newTitle = form.getFieldValue('title');
      onEditFileName(toEditFileId, newTitle);
      setToEditFileId(null);
    }
  };

  const onEditFileData = async (
    id: string,
    fieldName: 'validFrom' | 'validUntil' | 'userComment',
    valueToUpdate: string | number,
  ) => {
    if ((fieldName === 'validFrom' || fieldName === 'validUntil') && typeof valueToUpdate !== 'number') return;
    if (fieldName === 'userComment' && typeof valueToUpdate !== 'string') return;
    onEditDocumentData(id, { [fieldName]: valueToUpdate });
  };

  const onOpenFile = async (docItem: IUploadedDocument) => {
    if (docItem.fileType) {
      if (['pdf', 'word', 'excel', 'txt', 'powerpoint'].includes(docItem.fileType)) {
        if (!docItem.fileStoragePath) {
          message.error('Fehler: ungültige Konfiguration');
          return;
        }
        const signedUrl = await getSignedFileUrl(docItem.fileStoragePath);

        if (['word', 'excel', 'txt', 'powerpoint'].includes(docItem.fileType)) {
          window.open(signedUrl, '_blank');
        } else {
          setToShowFile({ title: docItem.title, url: signedUrl ?? '' });
          setShowPDFViewer(true);
        }
      }
    } else {
      onPreviewFile(docItem.fileStoragePath ?? '');
    }
  };

  const debouncedSearch = debounce(setSearchText, 500);

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    debouncedSearch(event.target.value);
  };

  const filteredAlreadyUploadedFiles = useMemo(
    () => alreadyUploadedFiles.filter((doc) => doc.title.toLowerCase().includes(searchText.toLowerCase())),
    [alreadyUploadedFiles, searchText],
  );

  type ColumnIndex = keyof IUploadedDocument;

  const menu = (
    <Menu>
      <Menu.Item
        key="2"
        onClick={() => onTriggerShowAdditionalDocumentFields?.(selectedKnowledgeArea?._id ?? '', 'VALIDITY_PERIOD')}
      >
        {t(showValidityPeriodFields ? 'dataroom.disableComplianceFields' : 'dataroom.enableComplianceFields')}
      </Menu.Item>
    </Menu>
  );

  const getColumnSearchProps = (columnIndex: ColumnIndex): ColumnType<IUploadedDocument> => ({
    filterDropdown: ({ setSelectedKeys, confirm }) => {
      const onChange: DatePickerProps['onChange'] = (date) => {
        const valueToSave = date ? [date.unix()] : [];
        setSelectedKeys(valueToSave);
      };

      return (
        <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
          <Space direction="vertical" align={'end'}>
            <DatePicker size={'small'} onChange={onChange} style={{ height: '32px' }} />
            <Button
              type="primary"
              onClick={() => {
                confirm();
              }}
              size="small"
            >
              Filter
            </Button>
          </Space>
        </div>
      );
    },
    onFilter: (value, record) => {
      if (value && typeof value === 'number' && record && typeof record[columnIndex] === 'number') {
        if (columnIndex === 'validFrom') {
          return (record[columnIndex] as number) > value;
        }
        if (columnIndex === 'validUntil') {
          return (record[columnIndex] as number) < value;
        }
      }
      return true;
    },
  });

  const processingInfo = (processingState?: string) => {
    if (!processingState || processingState === 'WAITING_FOR_PROCESSING' || processingState === 'PROCESSING') {
      return (
        <Space>
          <NormalText
            size="s"
            semiBold
            style={{ color: processingState === 'PROCESSING' ? 'orange' : 'cornflowerblue' }}
          >
            {processingState === 'PROCESSING' ? 'processing' : 'wating for processing'}
          </NormalText>
          <LoadingOutlined style={{ color: processingState === 'PROCESSING' ? 'orange' : 'cornflowerblue' }} />
        </Space>
      );
    }
    return <LoadingOutlined />;
  };

  return (
    <S.ContainerWrapper>
      <S.ContentWrapper>
        <NormalText verticalPadding size="l">
          {t('uploadDocumentsForKnowledgeArea')}
        </NormalText>

        {maxLimitExceed && (
          <Space>
            <InfoCircleOutlined />
            <NormalText verticalPadding size="m" colorType="light">
              {t('maxFilesPerKnowledgeAreaReached', { maxFiles: MaxKnowledgeFilesPerAreas })}
            </NormalText>
          </Space>
        )}

        <FileUploader disabled={maxLimitExceed} onFileUploaded={onNewFileUploaded} />

        {alreadyUploadedFiles.length > 0 && (
          <Row
            justify={'space-between'}
            align={'middle'}
            gutter={6}
            style={{ marginTop: '2rem', marginBottom: 16, marginRight: 16 }}
          >
            <Col xs={20} lg={16}>
              <SearchInput placeholder={t('searchForDocuments')} onChange={handleSearch} size="middle" />
            </Col>
            <Col xs={'auto'}>
              <Space>
                <Button icon={<ReloadOutlined />} onClick={onRefreshClick}></Button>
              </Space>
            </Col>
          </Row>
        )}
      </S.ContentWrapper>

      <S.TableWrapper>
        <Row justify={'start'}>
          <Col xs={23}>
            <Table
              dataSource={filteredAlreadyUploadedFiles}
              pagination={{ hideOnSinglePage: true, defaultPageSize: 50, size: 'small' }}
              bordered
              rowKey={(item) => `${item.title}_${item.uploadedAt}`}
              columns={
                showValidityPeriodFields
                  ? [
                      {
                        title: 'Name',
                        dataIndex: 'title',
                        key: 'title',
                        defaultSortOrder: null,
                        sorter: (a, b) => a.title.localeCompare(b.title),
                      },
                      {
                        title: 'Valid from',
                        dataIndex: 'validFrom',
                        key: 'validFrom',
                        defaultSortOrder: null,
                        sorter: (a, b) => {
                          if (b.validFrom && a.validFrom) return b.validFrom - a.validFrom;
                          if (!b.validFrom && !a.validFrom) return 0;
                          if (!b.validFrom) return -1;
                          return 0;
                        },
                        render: (_, record) => (
                          <EditableDate
                            date={record.validFrom}
                            key={`validFrom${record._id ?? record.title}`}
                            onDateSave={(date: number) => onEditFileData(record._id ?? '', 'validFrom', date)}
                            disableEdit={documentIsProcessing(record.processingState)}
                          />
                        ),
                        ...getColumnSearchProps('validFrom'),
                      },
                      {
                        title: 'Valid until',
                        dataIndex: 'validUntil',
                        key: 'validUntil',
                        defaultSortOrder: null,
                        sorter: (a, b) => {
                          if (b.validUntil && a.validUntil) return b.validUntil - a.validUntil;
                          if (!b.validUntil && !a.validUntil) return 0;
                          if (!b.validUntil) return -1;
                          return 0;
                        },
                        render: (_, record) => (
                          <EditableDate
                            date={record.validUntil}
                            key={`validUntil${record._id ?? record.title}`}
                            onDateSave={(date: number) => onEditFileData(record._id ?? '', 'validUntil', date)}
                            disableEdit={documentIsProcessing(record.processingState)}
                          />
                        ),
                        ...getColumnSearchProps('validUntil'),
                      },
                      {
                        title: 'Comment',
                        dataIndex: 'userComment',
                        key: 'userComment',
                        defaultSortOrder: null,
                        sorter: (a, b) => {
                          if (b.userComment && a.userComment)
                            return a.userComment.toLowerCase().localeCompare(b.userComment.toLowerCase());
                          if (!b.userComment && !a.userComment) return 0;
                          if (!b.userComment) return -1;
                          return 0;
                        },
                        render: (_, record) => (
                          <EditableText
                            text={record.userComment}
                            key={`userComment${record._id ?? record.title}`}
                            onTextSave={(text: string) => onEditFileData(record._id ?? '', 'userComment', text)}
                            disableEdit={documentIsProcessing(record.processingState)}
                          />
                        ),
                      },
                      {
                        title: 'Uploaded at',
                        dataIndex: 'uploadedAt',
                        key: 'uploadedAt',
                        defaultSortOrder: null,
                        sorter: (a, b) => b.uploadedAt - a.uploadedAt,
                        render: (_, record) => (
                          <Space>
                            {documentIsProcessing(record.processingState) ? (
                              <LoadingOutlined />
                            ) : (
                              <NormalText style={{ minWidth: 100 }} size="s">
                                {moment.unix(record.uploadedAt).format('DD/MM/YY HH:mm')}
                              </NormalText>
                            )}
                          </Space>
                        ),
                      },
                      {
                        title: 'Options',
                        key: 'action',
                        render: (_, record) =>
                          documentIsProcessing(record.processingState) ? null : (
                            <Space size="large">
                              <a
                                onClick={() => {
                                  onOpenFile(record);
                                }}
                              >
                                <DownloadOutlined />
                              </a>
                              <a onClick={() => onDeleteFile(record.title, record.fileStoragePath ?? '')}>
                                <DeleteOutlined />
                              </a>
                              <a onClick={() => onEditClick(record._id || '', record.title)}>
                                <EditOutlined />
                              </a>
                            </Space>
                          ),
                      },
                    ]
                  : [
                      {
                        title: 'Name',
                        dataIndex: 'title',
                        key: 'title',
                        defaultSortOrder: null,
                        sorter: (a, b) => a.title.localeCompare(b.title),
                      },
                      {
                        title: 'Comment',
                        dataIndex: 'userComment',
                        key: 'userComment',
                        defaultSortOrder: null,
                        sorter: (a, b) => {
                          if (b.userComment && a.userComment)
                            return a.userComment.toLowerCase().localeCompare(b.userComment.toLowerCase());
                          if (!b.userComment && !a.userComment) return 0;
                          if (!b.userComment) return -1;
                          return 0;
                        },
                        render: (_, record) => (
                          <EditableText
                            text={record.userComment}
                            onTextSave={(text: string) => onEditFileData(record._id ?? '', 'userComment', text)}
                            disableEdit={documentIsProcessing(record.processingState)}
                          />
                        ),
                      },
                      {
                        title: 'Uploaded at',
                        dataIndex: 'uploadedAt',
                        key: 'uploadedAt',
                        defaultSortOrder: null,
                        sorter: (a, b) => b.uploadedAt - a.uploadedAt,
                        render: (_, record) => (
                          <Space>
                            {documentIsProcessing(record.processingState) ? (
                              processingInfo(record.processingState)
                            ) : (
                              <NormalText style={{ minWidth: 100 }} size="s">
                                {moment.unix(record.uploadedAt).format('DD/MM/YY HH:mm')}
                              </NormalText>
                            )}
                          </Space>
                        ),
                      },
                      {
                        title: 'Options',
                        key: 'action',
                        render: (_, record) =>
                          documentIsProcessing(record.processingState) ? null : (
                            <Space size="large">
                              <a
                                onClick={() => {
                                  onOpenFile(record);
                                }}
                              >
                                <DownloadOutlined />
                              </a>
                              <a onClick={() => onDeleteFile(record.title, record.fileStoragePath ?? '')}>
                                <DeleteOutlined />
                              </a>
                              <a onClick={() => onEditClick(record._id || '', record.title)}>
                                <EditOutlined />
                              </a>
                            </Space>
                          ),
                      },
                    ]
              }
            />
          </Col>
          <Col xs={1}>
            <Dropdown overlay={menu} trigger={['click']}>
              <Button type="text" icon={<MoreOutlined />} style={{ marginLeft: 4 }}></Button>
            </Dropdown>
          </Col>
        </Row>
      </S.TableWrapper>

      <Modal
        title={t('updateFileName')}
        open={!!toEditFileId}
        onOk={handleUpdateFileName}
        onCancel={() => setToEditFileId(null)}
      >
        <Form form={form} layout="vertical">
          <Form.Item name="title" rules={[{ required: true, message: t('fileNameRequired') }]}>
            <BaseInput placeholder={t('fileName')} />
          </Form.Item>
        </Form>
      </Modal>
      <PdfViewer
        open={showPDFViewer}
        onClose={() => setShowPDFViewer(false)}
        url={toShowFile?.url ?? ''}
        title={toShowFile?.title ?? ''}
      />
    </S.ContainerWrapper>
  );
};
