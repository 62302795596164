import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, message, Row, Dropdown, Menu, Col, Radio, RadioChangeEvent } from 'antd';
import * as S from './TeamOverview.styles';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { NormalText } from '@app/components/common/BaseTexts/BaseTexts';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { getCompanyTeamMembers } from '@app/api/company.api';
import { UserModel, UserRole } from '@app/domain/UserModel';
import { notificationController } from '@app/controllers/notificationController';
import {
  deleteTeamMemberInvitation,
  inviteByCsvFile,
  readAllTeamMemberInvitationForCompany,
} from '@app/api/teamMemberInvitation.api';
import { TeamMemberInvitationModel } from '@app/domain/TeamMemberInvitationModel';
import moment from 'moment';
import { debounce } from 'lodash';
import { SearchInput } from '@app/components/common/inputs/SearchInput/SearchInput.styles';
import { ExclamationCircleOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { deleteUser } from '@app/api/user.api';
import { ModalInviteTeamMember } from './ModalInviteTeamMember';
import { TeamMemberTable } from './TeamMembersTable';
import {
  IUserOverviewAccessRights,
  IUserOverviewAccessRight,
  AccessRightEntityType,
  IUserDetailedAccessRight,
} from '@app/domain/AccessRightsModel';
import { readOverviewAccessRightsForUsers } from '@app/api/accessRights.api';
import { IconDotsVertical } from '@tabler/icons-react';
import { themeObject } from '@app/styles/themes/themeVariables';
import { RadioGroup } from '@app/components/common/Radio/Radio';
import { LocalStoredFile, SingleFileUploader } from '@app/components/singleFileUploader/SingleFileUploader';
import { useErrorHandling } from '@app/hooks/useErrorHandling';
import { LoadingSpin } from '@app/components/common/LoadingSpin';

export type TeamMemberItemProps = {
  name: string;
  email: string;
  _id: string;
  title?: string | null;
  imageUrl?: string;
  role?: UserRole;
};

export const TeamOverview: React.FC = () => {
  const { t } = useTranslation();

  const [teamMembersData, setTeamMembersData] = useState<TeamMemberItemProps[]>([]);
  const [userOverviewAccessRights, setUserOverviewAccessRights] = useState<IUserOverviewAccessRights[]>([]);
  const [invitationData, setInvitationData] = useState<TeamMemberInvitationModel[]>([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [defaultUserRole, setDefaultUserRole] = useState<UserRole>('EMPLOYEE');
  const [searchText, setSearchText] = useState('');
  const [csvFileToUpload, setCsvFileToUpload] = useState<LocalStoredFile | undefined>(undefined);
  const [csvFileToUploadResult, setCsvFileToUploadResult] = useState<string | null>(null);
  const [csvFileToUploadLoading, setCsvFileToUploadLoading] = useState(false);
  const [onShowCsvImportModal, setOnShowCsvImportModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState<TeamMemberItemProps | null>(null);

  const theme = useAppSelector((state) => state.theme.theme);
  const companyState = useAppSelector((state) => state.company);
  const { user, isAdmin, isManager } = useAppSelector((state) => state.user);

  const { handleApiError } = useErrorHandling();

  useEffect(() => {
    const readTeamMemebers = async () => {
      if (companyState._id) {
        const members: UserModel[] = await getCompanyTeamMembers(companyState._id);
        setTeamMembersData(
          members.map(
            (member) =>
              ({
                imageUrl: member.profileImage?.smallUrl,
                name: `${member.firstName} ${member.lastName}`,
                email: member.email,
                title: member.title,
                _id: member._id,
                role: member.role,
              }) as TeamMemberItemProps,
          ),
        );
      }
    };

    const readOverviewRights = async () => {
      if (companyState._id) {
        const overviewRights: IUserOverviewAccessRights[] | null = await readOverviewAccessRightsForUsers(
          companyState._id,
        );
        setUserOverviewAccessRights(overviewRights ?? []);
      }
    };
    readTeamMemebers();
    readInvitations();
    readOverviewRights();
  }, [companyState]);

  const readInvitations = async () => {
    if (companyState._id) {
      const invitations: TeamMemberInvitationModel[] = await readAllTeamMemberInvitationForCompany(companyState._id);
      setInvitationData(invitations);
    }
  };

  const handleDeleteUser = async (memberId: string) => {
    const onConfirmed = async () => {
      if (memberId && user?._id) {
        await deleteUser(user?._id, memberId);
        setTeamMembersData(teamMembersData.filter((member) => member._id !== memberId));
        message.success(t('userWasDeleted'));
      }
    };

    Modal.confirm({
      title: t('deleteUser'),
      icon: <ExclamationCircleOutlined />,
      content: t('areYouSureToDeleteUser'),
      okText: t('yes'),
      okType: 'danger',
      cancelText: t('no'),
      onOk() {
        onConfirmed();
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };

  const handleInviteClick = () => {
    if (!isAdmin && !isManager) {
      Modal.confirm({
        title: t('noRights'),
        icon: <InfoCircleOutlined />,
        content: t('onlyAdminCanInvite'),
        okText: t('ok'),
      });
      return;
    }
    setIsEditMode(false);
    setIsModalVisible(true);
  };

  const handleDeleteInvitation = async (email: string) => {
    try {
      const toDeleteInvitation = invitationData.find((item) => item.teamMemberEmail === email);
      if (toDeleteInvitation?._id) {
        await deleteTeamMemberInvitation(toDeleteInvitation._id);
        setInvitationData(invitationData.filter((item) => item._id !== toDeleteInvitation._id));
        notificationController.success({ message: t('invitationWasSent') });
      }
    } catch (error) {
      if (error instanceof Error) {
        notificationController.error({ message: error.message });
      } else if (typeof error === 'string') {
        notificationController.error({ message: error });
      } else {
        notificationController.error({
          message: t('invitationError'),
        });
      }
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setSelectedUser(null);
  };

  const debouncedSearch = debounce(setSearchText, 300);

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    debouncedSearch(event.target.value);
  };

  const filteredTeamMembers = useMemo(
    () => teamMembersData.filter((member) => member.name.toLowerCase().includes(searchText.toLowerCase())),
    [teamMembersData, searchText],
  );

  const getUserOverviewAccessRights = (
    teamMemberAccessRights: IUserDetailedAccessRight[],
  ): IUserOverviewAccessRight[] => {
    const getAreasNumber = (
      entityType: AccessRightEntityType,
      teamMemberAccessRights: IUserDetailedAccessRight[],
    ): number => {
      return teamMemberAccessRights
        ? teamMemberAccessRights.filter((item) => item.entityType === entityType).length
        : 0;
    };

    const userAccessRight: IUserOverviewAccessRight[] = [
      {
        entityType: 'KNOWLEDGE_AREA',
        allowedItems: getAreasNumber('KNOWLEDGE_AREA', teamMemberAccessRights),
      },
      {
        entityType: 'APP',
        allowedItems: getAreasNumber('APP', teamMemberAccessRights),
      },
    ];

    return userAccessRight;
  };

  const mapInvitedTeamMember = (invitedTeamMember: TeamMemberInvitationModel) => {
    const userAccessRight: IUserOverviewAccessRight[] = getUserOverviewAccessRights(
      invitedTeamMember.teamMemberAccessRights,
    );

    return {
      name: invitedTeamMember.teamMemberEmail,
      title: t('invitedOn', {
        date: invitedTeamMember.timestampCreated ? moment.unix(invitedTeamMember.timestampCreated).fromNow() : '-',
      }),
      email: invitedTeamMember.teamMemberEmail,
      _id: invitedTeamMember._id ?? '',
      role: invitedTeamMember.teamMemberRole,
      userAccessRight,
    };
  };

  const handleFileUpload = (file: LocalStoredFile): void => {
    setCsvFileToUpload(file);
  };

  const handleFileRemove = (): void => {
    setCsvFileToUpload(undefined);
  };

  const onCloseCsvModal = (): void => {
    setOnShowCsvImportModal(false);
    setCsvFileToUploadResult(null);
    setCsvFileToUpload(undefined);
  };

  const onUploadCsvFile = async (): Promise<void> => {
    if (!csvFileToUpload) {
      message.error(t('company.pleaseUploadACsvFile'));
      return;
    }

    const formData = new FormData();
    formData.append('files', csvFileToUpload.originFileObj as Blob, encodeURIComponent(csvFileToUpload.name));
    formData.append(`defaultUserRole`, defaultUserRole);

    try {
      setCsvFileToUploadLoading(true);
      const result = await inviteByCsvFile(formData);
      let resultString = `Successfully invited: ${result.successfullyInvited}`;
      if (result.wasAlreadyInvited > 0) {
        resultString += `, was already invited: ${result.wasAlreadyInvited}`;
      }
      if (result.errorOccured > 0) {
        resultString += `, error occured: ${result.errorOccured}`;
      }
      setCsvFileToUploadResult(resultString);
      readInvitations();
    } catch (error) {
      handleApiError(error);
    } finally {
      setCsvFileToUploadLoading(false);
    }
  };

  const menu = (
    <Menu onClick={() => setOnShowCsvImportModal(true)}>
      <Menu.Item key="importCsv">{t('company.importTeamMemeberWithCsv')}</Menu.Item>
    </Menu>
  );

  return (
    <>
      <Row justify={'end'} align={'middle'}>
        <Dropdown overlay={menu} placement="bottomCenter" arrow>
          <S.MoreOptionsButton>
            <IconDotsVertical color={themeObject[theme].textLight} />
          </S.MoreOptionsButton>
        </Dropdown>
        <S.InviteButton type="primary" htmlType="submit" onClick={handleInviteClick}>
          <PlusOutlined />
          {t('company.inviteTeamMemeber')}
        </S.InviteButton>
      </Row>
      <NormalText semiBold size="m" style={{ marginTop: '1rem' }}>
        {t('manageTeamHere')}
      </NormalText>
      {teamMembersData.length > 0 && (
        <SearchInput placeholder={t('searchMembers')} onChange={handleSearch} style={{ margin: '1rem 0' }} />
      )}
      <TeamMemberTable
        teamMembers={filteredTeamMembers.map((teamMember) => {
          const userAccessRight = userOverviewAccessRights.find(
            (userAccessRights) => userAccessRights.userId === teamMember._id,
          );

          return { ...teamMember, userAccessRight: userAccessRight ? userAccessRight.overviewAccessRights : [] };
        })}
        onDeleteUser={(userId: string) => handleDeleteUser(userId)}
        openEditModal={(item) => {
          setIsEditMode(true);
          setIsModalVisible(true);
          setSelectedUser(item);
        }}
        allowEditRights={isAdmin || isManager}
        currentUserId={user?._id || ''}
      />
      {invitationData.length > 0 && (
        <NormalText bold size="m" style={{ marginTop: '2rem' }}>
          {t('unconfirmedTeamInvitations')}
        </NormalText>
      )}
      {invitationData.length > 0 && (
        <TeamMemberTable
          teamMembers={invitationData.map(mapInvitedTeamMember)}
          allowEditRights={false}
          currentUserId={''}
          isInvitationTable
          onDeleteInvitation={handleDeleteInvitation}
        />
      )}
      <Modal
        title={t('company.importTeamMemeberWithCsvTitle')}
        open={onShowCsvImportModal}
        onCancel={() => onCloseCsvModal()}
        onOk={() => onUploadCsvFile()}
        bodyStyle={{ maxHeight: '70vh', overflowY: 'scroll' }}
        footer={csvFileToUploadResult ? null : undefined}
      >
        {csvFileToUploadResult || csvFileToUploadLoading ? (
          <>
            {csvFileToUploadLoading && <LoadingOutlined style={{ marginTop: '2rem' }} />}
            {csvFileToUploadResult && (
              <NormalText bottomMargin="m" size="l" semiBold>
                Result:
              </NormalText>
            )}
            <NormalText bottomMargin="m" size="l" light>
              {csvFileToUploadResult}
            </NormalText>
          </>
        ) : (
          <>
            <div style={{ marginBottom: '2rem' }}>
              <SingleFileUploader
                fileData={csvFileToUpload}
                acceptedFileTypes="text/csv"
                description={t('company.fileUploaderWithCsvDescription')}
                onFileUploaded={handleFileUpload}
                onFileRemove={handleFileRemove}
              />
            </div>
            <NormalText bottomMargin="m" size="l">
              {t('company.selectDefaultRole')}
            </NormalText>
            <RadioGroup
              size="large"
              value={defaultUserRole}
              onChange={(e: RadioChangeEvent) => setDefaultUserRole(e.target.value)}
            >
              <Col>
                <Radio value="ADMIN">{'Admin'}</Radio>
                <S.Description>{t('adminRoleDescription')}</S.Description>
              </Col>
              <Col>
                <Radio value="MANAGER">{'Manager'}</Radio>
                <S.Description>{t('managerRoleDescription')}</S.Description>
              </Col>
              <Col>
                <Radio value="EMPLOYEE">{t('employeeRole')}</Radio>
                <S.Description>{t('userRoleDescription')}</S.Description>
              </Col>
            </RadioGroup>
          </>
        )}
      </Modal>
      <ModalInviteTeamMember
        handleCancel={handleCancel}
        isModalVisible={isModalVisible}
        isEditMode={isEditMode}
        setInvitationData={setInvitationData}
        invitationData={invitationData}
        selectedUser={selectedUser}
        isAdmin={isAdmin}
        onUserUpdate={(userId, userRole, knowledgeAreasAccessRights, appAreasAccessRights) => {
          const teamMemberToUpdate = teamMembersData.find((teamMember) => teamMember._id === userId);
          if (teamMemberToUpdate) {
            const teamMembersToSave = teamMembersData.map((teamMember) => {
              if (teamMember._id === userId) {
                return { ...teamMember, role: userRole };
              }
              return teamMember;
            });
            setTeamMembersData(teamMembersToSave);
          }

          const userAccessRightsToUpdate = userOverviewAccessRights.find(
            (userAccessRight) => userAccessRight.userId === userId,
          );

          if (userAccessRightsToUpdate) {
            const userAccessRightsToSave = userOverviewAccessRights.map((userAccessRights) => {
              if (userAccessRights.userId === userId) {
                return {
                  ...userAccessRights,
                  overviewAccessRights: getUserOverviewAccessRights([
                    ...knowledgeAreasAccessRights,
                    ...appAreasAccessRights,
                  ]),
                };
              }
              return userAccessRights;
            });

            setUserOverviewAccessRights(userAccessRightsToSave);
          }
          message.success(t('settings.rightsWhereUpdated'));
        }}
      />
    </>
  );
};
