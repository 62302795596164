import React from 'react';
import { useTranslation } from 'react-i18next';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import LawRadarOverview from '@app/components/lawRadar/LawRadarOverview';

function LawRadarOverviewPage() {
  const { t } = useTranslation();
  return (
    <>
      <PageTitle>{t('lawRadar.shortTitle')}</PageTitle>
      <LawRadarOverview />
    </>
  );
}

export default LawRadarOverviewPage;
