import React from 'react';
import { Button, Space } from 'antd';
import styled from 'styled-components';
import { useAppDispatch, useAppSelector } from '@app/hooks/reduxHooks';
import { setaiInteractionMode } from '@app/store/slices/settingsSlice';

const ButtonContainer = styled(Space)`
  padding: 0.3rem;
  border: 0.5px solid #bebebe;
  border-radius: 10px;
  height: 2.5rem;
`;

const StyledButton = styled(Button)`
  margin-right: 2px;
  font-size: 0.75rem !important;
  font-weight: 500 !important;
  height: 2rem;
`;

const AIModeSwitcher = () => {
  const { aiInteractionMode } = useAppSelector((state) => state.settings);

  const dispatch = useAppDispatch();

  const onChange = (value: 'AI_ONLY' | 'WEB' | 'IMAGE_GENERATION' | 'DOCUMENT_ANALYSIS') => {
    dispatch(setaiInteractionMode({ value }));
  };

  return (
    <ButtonContainer>
      <StyledButton
        size="small"
        type={aiInteractionMode === 'AI_ONLY' ? 'default' : 'text'}
        style={{
          backgroundColor:
            aiInteractionMode === 'AI_ONLY'
              ? 'var(--secondary-button-active-background-color)'
              : 'var(--secondary-button-background-color)',
        }}
        onClick={() => onChange('AI_ONLY')}
      >
        AI only
      </StyledButton>
      <StyledButton
        size="small"
        type={aiInteractionMode === 'WEB' ? 'default' : 'text'}
        style={{
          backgroundColor:
            aiInteractionMode === 'WEB'
              ? 'var(--secondary-button-active-background-color)'
              : 'var(--secondary-button-background-color)',
        }}
        onClick={() => onChange('WEB')}
      >
        AI Websearch
      </StyledButton>
      <StyledButton
        size="small"
        type={aiInteractionMode === 'DOCUMENT_ANALYSIS' ? 'default' : 'text'}
        style={{
          backgroundColor:
            aiInteractionMode === 'DOCUMENT_ANALYSIS'
              ? 'var(--secondary-button-active-background-color)'
              : 'var(--secondary-button-background-color)',
        }}
        onClick={() => onChange('DOCUMENT_ANALYSIS')}
      >
        Document analysis
      </StyledButton>
      <StyledButton
        size="small"
        type={aiInteractionMode === 'IMAGE_GENERATION' ? 'default' : 'text'}
        style={{
          backgroundColor:
            aiInteractionMode === 'IMAGE_GENERATION'
              ? 'var(--secondary-button-active-background-color)'
              : 'var(--secondary-button-background-color)',
        }}
        onClick={() => onChange('IMAGE_GENERATION')}
      >
        Dall-E
      </StyledButton>
    </ButtonContainer>
  );
};

export default AIModeSwitcher;
